export const CardioWorkouts = [
  {
    kind: "youtube#searchListResponse",
    etag: "pfv1cq9vZATjxBQa_uKro_ofxIY",
    nextPageToken: "CDIQAA",
    regionCode: "GB",
    pageInfo: {
      totalResults: 1000000,
      resultsPerPage: 50,
    },
    items: [
      {
        kind: "youtube#searchResult",
        etag: "c-k0mGCmaOqmlAmMe9mfvWLADG8",
        id: {
          kind: "youtube#video",
          videoId: "ml6cT4AZdqI",
        },
        snippet: {
          publishedAt: "2018-05-21T16:01:33Z",
          channelId: "UCGiSCVGNukLqv8hwpKCsQKQ",
          title:
            "30-Minute HIIT Cardio Workout with Warm Up - No Equipment at Home | SELF",
          description:
            "In this high intensity cardio bodyweight workout from trainer Lita Lewis, you'll spike your heart rate with high-knees, fast feet, and star jumps; plus work your core ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ml6cT4AZdqI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ml6cT4AZdqI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ml6cT4AZdqI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "SELF",
          liveBroadcastContent: "none",
          publishTime: "2018-05-21T16:01:33Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "mD8GRURUxRt4zo5D8aUj9QthMCg",
        id: {
          kind: "youtube#video",
          videoId: "LueYlLt1l9M",
        },
        snippet: {
          publishedAt: "2020-08-17T14:00:09Z",
          channelId: "UCL0-zfZcvpeagrmUcvt3F3A",
          title: "BURN 500 CALORIES with this 30-Minute Cardio Workout!",
          description:
            "Sign up for my free Get Fit in 30 Days workout program: https://www.rachelgulottafitness.com/. The amount of calories you'll burn from this 30-minute workout will ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/LueYlLt1l9M/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/LueYlLt1l9M/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/LueYlLt1l9M/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Rachel Gulotta Fitness",
          liveBroadcastContent: "none",
          publishTime: "2020-08-17T14:00:09Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "mCtRF8q4WoumoYVTuiGEyWHt-Es",
        id: {
          kind: "youtube#video",
          videoId: "fcN37TxBE_s",
        },
        snippet: {
          publishedAt: "2013-04-26T14:39:31Z",
          channelId: "UCiP6wD_tYlYLYh3agzbByWQ",
          title:
            "Fat Burning Cardio Workout - 37 Minute Fitness Blender Cardio Workout at Home",
          description:
            "Calorie Burn info @ https://gofb.info/bd9Nr7 At-home Workout Programs @ https://gofb.info/Programs Exclusive workout videos, Workout Challenges, and site ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/fcN37TxBE_s/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/fcN37TxBE_s/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/fcN37TxBE_s/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "FitnessBlender",
          liveBroadcastContent: "none",
          publishTime: "2013-04-26T14:39:31Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "24Q007NDa-eqyNRlcqUc6ImjS_U",
        id: {
          kind: "youtube#video",
          videoId: "-YJXpabrX4k",
        },
        snippet: {
          publishedAt: "2019-08-18T16:00:21Z",
          channelId: "UCBINFWq52ShSgUFEoynfSwg",
          title: "30-Minute Standing Cardio Workout",
          description:
            "Get your heart rate up without taking it to the mat with this standing cardio workout that you can do anywhere! These cardio exercises will jump-start your ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/-YJXpabrX4k/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/-YJXpabrX4k/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/-YJXpabrX4k/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "POPSUGAR Fitness",
          liveBroadcastContent: "none",
          publishTime: "2019-08-18T16:00:21Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "b3ntoVX21hpFCSfdNPrQk9t3S_M",
        id: {
          kind: "youtube#video",
          videoId: "LyRjxAtpk_U",
        },
        snippet: {
          publishedAt: "2020-05-11T17:21:46Z",
          channelId: "UCR117JPMLO3Y7J5mIblkBNg",
          title: "FAT BURNING CARDIO WORKOUT 💦INTENSE &amp; SWEATY HIIT",
          description:
            "Girl when I tell you this is intense i MEAN IT!!! Get ready to sweat and burn 100 cals in just 10 minutes with this intense cardio workout. Burn fat and train your ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/LyRjxAtpk_U/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/LyRjxAtpk_U/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/LyRjxAtpk_U/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Vicky Justiz",
          liveBroadcastContent: "none",
          publishTime: "2020-05-11T17:21:46Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "LBdwefPsU5nd3vtQ5jFDb2QISW4",
        id: {
          kind: "youtube#video",
          videoId: "sjmsApWcyCU",
        },
        snippet: {
          publishedAt: "2019-06-08T19:22:35Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "10 MIN CARDIO WORKOUT AT HOME (Equipment Free)",
          description:
            "A 10 min no-equipment, at home, jump, cardio workout! THE MAT I USE: http://gorillamats.com?aff=19 (MADFIT10 for 10% off) ⭐️SHOP MY COOKBOOKS!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/sjmsApWcyCU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/sjmsApWcyCU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/sjmsApWcyCU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2019-06-08T19:22:35Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "fHhDKR3XU0oNyMLGifPKjVqNz8k",
        id: {
          kind: "youtube#video",
          videoId: "50kH47ZztHs",
        },
        snippet: {
          publishedAt: "2014-04-24T06:33:54Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title: "Low Impact 30 minute cardio workout- Beginner/intermediate",
          description:
            "https://teambodyproject.com Transform your body in just 10 weeks and take part in the entire Body Project system. Start your Body Project TODAY! Or simply ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/50kH47ZztHs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/50kH47ZztHs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/50kH47ZztHs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2014-04-24T06:33:54Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "yFLzFy_dOfL5ZbXL5dIuKBcrv2k",
        id: {
          kind: "youtube#video",
          videoId: "8oQ-WNJoYtM",
        },
        snippet: {
          publishedAt: "2020-05-18T05:59:30Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title: "Fat burning, high intensity, low impact home cardio workout",
          description:
            "http://teambodyproject.com For more workouts just like this one, come and join the team.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8oQ-WNJoYtM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8oQ-WNJoYtM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8oQ-WNJoYtM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2020-05-18T05:59:30Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "oNE7L9HTeG876CTLECG3-UgkEaY",
        id: {
          kind: "youtube#video",
          videoId: "edIK5SZYMZo",
        },
        snippet: {
          publishedAt: "2020-10-05T00:00:05Z",
          channelId: "UCqjwF8rxRsotnojGl4gM0Zw",
          title: "Replace Treadmill With This 10 Min HIIT/CARDIO Workout",
          description:
            "Join Chris Heria as he shows you how to Replace Treadmill With this 10 Min HIIT/CARDIO Workout that is going to build muscle and burn fat. Follow along with ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/edIK5SZYMZo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/edIK5SZYMZo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/edIK5SZYMZo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "THENX",
          liveBroadcastContent: "none",
          publishTime: "2020-10-05T00:00:05Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "3zll2kTCK-X4wm0-sN1HAE0zlfQ",
        id: {
          kind: "youtube#video",
          videoId: "M60AVSKVqUA",
        },
        snippet: {
          publishedAt: "2019-10-08T00:14:49Z",
          channelId: "UCOpsZxrmeDARilha1uq4slA",
          title: "Killer HIIT CARDIO Workout // No Equipment",
          description:
            "Ok guys get ready for a quick and sweaty session! Today's HIIT Cardio Workout is a killer! We will be powering through 6 different body-weighted, high-intensity ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/M60AVSKVqUA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/M60AVSKVqUA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/M60AVSKVqUA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Heather Robertson",
          liveBroadcastContent: "none",
          publishTime: "2019-10-08T00:14:49Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "rhx5YsosoqtYyvoWRmTgxH9JsJI",
        id: {
          kind: "youtube#video",
          videoId: "gFeEUq2624Q",
        },
        snippet: {
          publishedAt: "2020-05-01T05:30:04Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "10 MIN CARDIO / No Jumping - silent &amp; neighbor friendly / No Equipment I Pamela Reif",
          description:
            'no jumps - no noise - no upset neighbors ♥︎ I included looooots of new movements in here. I hope you "enjoy" them! // Werbung This is a super quick & intense ...',
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/gFeEUq2624Q/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/gFeEUq2624Q/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/gFeEUq2624Q/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-05-01T05:30:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "LiLGE8juvIcMuuNIwbSJFhetwY8",
        id: {
          kind: "youtube#video",
          videoId: "VHyGqsPOUHs",
        },
        snippet: {
          publishedAt: "2016-07-31T16:00:02Z",
          channelId: "UCBINFWq52ShSgUFEoynfSwg",
          title:
            "15-Minute Beginner&#39;s At-Home Cardio Workout | Class FitSugar",
          description:
            "Everyone has to start somewhere. And when it comes to cardio, working out in the comfort of your own home is a great place to start. We created this 15-minute ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/VHyGqsPOUHs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/VHyGqsPOUHs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/VHyGqsPOUHs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "POPSUGAR Fitness",
          liveBroadcastContent: "none",
          publishTime: "2016-07-31T16:00:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ndwUPnJ9kilYDxZDVDG2BXaOa0Q",
        id: {
          kind: "youtube#video",
          videoId: "W5IiasNutB8",
        },
        snippet: {
          publishedAt: "2020-05-22T17:46:23Z",
          channelId: "UCK9JEqf7LBBx3tkrPx2xvbQ",
          title: "20 MINUTE FAT BURNING CARDIO WORKOUT(NO EQUIPMENT)",
          description:
            "WWW.GYMOCLOCKFITNESS.COM ——————————————— Workout starts at: 00:24 This 20 min workout is for those that want to get SHREDDED!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/W5IiasNutB8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/W5IiasNutB8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/W5IiasNutB8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BullyJuice",
          liveBroadcastContent: "none",
          publishTime: "2020-05-22T17:46:23Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "owDSfTpvMNrTS970K-a81wSVRBY",
        id: {
          kind: "youtube#video",
          videoId: "bdCX8Nb_2Mg",
        },
        snippet: {
          publishedAt: "2018-06-05T16:04:58Z",
          channelId: "UCGiSCVGNukLqv8hwpKCsQKQ",
          title:
            "30 Minute HIIT Cardio Workout + Abs At Home - With Warmup | SELF",
          description:
            "A 30 minute HIIT cardio and abs no-equipment workout with a warm up. In this routine, you'll keep moving for a full four minutes through cardio moves like pop ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/bdCX8Nb_2Mg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/bdCX8Nb_2Mg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/bdCX8Nb_2Mg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "SELF",
          liveBroadcastContent: "none",
          publishTime: "2018-06-05T16:04:58Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "bFEOp5q5goWs_pMILdStcIEZwxg",
        id: {
          kind: "youtube#video",
          videoId: "CBWQGb4LyAM",
        },
        snippet: {
          publishedAt: "2019-03-10T16:00:20Z",
          channelId: "UCBINFWq52ShSgUFEoynfSwg",
          title: "30-Minute No-Equipment Cardio &amp; HIIT Workout",
          description:
            "Get ready to torch calories with Le Sweat founder Charlee Atkins! This no-equipment workout includes three circuits that are going to get your heart rate up and ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/CBWQGb4LyAM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/CBWQGb4LyAM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/CBWQGb4LyAM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "POPSUGAR Fitness",
          liveBroadcastContent: "none",
          publishTime: "2019-03-10T16:00:20Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "xDZ3lbmKNloQWqYa6lhVcrJnAbQ",
        id: {
          kind: "youtube#video",
          videoId: "8ortypveAL0",
        },
        snippet: {
          publishedAt: "2020-06-13T09:54:56Z",
          channelId: "UCAxW1XT0iEJo0TYlRfn6rYQ",
          title:
            "NEW!!!! 20 Minute FAT BURNING Cardio &amp; Abs HOME HIIT Workout | The Body Coach TV",
          description: "20 Minute FAT BURNING Cardio & Abs HOME HIIT Workout.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8ortypveAL0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8ortypveAL0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8ortypveAL0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "The Body Coach TV",
          liveBroadcastContent: "none",
          publishTime: "2020-06-13T09:54:56Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "udFkPoHl2NUocaHLyVBKfRoKw7M",
        id: {
          kind: "youtube#video",
          videoId: "yplP5cLuyf4",
        },
        snippet: {
          publishedAt: "2015-04-17T08:04:21Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title: "Advanced fat burning HIIT cardio workout - 30 mins.",
          description:
            "https://teambodyproject.com Transform your body in just 10 weeks and take part in the entire Body Project system! Or simply come and join the conversation at ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/yplP5cLuyf4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/yplP5cLuyf4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/yplP5cLuyf4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2015-04-17T08:04:21Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "s1SxyprY9A5EBYb-8AcR3i4Fojw",
        id: {
          kind: "youtube#video",
          videoId: "PvEnWsPrL4w",
        },
        snippet: {
          publishedAt: "2020-06-15T08:20:08Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title:
            "Low impact, beginner, fat burning, home cardio workout. ALL standing!",
          description:
            "http://teambodyproject.com If you'd like to take part in more workouts just like this with Daniel and the team, come and join the Team Body Project site, where we ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/PvEnWsPrL4w/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/PvEnWsPrL4w/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/PvEnWsPrL4w/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2020-06-15T08:20:08Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "SBaezozPZBlRIlonbGqvjZ8I9WY",
        id: {
          kind: "youtube#video",
          videoId: "ILUZz0nGEBI",
        },
        snippet: {
          publishedAt: "2020-04-13T06:00:04Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "15 MIN DANCE CARDIO WORKOUT - 80s EDITION, burn calories and be happy / No Equipment I Pamela Reif",
          description:
            "ok - quarantine is kicking in for me haha.. a DANCE WORKOUT with 80s music - this is a guarantee to get into a HAPPY MOOD and put a smile on your face ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ILUZz0nGEBI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ILUZz0nGEBI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ILUZz0nGEBI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-04-13T06:00:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "1YFRVAlnpQ_YYe_1scKTKAOpsGY",
        id: {
          kind: "youtube#video",
          videoId: "vL3Rwem-nW0",
        },
        snippet: {
          publishedAt: "2020-07-23T12:56:18Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "10 MIN CARDIO WORKOUT AT HOME (Intense &amp; No Equipment)",
          description:
            "A 10 min no-equipment, at home, jump, cardio workout! ⭐️SHOP MY COOKBOOKS!: https://goo.gl/XHwUJg ⭐️ DO THIS WARM UP FIRST: http://bit.ly/2riv8T6 ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/vL3Rwem-nW0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/vL3Rwem-nW0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/vL3Rwem-nW0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-07-23T12:56:18Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "HqweuPqFFlusIE0BEzyXe-RN5E8",
        id: {
          kind: "youtube#video",
          videoId: "owrBD7_8edA",
        },
        snippet: {
          publishedAt: "2019-01-07T11:43:53Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title:
            "Belly Fat Burner Workout | 20 MIN ABS &amp; HIIT CARDIO Workout At Home | No Jumping alt",
          description:
            "This is a 20 mins cardio abs workout that will help you get that flat belly and toned abs. This video is part of my 30 day FREE flat belly challenge program.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/owrBD7_8edA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/owrBD7_8edA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/owrBD7_8edA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2019-01-07T11:43:53Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "m3olkTlCfEWM9I4voNouuGxMRCU",
        id: {
          kind: "youtube#video",
          videoId: "LCOy-6BcRkI",
        },
        snippet: {
          publishedAt: "2020-05-24T21:41:30Z",
          channelId: "UCOpsZxrmeDARilha1uq4slA",
          title: "10 minute POWER CARDIO Workout // No Equipment",
          description:
            "It's cardio time!! Today's quick 10 minute power cardio is intense and sweaty! 10 different cardio focused moves to get that heart rate up. No rest in between ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/LCOy-6BcRkI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/LCOy-6BcRkI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/LCOy-6BcRkI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Heather Robertson",
          liveBroadcastContent: "none",
          publishTime: "2020-05-24T21:41:30Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "_9rJC7tWAQ8tl28POLZ7aORQgmQ",
        id: {
          kind: "youtube#video",
          videoId: "ld3x6ccLs2Q",
        },
        snippet: {
          publishedAt: "2020-12-24T03:14:03Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title:
            "Caroline&#39;s CHRISTMAS CARDIO WORKOUT | 30 Minutes / No Equipment",
          description:
            "Let's get sweaty, burn some energy, test your endurance, strengthen your core and feel ready for the holidays with this 30 min Christmas Cardio Workout!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ld3x6ccLs2Q/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ld3x6ccLs2Q/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ld3x6ccLs2Q/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-12-24T03:14:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "yCJw6f9RKDhwcSg1hG6rLD4xvlI",
        id: {
          kind: "youtube#video",
          videoId: "ZMO_XC9w7Lw",
        },
        snippet: {
          publishedAt: "2018-01-09T07:57:43Z",
          channelId: "UChJHUmT_EREEs4WV6jpmnBw",
          title: "WORK OUT #LIKENINA | 30-minute LES MILLS GRIT Cardio Workout",
          description:
            "Nina Dobrev has collaborated with the Les Mills creative team and Reebok and to bring you a free 30-minute LES MILLS GRIT Cardio workout. The workout is ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ZMO_XC9w7Lw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ZMO_XC9w7Lw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ZMO_XC9w7Lw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Les Mills",
          liveBroadcastContent: "none",
          publishTime: "2018-01-09T07:57:43Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "n4b4cL8h397WM69RM1l5wCw2iw0",
        id: {
          kind: "youtube#video",
          videoId: "Ba3qZjzPonI",
        },
        snippet: {
          publishedAt: "2020-03-10T22:14:02Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title: "Low impact, high intensity intermediate home cardio workout",
          description:
            "https://teambodyproject.com For more workouts like this, come and join us. New workouts weekly - over 300 workouts and multiple plans with the Body Project ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Ba3qZjzPonI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Ba3qZjzPonI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Ba3qZjzPonI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2020-03-10T22:14:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ZlgucZwtmfnpHL_Yq4qlg8YgTrA",
        id: {
          kind: "youtube#video",
          videoId: "13ggcvTHETw",
        },
        snippet: {
          publishedAt: "2019-11-14T19:00:00Z",
          channelId: "UCaBqRxHEMomgFU-AkSfodCw",
          title: "8 Best Cardio Exercises You Can Do Anywhere",
          description:
            "Join Chris Heria as he explains the 8 Best Cardio Exercises You Can Do Anywhere and follow along as he shows you how to do them so you can start training ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/13ggcvTHETw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/13ggcvTHETw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/13ggcvTHETw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "CHRIS HERIA",
          liveBroadcastContent: "none",
          publishTime: "2019-11-14T19:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ajjZ_dQ4Mt40iE6XVdARXh_gnBc",
        id: {
          kind: "youtube#video",
          videoId: "HirKyFpbiLs",
        },
        snippet: {
          publishedAt: "2020-09-09T05:00:12Z",
          channelId: "UCTZYqASosURuJdm2ZKiOJfw",
          title:
            "DAY 3 : 40 MIN FUN CARDIO Music Workout/ No Weights/ Kickbox, Dance, and Sweat!",
          description:
            "Music makes your workout by with this fun 40 min cardio Music Workout. I added elements of dance, kickboxing, and cardio moves to give you a full-body ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/HirKyFpbiLs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/HirKyFpbiLs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/HirKyFpbiLs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Healthy Fit with Ty",
          liveBroadcastContent: "none",
          publishTime: "2020-09-09T05:00:12Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "7d3nctvlfoS5TgfSKF93ymWVzHk",
        id: {
          kind: "youtube#video",
          videoId: "0kyoJ8Wh18w",
        },
        snippet: {
          publishedAt: "2020-09-23T17:52:02Z",
          channelId: "UCi_J6WNj99ro-9cPZOUrf8Q",
          title: "Very Sweaty Cardio Workout | HIIT at HOME | no.75",
          description:
            "USE Our WORKOUT CALENDARS & MEAL PLANS – https://mrandmrsmuscle.com - Our Gymshark Outfits: https://gym.sh/muscle-home-YT - Our fat burning ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/0kyoJ8Wh18w/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/0kyoJ8Wh18w/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/0kyoJ8Wh18w/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MrandMrsMuscle",
          liveBroadcastContent: "none",
          publishTime: "2020-09-23T17:52:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "1en6B-dFnEemO2uwAiZckPIFvVg",
        id: {
          kind: "youtube#video",
          videoId: "ImI63BUUPwU",
        },
        snippet: {
          publishedAt: "2020-10-31T12:30:03Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title: "30 Min CARDIO WORKOUT at Home [LOW IMPACT STEADY STATE] LISS",
          description:
            "A perfect 30 minute full body no repeat, low impact steady state cardio workout at home (LISS) that requires a continuous, maintainable level of effort! Cardio ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ImI63BUUPwU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ImI63BUUPwU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ImI63BUUPwU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-10-31T12:30:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "85ti9Yyv6ueKdX9uEuC4LUrMTbc",
        id: {
          kind: "youtube#video",
          videoId: "lmgeM9Ne-Ns",
        },
        snippet: {
          publishedAt: "2020-12-04T20:11:36Z",
          channelId: "UCK9JEqf7LBBx3tkrPx2xvbQ",
          title: "60 MINUTE FAT BURNING CARDIO WORKOUT(NO EQUIPMENT)",
          description:
            "LETS BURN SOME CALORIES AND SHRED SOME FAT! ——————————————— WWW.GYMOCLOCKFITNESS.COM In this video, I'm going to take ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/lmgeM9Ne-Ns/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/lmgeM9Ne-Ns/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/lmgeM9Ne-Ns/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BullyJuice",
          liveBroadcastContent: "none",
          publishTime: "2020-12-04T20:11:36Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "egnMoQ8zMFcNkRK0HareiD38q-M",
        id: {
          kind: "youtube#video",
          videoId: "_Zem0_qsDg0",
        },
        snippet: {
          publishedAt: "2016-11-27T17:00:02Z",
          channelId: "UCBINFWq52ShSgUFEoynfSwg",
          title:
            "This Workout With Jeanette Jenkins Torches Calories — About 500 in 45 Minutes",
          description:
            "It's time to break a sweat! This 45-minute workout from celebrity trainer Jeanette Jenkins, who trains Pink and Alicia Keys, will help you burn up to 500 calories.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/_Zem0_qsDg0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/_Zem0_qsDg0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/_Zem0_qsDg0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "POPSUGAR Fitness",
          liveBroadcastContent: "none",
          publishTime: "2016-11-27T17:00:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "kPVCph9nUq1mTi2qfQtCereeObw",
        id: {
          kind: "youtube#video",
          videoId: "qfe4vzupWjI",
        },
        snippet: {
          publishedAt: "2020-12-28T08:02:09Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title: "LOW IMPACT home cardio workout - fat burner - NO EQUIPMENT!",
          description:
            "http://teambodyproject.com Join the team and get RESULTS! Take part in over 500 workouts, countless workout plans, and lots of support from everybody at ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/qfe4vzupWjI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/qfe4vzupWjI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/qfe4vzupWjI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2020-12-28T08:02:09Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "xeIdK7KPLT7iVRZCrHcyTcYfrFo",
        id: {
          kind: "youtube#video",
          videoId: "kZDvg92tTMc",
        },
        snippet: {
          publishedAt: "2020-12-09T18:04:44Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title:
            "30 MIN PUMPING CARDIO WORKOUT | Full Body - No Equipment at Home",
          description:
            "Let's burn some energy with this 30 minute cardio full body workout... no equipment needed. Simply follow along and have fun! This is meant for low intensity ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/kZDvg92tTMc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/kZDvg92tTMc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/kZDvg92tTMc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-12-09T18:04:44Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Rox6RqGXp5lOlZu3qVXbxo-QC3s",
        id: {
          kind: "youtube#video",
          videoId: "HzjVKXKzpNY",
        },
        snippet: {
          publishedAt: "2020-05-27T16:15:00Z",
          channelId: "UCK9JEqf7LBBx3tkrPx2xvbQ",
          title: "30 MINUTE FAT BURNING CARDIO WORKOUT(NO EQUIPMENT)",
          description: "WWW.GYMOCLOCKFITNESS.COM ———————————————",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/HzjVKXKzpNY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/HzjVKXKzpNY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/HzjVKXKzpNY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BullyJuice",
          liveBroadcastContent: "none",
          publishTime: "2020-05-27T16:15:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "WTpEc-z-zL_k6s7BLa3uApI5XNo",
        id: {
          kind: "youtube#video",
          videoId: "7iSm6rLBt20",
        },
        snippet: {
          publishedAt: "2019-11-17T17:00:11Z",
          channelId: "UCBINFWq52ShSgUFEoynfSwg",
          title: "30-Minute No-Equipment Cardio Workout",
          description:
            "Can't make it to Barry's, but still want to get your cardio in? This high-intensity cardio workout has you covered. This session includes a series of plyometric ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/7iSm6rLBt20/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/7iSm6rLBt20/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/7iSm6rLBt20/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "POPSUGAR Fitness",
          liveBroadcastContent: "none",
          publishTime: "2019-11-17T17:00:11Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "G-0AnTqH7NB3kW6Em8nn9W3DkJU",
        id: {
          kind: "youtube#video",
          videoId: "nOQpD8aDJeI",
        },
        snippet: {
          publishedAt: "2016-07-24T14:49:37Z",
          channelId: "UCqjwF8rxRsotnojGl4gM0Zw",
          title:
            "HOW TO GET THE ULTIMATE PHYSIQUE (CARDIO WORKOUT) 2016 | THENX",
          description:
            "DOWNLOAD iPhone App : https://goo.gl/Qk235s FOR ANDROID: https://goo.gl/kcRBpL SHOP THENX APPAREL: https://thenx.com/apparel/ Blog about this ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/nOQpD8aDJeI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/nOQpD8aDJeI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/nOQpD8aDJeI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "THENX",
          liveBroadcastContent: "none",
          publishTime: "2016-07-24T14:49:37Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "HmEv-sZ3qTb_uroXcoppDdXB_wE",
        id: {
          kind: "youtube#video",
          videoId: "s3F6R92s6_o",
        },
        snippet: {
          publishedAt: "2017-11-26T17:00:06Z",
          channelId: "UCBINFWq52ShSgUFEoynfSwg",
          title:
            "Burn 600 Calories in a 60-Minute Workout With Jeanette Jenkins",
          description:
            "Try Jeanette's new 15-minute ab-burner workout on: https://fb.watch/2e7iMwI55w/ Get ready to torch calories with this cardio-sculpting kickboxing workout from ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/s3F6R92s6_o/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/s3F6R92s6_o/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/s3F6R92s6_o/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "POPSUGAR Fitness",
          liveBroadcastContent: "none",
          publishTime: "2017-11-26T17:00:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "0Xweb0_wLzNBO_TqNxcjgMjmm8E",
        id: {
          kind: "youtube#video",
          videoId: "3SpPraOLJl4",
        },
        snippet: {
          publishedAt: "2020-07-13T10:31:02Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title:
            "Low impact cardio workout for ALL fitness levels - no equipment, at home!",
          description:
            "http://teambodyproject.com At home cardio workout with Team Body Project, Daniel and Alexandra Bartlett. Includes warm up and cool down.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/3SpPraOLJl4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/3SpPraOLJl4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/3SpPraOLJl4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2020-07-13T10:31:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "o1f9oqiieiN6eHyKKIusCVVRJHs",
        id: {
          kind: "youtube#video",
          videoId: "8JpKOczCPHU",
        },
        snippet: {
          publishedAt: "2018-12-13T17:00:01Z",
          channelId: "UCGiSCVGNukLqv8hwpKCsQKQ",
          title:
            "25 Minute Full Body Cardio Workout - No Equipment With Warm-Up and Cool-Down | SELF",
          description:
            "Rhys and Amy take us through a 25-minute full body cardio workout. This workout consists of pop squats, leg raises to toe touches, star toe touches, push-ups, ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8JpKOczCPHU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8JpKOczCPHU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8JpKOczCPHU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "SELF",
          liveBroadcastContent: "none",
          publishTime: "2018-12-13T17:00:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "KQj4rB2Y8KIMHjWFR5rQU1QINxI",
        id: {
          kind: "youtube#video",
          videoId: "ctlLJgxArZM",
        },
        snippet: {
          publishedAt: "2017-11-02T19:00:03Z",
          channelId: "UCOFCwvhDoUvYcfpD7RJKQwA",
          title: "V Shred 12 Minute Fat Burning Cardio Workout | No Equipment",
          description:
            "If you like this cardio workout, join V Shred University for a discount here - http://vshred.fit/FullBodyHIIT -------------------------------------------------------------------- Check ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ctlLJgxArZM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ctlLJgxArZM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ctlLJgxArZM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "V Shred",
          liveBroadcastContent: "none",
          publishTime: "2017-11-02T19:00:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "vUttkFhHlBA55OaVulMIcDmwhVY",
        id: {
          kind: "youtube#video",
          videoId: "gC_L9qAHVJ8",
        },
        snippet: {
          publishedAt: "2019-03-24T20:07:35Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title:
            "30 minute fat burning home workout for beginners. Achievable, low impact results.",
          description:
            "https://teambodyproject.com Create a free account today. This workout is part of Real Start and Real Start Plus - a workout plan made for real people with real ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/gC_L9qAHVJ8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/gC_L9qAHVJ8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/gC_L9qAHVJ8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2019-03-24T20:07:35Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "AHaVGpwIu8UxGOF1nIOS0P1pXn0",
        id: {
          kind: "youtube#video",
          videoId: "BR0jT6JxH-o",
        },
        snippet: {
          publishedAt: "2015-10-21T18:02:50Z",
          channelId: "UCKik8uG08NYJStvTW7ZgUAQ",
          title:
            "Bowflex® Bodyweight Workout | Five-Minute Cardio Blast Workout",
          description:
            "Subscribe for more workouts and tips: http://goo.gl/sxLYmP Additional fitness tips: http://goo.gl/vFWbTL Quick Workouts: http://goo.gl/mj1b0C Sometimes fitting a ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/BR0jT6JxH-o/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/BR0jT6JxH-o/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/BR0jT6JxH-o/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bowflex",
          liveBroadcastContent: "none",
          publishTime: "2015-10-21T18:02:50Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "GauR7RHJ8qhtBbqBThMfXmvSZNo",
        id: {
          kind: "youtube#video",
          videoId: "iNW4lCU693Q",
        },
        snippet: {
          publishedAt: "2020-10-20T09:24:48Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title:
            "Fat burning Beginner LOW IMPACT home cardio workout - all standing!",
          description:
            "http://teambodyproject.com For more workouts just like this one, come and join the team.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/iNW4lCU693Q/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/iNW4lCU693Q/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/iNW4lCU693Q/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2020-10-20T09:24:48Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "7yaBB2yCAgkyqwk6O___2NkZ2lE",
        id: {
          kind: "youtube#video",
          videoId: "filPQQnX-wM",
        },
        snippet: {
          publishedAt: "2020-07-02T03:02:52Z",
          channelId: "UCFoAa0v280gPKU2KicYYleQ",
          title: "Kayla Itsines 30-Minute No-Equipment Cardio Workout",
          description:
            "You can do this 30-minute full-body workout from almost anywhere — it requires no equipment, making it a great addition to your at-home exercise routine.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/filPQQnX-wM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/filPQQnX-wM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/filPQQnX-wM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "SWEAT",
          liveBroadcastContent: "none",
          publishTime: "2020-07-02T03:02:52Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "_o62rNNY6Q8srm8L6cZrooPy_2Q",
        id: {
          kind: "youtube#video",
          videoId: "wLYeRlyyncY",
        },
        snippet: {
          publishedAt: "2014-10-09T13:41:55Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title: "Intermediate Cardio workout",
          description:
            "https://teambodyproject.com Start your own Body Project today and transform your body. Access the ENTIRE BODY PROJECT SYSTEM, calendars and more.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/wLYeRlyyncY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/wLYeRlyyncY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/wLYeRlyyncY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2014-10-09T13:41:55Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "nkCSzP10NzJeDK8t_WOg5PQAsUM",
        id: {
          kind: "youtube#video",
          videoId: "VWj8ZxCxrYk",
        },
        snippet: {
          publishedAt: "2020-12-11T18:16:27Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "15 MIN BEGINNER CARDIO Workout (At Home No Equipment)",
          description:
            "Do this 15 minute beginner, full body, cardio workout #WithMe at home! This is a non-stop cardio routine with basic, low impact exercises for beginners.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/VWj8ZxCxrYk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/VWj8ZxCxrYk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/VWj8ZxCxrYk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-12-11T18:16:27Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "A5rRm0QaHfWe9qnh1araOQ6Q1S4",
        id: {
          kind: "youtube#video",
          videoId: "zXK6oXwRr1U",
        },
        snippet: {
          publishedAt: "2015-04-02T19:00:25Z",
          channelId: "UCFjc9H89-RpWuIStDqhO7AQ",
          title: "25 minute interval cardio workout from home",
          description:
            "https://teambodyproject.com https://www.instagram.com/teambodyproject/ Transform your body in just 10 weeks and take part in the entire Body Project system!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/zXK6oXwRr1U/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/zXK6oXwRr1U/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/zXK6oXwRr1U/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Body Project",
          liveBroadcastContent: "none",
          publishTime: "2015-04-02T19:00:25Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "urp9ta1EVntG01bhPFo2x28EIfQ",
        id: {
          kind: "youtube#video",
          videoId: "wwkrup_TG6I",
        },
        snippet: {
          publishedAt: "2016-09-21T18:00:06Z",
          channelId: "UCXIJ2-RSIGn53HA-x9RDevA",
          title:
            "20 Minute HIIT Home Cardio Workout Without Equipment - Full Body HIIT Workout No Equipment at Home",
          description:
            "Download the FREE HASfit app: Android http://bit.ly/HASfitAndroid -- iPhone http://bit.ly/HASfitiOS Visit ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/wwkrup_TG6I/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/wwkrup_TG6I/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/wwkrup_TG6I/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "HASfit",
          liveBroadcastContent: "none",
          publishTime: "2016-09-21T18:00:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "6KljjJ_v7RoWglqiMUmqix4H6AA",
        id: {
          kind: "youtube#video",
          videoId: "2S9YiDUI9hA",
        },
        snippet: {
          publishedAt: "2015-07-05T16:00:01Z",
          channelId: "UCBINFWq52ShSgUFEoynfSwg",
          title: "Burn Up the Calories With This At-Home Cardio Workout",
          description:
            "POPSUGAR Fitness offers fresh fitness tutorials, workouts, and exercises that will help you on your road to healthy living, weight loss, and stress relief. Check out ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/2S9YiDUI9hA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/2S9YiDUI9hA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/2S9YiDUI9hA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "POPSUGAR Fitness",
          liveBroadcastContent: "none",
          publishTime: "2015-07-05T16:00:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "v2Ig8J1XzHVPAxUNDLDL6hSHpfo",
        id: {
          kind: "youtube#video",
          videoId: "SDhyfxf81nU",
        },
        snippet: {
          publishedAt: "2020-10-19T12:00:30Z",
          channelId: "UCL0-zfZcvpeagrmUcvt3F3A",
          title:
            "BURN 600 CALORIES with this 45-minute cardio AT HOME workout (No Equipment!)",
          description:
            "Sign up for my free Get Fit in 30 Days workout program: https://www.rachelgulottafitness.com/. I'm pretty sure I drank an entire gallon of coconut water before I ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/SDhyfxf81nU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/SDhyfxf81nU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/SDhyfxf81nU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Rachel Gulotta Fitness",
          liveBroadcastContent: "none",
          publishTime: "2020-10-19T12:00:30Z",
        },
      },
    ],
  },
];
