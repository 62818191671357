export const CoreWorkouts = [
  {
    kind: "youtube#searchListResponse",
    etag: "22-gdk9x3F256_4wIGfES9537S0",
    nextPageToken: "CDIQAA",
    regionCode: "GB",
    pageInfo: {
      totalResults: 1000000,
      resultsPerPage: 50,
    },
    items: [
      {
        kind: "youtube#searchResult",
        etag: "NAwqOl8_UBYFXyW5pqBH8JMSY60",
        id: {
          kind: "youtube#video",
          videoId: "AnYl6Nk9GOA",
        },
        snippet: {
          publishedAt: "2019-10-20T07:00:00Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title: "10 MIN AB WORKOUT // No Equipment | Pamela Reif",
          description:
            'inspired by my most popular "Sixpack Workout" - the NEW VERSION! // Werbung Whenever I meet people who workout with my videos & ask them which one ...',
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/AnYl6Nk9GOA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/AnYl6Nk9GOA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/AnYl6Nk9GOA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2019-10-20T07:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Smq413Ue3sivYuVmKY8M1vcYKRg",
        id: {
          kind: "youtube#video",
          videoId: "glxrwC9zsHY",
        },
        snippet: {
          publishedAt: "2018-12-15T19:18:25Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "15 MIN TOTAL CORE/AB WORKOUT (At Home No Equipment)",
          description:
            "15 Min Abs Routine that you can do anywhere! At home & equipment free. SUBSCRIBE TO MY MAIN CHANNEL (what i eat, recipes, vlogs): ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/glxrwC9zsHY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/glxrwC9zsHY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/glxrwC9zsHY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2018-12-15T19:18:25Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Ts9phrHfNZvpOz20L9cfQ6PiBCA",
        id: {
          kind: "youtube#video",
          videoId: "2pLT-olgUJs",
        },
        snippet: {
          publishedAt: "2019-08-12T11:45:00Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title: "Get Abs in 2 WEEKS | Abs Workout Challenge",
          description:
            "Abs Abs Abs! Everyone seems to be asking for a QUICK and short schedule, so I put together a 2 weeks schedule to help you get closer to those defined abs ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/2pLT-olgUJs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/2pLT-olgUJs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/2pLT-olgUJs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2019-08-12T11:45:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "0lcWT1f_KvLY5ns3hOTkSxoM6uI",
        id: {
          kind: "youtube#video",
          videoId: "uUKAYkQZXko",
        },
        snippet: {
          publishedAt: "2018-12-27T19:00:07Z",
          channelId: "UCyiNCEHLyKbSUrrncVr9KvQ",
          title: "10 Minute Home Ab Workout (6 PACK GUARANTEED!)",
          description:
            "Get ready for one of the best Home Ab Workouts of your LIFE! Let's do this! A full body workout that you can do whenever and wherever you like!! You don't need ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/uUKAYkQZXko/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/uUKAYkQZXko/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/uUKAYkQZXko/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fraser Wilson",
          liveBroadcastContent: "none",
          publishTime: "2018-12-27T19:00:07Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "3qSIzMpIqOA-Svn_mdQOvMtL_yI",
        id: {
          kind: "youtube#video",
          videoId: "1f8yoFFdkcY",
        },
        snippet: {
          publishedAt: "2020-02-16T08:00:00Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title: "10 MIN BEGINNER AB WORKOUT // No Equipment | Pamela Reif",
          description:
            'Ohhhh yes - a BEGINNER ab workout! ♥︎ / Werbung If you have a hard time keeping up with my super intense "10min Ab Workout" and "10min Sixpack ...',
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/1f8yoFFdkcY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/1f8yoFFdkcY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/1f8yoFFdkcY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-02-16T08:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "PYkxGrlJC15774asJDFKFVZfLSE",
        id: {
          kind: "youtube#video",
          videoId: "5cMYysFl4CY",
        },
        snippet: {
          publishedAt: "2016-04-21T16:55:21Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title: "10 MIN INTENSE AB WORKOUT // No Equipment | Pamela RF",
          description:
            "QUICK & INTENSE. NO EQUIPMENT NEEDED. NO EXCUSES ♥︎ // Werbung The video is in full length which means you can just follow whatever I'm doing!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/5cMYysFl4CY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/5cMYysFl4CY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/5cMYysFl4CY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2016-04-21T16:55:21Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "VgRdGSf8US8FBX8cHRhcSvo7-ec",
        id: {
          kind: "youtube#video",
          videoId: "EfJ4aB_enVE",
        },
        snippet: {
          publishedAt: "2020-06-12T05:30:00Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "15 MIN SIXPACK WORKOUT - intense ab workout / No Equipment I Pamela Reif",
          description:
            "A new KILLER sixpack workout! Feel (and enjoy.. or hate me for) the buuuuuurn ♥︎ / Werbung This routine starts with a concentrated and slow exercises: for ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/EfJ4aB_enVE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/EfJ4aB_enVE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/EfJ4aB_enVE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-06-12T05:30:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "oBcAtO6f0d99SA-JqTsa8iT2Mj0",
        id: {
          kind: "youtube#video",
          videoId: "DHD1-2P94DI",
        },
        snippet: {
          publishedAt: "2018-10-25T17:34:06Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "Intense Ab Workout | 7 Minutes (FOLLOW ALONG!)",
          description:
            "Build Muscle in 90 Days - http://athleanx.com/x/my-workouts Subscribe to this channel here - http://bit.ly/2b0coMW If you want to develop six pack abs you may ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/DHD1-2P94DI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/DHD1-2P94DI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/DHD1-2P94DI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2018-10-25T17:34:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "UflFIP3zWFNgdcs8tWxzIhN-vmQ",
        id: {
          kind: "youtube#video",
          videoId: "yEllr_kIV4I",
        },
        snippet: {
          publishedAt: "2020-02-05T18:00:09Z",
          channelId: "UCdOdchV00nejfJtbXf6BPxg",
          title: "10 Minute Ab Workout // No Equipment | Sami Clarke",
          description:
            "Outfit: Saski Collection - https://saskicollection.com/collections/all-shorts/products/neon-pink-shorts Yoga Mat: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/yEllr_kIV4I/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/yEllr_kIV4I/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/yEllr_kIV4I/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Sami Clarke",
          liveBroadcastContent: "none",
          publishTime: "2020-02-05T18:00:09Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "CXuCvqJdifnmJF1wiOYyQ7qs_2M",
        id: {
          kind: "youtube#video",
          videoId: "8AAmaSOSyIA",
        },
        snippet: {
          publishedAt: "2020-03-27T13:30:00Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "20 MIN TOTAL CORE/AB WORKOUT (At Home No Equipment)",
          description:
            "20 min INTENSE ab workout you can do anywhere & is equipment free! Work on toning your core at home with this killer abs workout! ⭐️SHOP MY ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8AAmaSOSyIA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8AAmaSOSyIA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8AAmaSOSyIA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-03-27T13:30:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "36Xftb1cd9deQg5G-xibjEzxC5c",
        id: {
          kind: "youtube#video",
          videoId: "54x6yjnzLms",
        },
        snippet: {
          publishedAt: "2020-12-15T05:00:00Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title: "10 MIN LOWER AB WORKOUT / No Equipment I Pamela Reif",
          description:
            "train that stubborn lower part of your belly ♥︎ / Werbung One of the most requested videos ever! I never wanted to do it because most people advertise their ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/54x6yjnzLms/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/54x6yjnzLms/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/54x6yjnzLms/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-12-15T05:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "g5CMp8tHP1mpo-HRvpKgcShLjEg",
        id: {
          kind: "youtube#video",
          videoId: "LebPal5gKrc",
        },
        snippet: {
          publishedAt: "2013-05-29T20:04:21Z",
          channelId: "UCM1Nde-9eorUhq-teaWlgUA",
          title: "The 7 Best Ab Exercises",
          description:
            "Sign up for our newsletter: http://goo.gl/UwnMd Subscribe: http://goo.gl/qR0gi On today's episode of XHIT, fitness trainer Rebecca-Louise shows you the best ab ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/LebPal5gKrc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/LebPal5gKrc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/LebPal5gKrc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "XHIT Daily",
          liveBroadcastContent: "none",
          publishTime: "2013-05-29T20:04:21Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ICim_tkaO6s_xBVryJONesfHcb4",
        id: {
          kind: "youtube#video",
          videoId: "3M3W5VCsuCY",
        },
        snippet: {
          publishedAt: "2019-09-25T21:09:01Z",
          channelId: "UC8-z0Suk0TKnwh7el3cNpaw",
          title: "6 MINUTE AB WORKOUT!!! (no equipment)",
          description:
            "Instagram - https://www.instagram.com/morganrosemoroney/?hl=en TIKTOK - https://www.tiktok.com/@morganrmoroney?lang=en Email ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/3M3W5VCsuCY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/3M3W5VCsuCY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/3M3W5VCsuCY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Morgan Rose Moroney",
          liveBroadcastContent: "none",
          publishTime: "2019-09-25T21:09:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "TYP4QbgKzwuz_h5qJusI_CXI-K8",
        id: {
          kind: "youtube#video",
          videoId: "Goo0g_zmFBY",
        },
        snippet: {
          publishedAt: "2020-03-29T08:00:02Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "12 MIN AB WORKOUT - Medium Level / No Equipment I Pamela Reif",
          description:
            "I know I'm popular for my super duper intense Sixpack Workouts .. and I also have Beginner ones. But what about something IN BETWEEN? ♥︎ / Werbung If ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Goo0g_zmFBY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Goo0g_zmFBY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Goo0g_zmFBY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-03-29T08:00:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "PwvQ4B42etboGqf77mgVxK3XRys",
        id: {
          kind: "youtube#video",
          videoId: "k0emMmLD1AE",
        },
        snippet: {
          publishedAt: "2020-08-03T13:00:23Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "12 MIN TOTAL CORE/AB WORKOUT (At Home No Equipment)",
          description:
            "12 Min Abs Routine that you can do anywhere! At home & equipment free. #WithMe #HomeWorkout #AbsWorkout ⭐️SHOP MY COOKBOOKS!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/k0emMmLD1AE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/k0emMmLD1AE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/k0emMmLD1AE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-08-03T13:00:23Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "VsTuWfzdYcnj-hFXNZMDyar78NQ",
        id: {
          kind: "youtube#video",
          videoId: "rptV4dEJUx4",
        },
        snippet: {
          publishedAt: "2018-12-04T18:01:40Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "10 min LOWER ABS Workout | LOSE LOWER BELLY FAT",
          description:
            "A 10 min intense lower abs AT HOME NO EQUIPMENT workout to target your lower belly. How to lose lower belly fat! ⭐️ GRAB MY COOKBOOK!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/rptV4dEJUx4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/rptV4dEJUx4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/rptV4dEJUx4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2018-12-04T18:01:40Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "fh6gYcF2X5XYtQV6da7U6K7iacI",
        id: {
          kind: "youtube#video",
          videoId: "9p7-YC91Q74",
        },
        snippet: {
          publishedAt: "2019-12-20T15:30:00Z",
          channelId: "UCyiNCEHLyKbSUrrncVr9KvQ",
          title:
            "10 MIN PERFECT ABS WORKOUT (NO EQUIPMENT BODYWEIGHT WORKOUT!)",
          description:
            "To develop a truly impressive Sixpack, you will want to train from abs a whole range of perspectives. You see, the core is generally made up of four key ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/9p7-YC91Q74/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/9p7-YC91Q74/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/9p7-YC91Q74/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fraser Wilson",
          liveBroadcastContent: "none",
          publishTime: "2019-12-20T15:30:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "sGcHjfLz0aK2H7yvIZjY5hu-9Cw",
        id: {
          kind: "youtube#video",
          videoId: "3p8EBPVZ2Iw",
        },
        snippet: {
          publishedAt: "2018-07-02T01:25:44Z",
          channelId: "UCqjwF8rxRsotnojGl4gM0Zw",
          title: "6 PACK ABS For Beginners You Can Do Anywhere",
          description:
            "6 PACK ABS workouts you can do anywhere if you are a beginner. Try these workouts when starting to build ABS! Get the Music in the video made by Chris ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/3p8EBPVZ2Iw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/3p8EBPVZ2Iw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/3p8EBPVZ2Iw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "THENX",
          liveBroadcastContent: "none",
          publishTime: "2018-07-02T01:25:44Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "MDvvq94vjdlC1tExUVegUYKILyc",
        id: {
          kind: "youtube#video",
          videoId: "zXITzjuRXYo",
        },
        snippet: {
          publishedAt: "2020-11-13T15:44:53Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "5 MIN FLAT ABS WORKOUT (At Home No Equipment)",
          description:
            "Here is a quick, yet INTENSE ab burning workout you can do at home, in only 5 minutes! 5 minutes, 5 exercises, no equipment, no excuses! #AbsWorkout ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/zXITzjuRXYo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/zXITzjuRXYo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/zXITzjuRXYo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-11-13T15:44:53Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "1Mk-o8hTHpwoNCFM_7Ln9Gk54iE",
        id: {
          kind: "youtube#video",
          videoId: "1919eTCoESo",
        },
        snippet: {
          publishedAt: "2012-01-17T19:12:28Z",
          channelId: "UCiP6wD_tYlYLYh3agzbByWQ",
          title:
            "10 Min Abs Workout -- At Home Abdominal and Oblique Exercises",
          description:
            "Find out how many calories this 10 Minute Abs Workout burns @ https://gofb.info/tkVKHT At-home Workout Programs @ https://gofb.info/Programs Exclusive ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/1919eTCoESo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/1919eTCoESo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/1919eTCoESo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "FitnessBlender",
          liveBroadcastContent: "none",
          publishTime: "2012-01-17T19:12:28Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "NPu1l4rYII7WoRVRIpcr4dTc0vM",
        id: {
          kind: "youtube#video",
          videoId: "cbCt69XAUKA",
        },
        snippet: {
          publishedAt: "2018-01-25T19:41:00Z",
          channelId: "UCSoKaD27LRI9nBPJ2KB1lnQ",
          title: "10 MINUTE AB WORKOUT | ALEXIS REN",
          description:
            "Sign up for Wearewarriors.co for more videos like this one! :) It's my private fitness & lifestyle community! it was late when i filmed this so excuse my giggles and ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/cbCt69XAUKA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/cbCt69XAUKA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/cbCt69XAUKA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Alexis Ren",
          liveBroadcastContent: "none",
          publishTime: "2018-01-25T19:41:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "IVg1IO_W9qQfw0UNUzIKzndbOPA",
        id: {
          kind: "youtube#video",
          videoId: "rPPu5RqB_TU",
        },
        snippet: {
          publishedAt: "2020-01-02T11:33:26Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title: "10 min Abs Workout for a Flat Stomach | Get ABS this 2020",
          description:
            "Start your New Year's Resolutions with my 28 Day Flat Tummy Challenge! 6 episodes this month including a full body workout, a morning hiit cardio, flat stomach ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/rPPu5RqB_TU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/rPPu5RqB_TU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/rPPu5RqB_TU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2020-01-02T11:33:26Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "6yRVSjzlex7ZyT9MrFpSkrBbqyc",
        id: {
          kind: "youtube#video",
          videoId: "vkKCVCZe474",
        },
        snippet: {
          publishedAt: "2010-04-02T10:46:55Z",
          channelId: "UCNAjjkbNbaVeH4o617fTC4g",
          title: "8 Min Abs Workout how to have six pack",
          description:
            "Watch our Best of videos playlist http://bit.ly/2QiCD6z Subscribe to our channel http://bit.ly/2Nx4u01 8 Min Abs Workout how to have six pack has been ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/vkKCVCZe474/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/vkKCVCZe474/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/vkKCVCZe474/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Passion4Profession",
          liveBroadcastContent: "none",
          publishTime: "2010-04-02T10:46:55Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Rac-pWm_JC1iOoPtKj9sA7R3GLY",
        id: {
          kind: "youtube#video",
          videoId: "xXHnhmDSRL8",
        },
        snippet: {
          publishedAt: "2017-04-01T20:57:47Z",
          channelId: "UCAxW1XT0iEJo0TYlRfn6rYQ",
          title: "10 Minute Abs Workout | The Body Coach",
          description:
            "Here's another great abs circuit for you to add to the end of a workout. 10 exercises | 40 seconds work | 20 seconds rest Abdominal Crunch Single Leg Crunch ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/xXHnhmDSRL8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/xXHnhmDSRL8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/xXHnhmDSRL8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "The Body Coach TV",
          liveBroadcastContent: "none",
          publishTime: "2017-04-01T20:57:47Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "WpBTCDpFwrUzLQfV2qKmvzqxI88",
        id: {
          kind: "youtube#video",
          videoId: "DYD6vr-wfjg",
        },
        snippet: {
          publishedAt: "2020-11-12T15:30:00Z",
          channelId: "UCyiNCEHLyKbSUrrncVr9KvQ",
          title: "10 MIN HOME AB WORKOUT (GET ABS IN 4 WEEKS!)",
          description:
            "It's time to get Abs from home! Get ready for one of the best Home Ab Workouts of your LIFE! Let's do this! A full home ab workout that you can do whenever and ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/DYD6vr-wfjg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/DYD6vr-wfjg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/DYD6vr-wfjg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fraser Wilson",
          liveBroadcastContent: "none",
          publishTime: "2020-11-12T15:30:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "2BkwmuRJuyw6U_B7zn64R0LQ4Sg",
        id: {
          kind: "youtube#video",
          videoId: "5i8y-_cbwgw",
        },
        snippet: {
          publishedAt: "2020-08-19T08:00:00Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title:
            "30 Min DEFINED ABS WORKOUT at Home | No Equipment | No Repeat",
          description:
            "This is a no repeat 30 minute ab workout and core workout you can do anywhere, focusing on the rectus abdominus muscles, the obliques and the strength of ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/5i8y-_cbwgw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/5i8y-_cbwgw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/5i8y-_cbwgw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-08-19T08:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ySpjuQn8KQLwXOk6dWElEf2rujM",
        id: {
          kind: "youtube#video",
          videoId: "f0lCIn461co",
        },
        snippet: {
          publishedAt: "2020-08-18T16:30:04Z",
          channelId: "UCyiNCEHLyKbSUrrncVr9KvQ",
          title: "10 MIN AB WORKOUT (NO EQUIPMENT BODYWEIGHT WORKOUT)",
          description:
            "Get ready for one of the best Home Ab Workouts of your LIFE! Let's do this! A full body workout that you can do whenever and wherever you like!! You don't need ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/f0lCIn461co/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/f0lCIn461co/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/f0lCIn461co/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fraser Wilson",
          liveBroadcastContent: "none",
          publishTime: "2020-08-18T16:30:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "9AC5Q3sc8AnCmNCb8tbgNaizGrE",
        id: {
          kind: "youtube#video",
          videoId: "r7gKGvjfyHg",
        },
        snippet: {
          publishedAt: "2020-12-01T18:49:32Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title: "10 MIN AB COMPLEX | Total Abs Workout - NO EQUIPMENT",
          description:
            "This 10 min abs workout is a perfect finisher to any workout or great for active rest days along side cardio! Please remember... ab specific workouts will not ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/r7gKGvjfyHg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/r7gKGvjfyHg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/r7gKGvjfyHg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-12-01T18:49:32Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "onXi1y8Dj5j-x0GFOpUMRn6outE",
        id: {
          kind: "youtube#video",
          videoId: "3oeimlA6s68",
        },
        snippet: {
          publishedAt: "2020-10-22T16:20:17Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title:
            "20 Min ABS WORKOUT at Home [NO EQUIPMENT + NO REPEAT] Caroline Girvan",
          description:
            "No equipment needed for this 20 minute at home abs workout. I do love some stretching and some extra ab work on my active rest days so I thought I would ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/3oeimlA6s68/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/3oeimlA6s68/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/3oeimlA6s68/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-10-22T16:20:17Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "KU4EBsA6k5f_xhB0KAaODNaWtZs",
        id: {
          kind: "youtube#video",
          videoId: "aWJo_Fe20aE",
        },
        snippet: {
          publishedAt: "2020-10-11T07:59:48Z",
          channelId: "UCAxW1XT0iEJo0TYlRfn6rYQ",
          title: "10 Minute Abs Workout | The Body Coach",
          description:
            "This is a great little Abs session you can do on it's own or you can add it on to the end of a workout as a finisher.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/aWJo_Fe20aE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/aWJo_Fe20aE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/aWJo_Fe20aE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "The Body Coach TV",
          liveBroadcastContent: "none",
          publishTime: "2020-10-11T07:59:48Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "-lJrAD6L6TNqritxHZ-BosuCe78",
        id: {
          kind: "youtube#video",
          videoId: "vwk7XPs5YdM",
        },
        snippet: {
          publishedAt: "2020-06-08T00:07:08Z",
          channelId: "UCqjwF8rxRsotnojGl4gM0Zw",
          title: "6 Minute 6 Pack ABS Workout(Follow Along)",
          description:
            "Join Chris Heria as he shows you a super effective 6 Minute 6 Pack ABS Workout that's great for any level athlete from beginners to advanced. Follow along as ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/vwk7XPs5YdM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/vwk7XPs5YdM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/vwk7XPs5YdM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "THENX",
          liveBroadcastContent: "none",
          publishTime: "2020-06-08T00:07:08Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "UePmc6IlpNuiBL_nRmpBA_0iCc0",
        id: {
          kind: "youtube#video",
          videoId: "vhcyvcbVBQQ",
        },
        snippet: {
          publishedAt: "2018-12-27T19:00:03Z",
          channelId: "UCaBqRxHEMomgFU-AkSfodCw",
          title: "5 MIN AB WORKOUT You Can Do Every Day (NO REST)",
          description:
            "Follow along as Chris Heria shows you a 5 minute ab workout you can do every day at any time and any place. NO GYM NO PROBLEM Instagram: @chrisheria ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/vhcyvcbVBQQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/vhcyvcbVBQQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/vhcyvcbVBQQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "CHRIS HERIA",
          liveBroadcastContent: "none",
          publishTime: "2018-12-27T19:00:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "tyZLUfwSZ6wyQRPOFjxgZkQ3pgI",
        id: {
          kind: "youtube#video",
          videoId: "JLdSuFF62AI",
        },
        snippet: {
          publishedAt: "2020-05-22T17:19:52Z",
          channelId: "UC_nfjMmJlJXrhl2xbP9wgRA",
          title: "Perfect ABS Workout To Get 6 PACK (RESULTS GUARANTEED)",
          description:
            "Perfect ABS Workout To Get 6 PACK | RESULTS GUARANTEED #abs #abworkout #6packabs Full Home program schedule at http://igorvoitenko.com/ My ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/JLdSuFF62AI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/JLdSuFF62AI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/JLdSuFF62AI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "NEXT Workout",
          liveBroadcastContent: "none",
          publishTime: "2020-05-22T17:19:52Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "K76Qeo4TfPJLxsGWbxhWgwkMK0Y",
        id: {
          kind: "youtube#video",
          videoId: "cO7iMCci904",
        },
        snippet: {
          publishedAt: "2020-04-23T15:00:15Z",
          channelId: "UCIJwWYOfsCfz6PjxbONYXSg",
          title:
            "Extreme Abs Workout | 25 Min At Home Upper Abs, Lower Abs, Obliques &amp; Total Core Pilates Routine",
          description:
            "You guys!! I can't believe we hit 5 million!!! 10 years ago, I was uploading YouTube videos to just 40 of my students. NOW THERE'S 5 MILLION OF YOU?",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/cO7iMCci904/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/cO7iMCci904/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/cO7iMCci904/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "blogilates",
          liveBroadcastContent: "none",
          publishTime: "2020-04-23T15:00:15Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "0lHQuRiDwHFSBHEX6o1EQBWJyVg",
        id: {
          kind: "youtube#video",
          videoId: "6TmQiugy_qw",
        },
        snippet: {
          publishedAt: "2019-04-30T11:08:03Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title: "Abs Workout 🔥Get that 11 Line Abs in 35 days",
          description:
            "Want that 11 line abs? It's the 2019 Summer Shred Challenge! Time to burn that belly fat away, lose weight in time to get that summer body! This intense abs ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/6TmQiugy_qw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/6TmQiugy_qw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/6TmQiugy_qw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2019-04-30T11:08:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ET78zuftkb-EACx-snCHViWD8vw",
        id: {
          kind: "youtube#video",
          videoId: "hbjq9_NER_k",
        },
        snippet: {
          publishedAt: "2020-01-25T14:55:25Z",
          channelId: "UCPiWIjwDZQo3UobMqvi4ggg",
          title: "Extreme Abs Workout - 7 Min - At Home with Cirque du Soleil",
          description:
            "Tone and strengthen your core with Elizabeth's infamous “unicycle abs” workout! Why unicycle? Artists on our show TOTEM gather around backstage as ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/hbjq9_NER_k/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/hbjq9_NER_k/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/hbjq9_NER_k/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Cirque du Soleil",
          liveBroadcastContent: "none",
          publishTime: "2020-01-25T14:55:25Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "7t9lVW1ERojPGDYT9gjhV_NGxZs",
        id: {
          kind: "youtube#video",
          videoId: "WdmSDzKdgkI",
        },
        snippet: {
          publishedAt: "2019-07-23T17:14:20Z",
          channelId: "UCjfG0dyMUiqKleUnkX6zBrA",
          title: "10 minute AB WORKOUT | Train Anywhere, No Equipment",
          description:
            "A no equipment, challenging ab workout you can do anywhere and follow-along easily For more workouts to build muscle or lose fat, my training and nutrition ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/WdmSDzKdgkI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/WdmSDzKdgkI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/WdmSDzKdgkI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Natacha Océane",
          liveBroadcastContent: "none",
          publishTime: "2019-07-23T17:14:20Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "KEAa0hFiN740onVdEmPsEGl28Gs",
        id: {
          kind: "youtube#video",
          videoId: "2Aigjh1ZPhw",
        },
        snippet: {
          publishedAt: "2020-10-29T16:46:00Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "15 MIN AB WORKOUT (Total Core, No Equipment, At Home)",
          description:
            "15 Min INTENSE TOTAL CORE/Abs Routine that you can do anywhere! At home & equipment free. Follow along with me in this real time, killer workout!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/2Aigjh1ZPhw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/2Aigjh1ZPhw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/2Aigjh1ZPhw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-10-29T16:46:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "QXkWqQibrE_pS3bSa8ex16OB1gk",
        id: {
          kind: "youtube#video",
          videoId: "jC5xlKIQgR8",
        },
        snippet: {
          publishedAt: "2020-10-07T04:00:08Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "6 MIN BRUTAL AB WORKOUT - intense sixpack workout, short and sweet / No Equipment I Pamela Reif",
          description:
            '"I got no time" is no excuse for this routine! Super quick, really intense and: effective ♥︎ / Werbung This routine burns like heeellllll in my abs! ▸ make sure to ...',
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/jC5xlKIQgR8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/jC5xlKIQgR8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/jC5xlKIQgR8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-10-07T04:00:08Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "icEXKCPhAZ__0hp0lLf3pTo3QMs",
        id: {
          kind: "youtube#video",
          videoId: "Bc6G41KutHE",
        },
        snippet: {
          publishedAt: "2020-11-18T14:00:02Z",
          channelId: "UCOpsZxrmeDARilha1uq4slA",
          title: "20 Minute Core Strength // No Repeats AB Workout!",
          description:
            "It's all about core strength in today's 20 minute no repeats ab workout! We are powering through a series of core exercises that will get those abs burning!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Bc6G41KutHE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Bc6G41KutHE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Bc6G41KutHE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Heather Robertson",
          liveBroadcastContent: "none",
          publishTime: "2020-11-18T14:00:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "YaJKpcFvobIlfwbxwNtvCAqQIrA",
        id: {
          kind: "youtube#video",
          videoId: "M6VjjM4nMLo",
        },
        snippet: {
          publishedAt: "2018-03-28T23:00:00Z",
          channelId: "UCnmGIkw-KdI0W5siakKPKog",
          title: "7 Minute Ab Workout (6 PACK GUARANTEED!)",
          description:
            "SUBSCRIBE ▻ http://bit.ly/SUB2RYANTRAHAN SHOP NEPTUNE BOTTLES ▻ http://amzn.to/2zCS7Xs PODCAST ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/M6VjjM4nMLo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/M6VjjM4nMLo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/M6VjjM4nMLo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Ryan Trahan",
          liveBroadcastContent: "none",
          publishTime: "2018-03-28T23:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "yld5-6H_63TWYbg0P1_eB2oNMKg",
        id: {
          kind: "youtube#video",
          videoId: "jn5IMNPMRR0",
        },
        snippet: {
          publishedAt: "2020-05-02T09:22:17Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "15 MIN AB WORKOUT - Let&#39;s Train Together / No Equipment I Pamela Reif",
          description:
            "A NEW routine with lots of new exercises / Werbung ♥︎ We train upper abs, lower abs & side abs! That's why we need 15min - we gotta do the whole thing :) I ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/jn5IMNPMRR0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/jn5IMNPMRR0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/jn5IMNPMRR0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-05-02T09:22:17Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "28gg1Zm5q06lZkmRjC86XIK4cUQ",
        id: {
          kind: "youtube#video",
          videoId: "l2AHpn2i7_Y",
        },
        snippet: {
          publishedAt: "2020-08-05T04:30:02Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "10 MIN AB WORKOUT - Back &amp; Neck Friendly / No Equipment I Pamela Reif",
          description:
            "an intense Sixpack Routine, that's friendly for your back & neck ♥︎ if you usually suffer from neck pain or have problems with your lower back: this one might ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/l2AHpn2i7_Y/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/l2AHpn2i7_Y/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/l2AHpn2i7_Y/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-08-05T04:30:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "GyCe5K5LtUq6s6F0UrlhuEu-o68",
        id: {
          kind: "youtube#video",
          videoId: "cfuePZeBFgY",
        },
        snippet: {
          publishedAt: "2020-05-04T10:00:20Z",
          channelId: "UCdOdchV00nejfJtbXf6BPxg",
          title:
            "10 MIN Sexy Abs Floor Routine // No Equipment + At Home Workout // Sami Clarke #FitAtHome",
          description:
            "10 MIN Sexy Abs Floor Routine // No Equipment + At Home Workout // Sami Clarke #WithMe Hi Friends! I want to make things a little fun by keeping you guys on ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/cfuePZeBFgY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/cfuePZeBFgY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/cfuePZeBFgY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Sami Clarke",
          liveBroadcastContent: "none",
          publishTime: "2020-05-04T10:00:20Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Yuw-myLjlxxmgCa-oqpI9GR04Ic",
        id: {
          kind: "youtube#video",
          videoId: "SedOjUJgDsg",
        },
        snippet: {
          publishedAt: "2016-03-16T21:08:29Z",
          channelId: "UCKik8uG08NYJStvTW7ZgUAQ",
          title: "Bowflex® Bodyweight Workout | Four-Minute Ab Workout",
          description:
            "Check out more Bowflex workouts here: https://goo.gl/jkjFMm Subscribe for more workouts and tips: http://goo.gl/sxLYmP Tone up your abs anytime with this ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/SedOjUJgDsg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/SedOjUJgDsg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/SedOjUJgDsg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bowflex",
          liveBroadcastContent: "none",
          publishTime: "2016-03-16T21:08:29Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "0AqEcUW7_3jTO7P8j_0doMbJMO4",
        id: {
          kind: "youtube#video",
          videoId: "Qia2ZXEzyLw",
        },
        snippet: {
          publishedAt: "2019-09-09T22:29:34Z",
          channelId: "UCOpsZxrmeDARilha1uq4slA",
          title: "10 Minute Standing ABS WORKOUT // No Equipment",
          description:
            "FREE 12 Week Workout Plan: https://www.heatherrobertson.com No sitting or laying down in this killer Standing Abs Workout. We will target that core and set ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Qia2ZXEzyLw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Qia2ZXEzyLw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Qia2ZXEzyLw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Heather Robertson",
          liveBroadcastContent: "none",
          publishTime: "2019-09-09T22:29:34Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "GH3Nh1OgQQgCchVXly1tkL5CmQE",
        id: {
          kind: "youtube#video",
          videoId: "nXxsfMyz7So",
        },
        snippet: {
          publishedAt: "2017-05-31T18:49:55Z",
          channelId: "UCKik8uG08NYJStvTW7ZgUAQ",
          title:
            "Bowflex® Bodyweight Workout | Eight-Minute At-Home Ab Workout",
          description:
            "Check out more Bowflex workouts here: https://goo.gl/jkjFMm Four moves and eight minutes is all you need to complete this awesome ab workout! These four ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/nXxsfMyz7So/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/nXxsfMyz7So/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/nXxsfMyz7So/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bowflex",
          liveBroadcastContent: "none",
          publishTime: "2017-05-31T18:49:55Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "CBFBhHynKYM9k1o4UmPTW7MbF6M",
        id: {
          kind: "youtube#video",
          videoId: "9DqQ5GfZth4",
        },
        snippet: {
          publishedAt: "2020-10-11T16:52:31Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "6 Pack Abs Workout | Just 6 Minutes!! (FOLLOW ALONG)",
          description:
            "If you want to get abs then this 6 pack abs workout is the perfect place to start. You can follow along with this home abs workout without needing any equipment ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/9DqQ5GfZth4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/9DqQ5GfZth4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/9DqQ5GfZth4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2020-10-11T16:52:31Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "uctrOpv64BgbHWoTV353cBKjjbg",
        id: {
          kind: "youtube#video",
          videoId: "Jnkz6q39Dlw",
        },
        snippet: {
          publishedAt: "2019-12-04T11:27:36Z",
          channelId: "UCSld2X4CREfM9J3f485kEgQ",
          title: "5 Minute LOWER ABS Workout 👙💕 LOSE LOWER BELLY FAT",
          description:
            "This workout has all THE BEST exercises for the LOWER ABS! It will help to create a flat stomach and burn belly fat. Get MORE belly fat burning, ab workouts ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Jnkz6q39Dlw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Jnkz6q39Dlw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Jnkz6q39Dlw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bailey Brown",
          liveBroadcastContent: "none",
          publishTime: "2019-12-04T11:27:36Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "QY6n8D1twuiO7O8XPalsmSzRBaw",
        id: {
          kind: "youtube#video",
          videoId: "UBnfm4s7CRA",
        },
        snippet: {
          publishedAt: "2019-01-03T11:48:26Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title:
            "10 Mins ABS Workout To Get FLAT BELLY IN 30 DAYS | FREE WORKOUT PROGRAM",
          description:
            "This is a 10 mins intense abs workout that will help you get that flat belly and toned abs. This video is part of my 30 day FREE flat belly challenge program.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/UBnfm4s7CRA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/UBnfm4s7CRA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/UBnfm4s7CRA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2019-01-03T11:48:26Z",
        },
      },
    ],
  },
];
