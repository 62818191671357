export const WholeBodyWorkouts = [
  {
    kind: "youtube#searchListResponse",
    etag: "CKHgGspl7LZKstNNhJ2my8NFSRs",
    nextPageToken: "CDIQAA",
    regionCode: "GB",
    pageInfo: {
      totalResults: 1000000,
      resultsPerPage: 50,
    },
    items: [
      {
        kind: "youtube#searchResult",
        etag: "nh801Zt1QA4zoOw0GPjOxVopqwo",
        id: {
          kind: "youtube#video",
          videoId: "UBMk30rjy0o",
        },
        snippet: {
          publishedAt: "2018-04-29T16:29:19Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title: "20 MIN FULL BODY WORKOUT // No Equipment | Pamela Reif",
          description:
            "NO EXCUSES ♥︎ a Full Body Workout that can do whenever and wherever you like. // Werbung You don't need any equipment or weights!! Targets the muscles ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/UBMk30rjy0o/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/UBMk30rjy0o/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/UBMk30rjy0o/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2018-04-29T16:29:19Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "fqfm0zObMrBx3bShkyMoLGiXjkE",
        id: {
          kind: "youtube#video",
          videoId: "Y2eOW7XYWxc",
        },
        snippet: {
          publishedAt: "2020-03-15T08:00:06Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "20 MIN FULL BODY WORKOUT - Intense Version / No Equipment I Pamela Reif",
          description:
            "An intense Full Body Workout, that you can do without equipment, wherever and whenever you like ♥︎ this is definitely more intense than my old 20min Full ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Y2eOW7XYWxc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Y2eOW7XYWxc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Y2eOW7XYWxc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-03-15T08:00:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "94XsOoXCY9eGbEZTzf5F2gGZ400",
        id: {
          kind: "youtube#video",
          videoId: "oAPCPjnU1wA",
        },
        snippet: {
          publishedAt: "2020-02-19T20:15:00Z",
          channelId: "UCK9JEqf7LBBx3tkrPx2xvbQ",
          title: "20 MINUTE FULL BODY WORKOUT(NO EQUIPMENT)",
          description:
            "At home workouts now a days are literally a lifesaver at times, especially when you do not have a gym membership or you simply cannot make it to the gym.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/oAPCPjnU1wA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/oAPCPjnU1wA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/oAPCPjnU1wA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BullyJuice",
          liveBroadcastContent: "none",
          publishTime: "2020-02-19T20:15:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "QCiswhD_gjpHh-3dBeJ9OYUdpiI",
        id: {
          kind: "youtube#video",
          videoId: "UItWltVZZmE",
        },
        snippet: {
          publishedAt: "2019-09-01T10:12:36Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "20 MIN FULL BODY WORKOUT - Beginner Version // No Equipment I Pamela Reif",
          description:
            'Finally: a workout suitable for BEGINNERS. // Werbung What makes this "beginner friendly"? 1. I used BASIC movements, that are not too complicated or hard to ...',
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/UItWltVZZmE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/UItWltVZZmE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/UItWltVZZmE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2019-09-01T10:12:36Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "6hPq8YpfyHwWutn8n4QgUgjOVso",
        id: {
          kind: "youtube#video",
          videoId: "CGmr02bfHUo",
        },
        snippet: {
          publishedAt: "2020-01-01T12:06:27Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title:
            "Best Full Body Workout to Lose Fat 💪🏽20 mins | 28 Day Challenge",
          description:
            "Start your New Year's Resolutions with my 28 Day Flat Tummy Challenge! 6 episodes this month including a full body workout, a morning hiit cardio, abs ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/CGmr02bfHUo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/CGmr02bfHUo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/CGmr02bfHUo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2020-01-01T12:06:27Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "f1g80JDVNeq5MjLGHkmgs0zxstM",
        id: {
          kind: "youtube#video",
          videoId: "Og_f0_QO_Ko",
        },
        snippet: {
          publishedAt: "2020-09-29T13:08:16Z",
          channelId: "UCAxW1XT0iEJo0TYlRfn6rYQ",
          title:
            "20 Minute Full Body Workout - No Equipment Needed | The Body Coach TV",
          description: "",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Og_f0_QO_Ko/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Og_f0_QO_Ko/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Og_f0_QO_Ko/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "The Body Coach TV",
          liveBroadcastContent: "none",
          publishTime: "2020-09-29T13:08:16Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "CGbOd1yk9ePIUXY9YFsq5C2473Y",
        id: {
          kind: "youtube#video",
          videoId: "NQmnJtB1gzo",
        },
        snippet: {
          publishedAt: "2019-01-02T11:29:45Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title:
            "30 Min Full Body Workout to BURN FAT &amp; GET ABS + Toned Legs | FREE WORKOUT PROGRAM",
          description:
            "This is a 30 min full body workout that includes warm ups and stretches. This video is part of my 30 day flat belly challenge program. If you want to get that flat ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/NQmnJtB1gzo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/NQmnJtB1gzo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/NQmnJtB1gzo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2019-01-02T11:29:45Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "wEQORXwpCnFzeuskrJvRY8c-ACs",
        id: {
          kind: "youtube#video",
          videoId: "6VFLKdfxA24",
        },
        snippet: {
          publishedAt: "2020-01-05T08:00:00Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title: "10 MIN FULL BODY WORKOUT // No Equipment | Pamela Reif",
          description:
            "Let's kickstart this year together / Werbung!! I trained more than 3x a week for 97% of 2019! That means I managed to workout regularly during 49 out of the 52 ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/6VFLKdfxA24/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/6VFLKdfxA24/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/6VFLKdfxA24/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-01-05T08:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "nE9p4rWl12QNv4vOGq5mHccfqK0",
        id: {
          kind: "youtube#video",
          videoId: "R6gZoAzAhCg",
        },
        snippet: {
          publishedAt: "2019-07-28T15:58:53Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "The PERFECT Total Body Workout (Sets and Reps Included)",
          description:
            "Constructing a single perfect total body workout is a challenge when you consider all of the muscle groups that you will need to hit in that workout. That said, I'm ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/R6gZoAzAhCg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/R6gZoAzAhCg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/R6gZoAzAhCg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2019-07-28T15:58:53Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "-daJh03UxEMpBlTK8VK2ohqdrZw",
        id: {
          kind: "youtube#video",
          videoId: "QNtCo20dD6w",
        },
        snippet: {
          publishedAt: "2018-12-23T19:15:49Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "15 min FULL BODY Workout (No Equipment)",
          description:
            "An intense full body workout to get you sweating and your muscles burning in only 15 MINUTES with NO equipment! SUBSCRIBE TO MY MAIN CHANNEL ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/QNtCo20dD6w/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/QNtCo20dD6w/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/QNtCo20dD6w/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2018-12-23T19:15:49Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "MYkl5Pkxo7hRX2jLx6eXhnLbjtY",
        id: {
          kind: "youtube#video",
          videoId: "ge1ALhE-Fqs",
        },
        snippet: {
          publishedAt: "2020-06-24T12:36:51Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title:
            "Full Body Workout - QUICK &amp; EFFECTIVE (No Equipment) | 15 Day Challenge",
          description:
            "Back with another intense full body workout featuring a brand new filming set! I thought it was time to also refresh the timers so do leave me a comment down ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ge1ALhE-Fqs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ge1ALhE-Fqs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ge1ALhE-Fqs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2020-06-24T12:36:51Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "gOsijMYjOzCyjhY-EnMEFqNwr64",
        id: {
          kind: "youtube#video",
          videoId: "ia0wKz66X9U",
        },
        snippet: {
          publishedAt: "2018-05-04T17:46:07Z",
          channelId: "UCDUlDJcPPOOQK-3UrxEyhAQ",
          title: "Full Body Workout Routine [Fat Burning Workout At Home]",
          description:
            "Calories burned for this workout: between 92 and 118. This Full Body workout routine is designed to burn fat as effectively as possible, at home. By doing these ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ia0wKz66X9U/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ia0wKz66X9U/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ia0wKz66X9U/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Roberta's Gym",
          liveBroadcastContent: "none",
          publishTime: "2018-05-04T17:46:07Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "5IKYLqO12ywRRzuWkCGttFIBORo",
        id: {
          kind: "youtube#video",
          videoId: "Wgm1Xc25imM",
        },
        snippet: {
          publishedAt: "2020-11-22T12:22:08Z",
          channelId: "UCvGEK5_U-kLgO6-AMDPeTUQ",
          title:
            "45 min Full Body Workout to BURN MAX CALORIES (Results in 2 Weeks) ~ Emi",
          description:
            "this video is in collaboration with Skillshare the first 1000 people to use this link will get a free trial of Skillshare Premium Membership: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Wgm1Xc25imM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Wgm1Xc25imM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Wgm1Xc25imM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "emi wong",
          liveBroadcastContent: "none",
          publishTime: "2020-11-22T12:22:08Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "4I4EJRgMqr7J8qMUez47YqDan28",
        id: {
          kind: "youtube#video",
          videoId: "Auo8veVyRIY",
        },
        snippet: {
          publishedAt: "2020-04-20T16:15:00Z",
          channelId: "UCK9JEqf7LBBx3tkrPx2xvbQ",
          title: "30 MINUTE FULL BODY WORKOUT(NO EQUIPMENT)",
          description: "WWW.GYMOCLOCKFITNESS.COM ———————————————",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Auo8veVyRIY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Auo8veVyRIY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Auo8veVyRIY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BullyJuice",
          liveBroadcastContent: "none",
          publishTime: "2020-04-20T16:15:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Y_5IVbnzKIK9No5cDKnUfvymbnM",
        id: {
          kind: "youtube#video",
          videoId: "-YpRYNREDV8",
        },
        snippet: {
          publishedAt: "2020-10-08T14:00:12Z",
          channelId: "UCsLF0qPTpkYKq81HsjgzhwQ",
          title:
            "No Equipment INTENSE FULL BODY CRUSHER - HIIT WORKOUT I no repeat I stronger together",
          description:
            "So excited to share this Full Body Crusher Workout with you today. 30 Minute Bodyweight Only Workout (No Equipment needed) - No Repeat - Stronger ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/-YpRYNREDV8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/-YpRYNREDV8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/-YpRYNREDV8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "growingannanas",
          liveBroadcastContent: "none",
          publishTime: "2020-10-08T14:00:12Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "d2CSojIoYRinDCR7-XeC_h4Dqw4",
        id: {
          kind: "youtube#video",
          videoId: "5JmWguyvu7Y",
        },
        snippet: {
          publishedAt: "2019-01-27T16:53:44Z",
          channelId: "UCERm5yFZ1SptUEU4wZ2vJvw",
          title:
            "The Best Science-Based Full Body Workout for Growth (WORKOUT “A”)",
          description:
            "Full body workouts are one of the best routines for muscle growth and strength regardless of your training experience. A total body workout 3x/week not only ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/5JmWguyvu7Y/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/5JmWguyvu7Y/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/5JmWguyvu7Y/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeremy Ethier",
          liveBroadcastContent: "none",
          publishTime: "2019-01-27T16:53:44Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Xs7hdP8VBsPPc0s4w7mmPsGXfqc",
        id: {
          kind: "youtube#video",
          videoId: "IFQmOZqvtWg",
        },
        snippet: {
          publishedAt: "2020-03-29T16:00:10Z",
          channelId: "UCBINFWq52ShSgUFEoynfSwg",
          title: "30-Minute No-Equipment Full-Body Toning Workout",
          description:
            "Fitness and dance expert Nicole Steen makes sure you hit every muscle in your body in this 30-minute no-equipment workout. Nicole alternates between toning ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/IFQmOZqvtWg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/IFQmOZqvtWg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/IFQmOZqvtWg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "POPSUGAR Fitness",
          liveBroadcastContent: "none",
          publishTime: "2020-03-29T16:00:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "lCU5d8E-vkqAypCQTCVfwvpmmAw",
        id: {
          kind: "youtube#video",
          videoId: "nxisr1AalNc",
        },
        snippet: {
          publishedAt: "2020-01-18T18:08:21Z",
          channelId: "UC68TLK0mAEzUyHx5x5k-S1Q",
          title:
            "The Most Effective FULL BODY Workout For Growth [Science Applied 1]",
          description:
            "Get my new Full Body 5x Training Program ‣ https://www.jeffnippard.com/product/high-frequency-full-body-program/ If you've only been in the gym for a year or ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/nxisr1AalNc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/nxisr1AalNc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/nxisr1AalNc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeff Nippard",
          liveBroadcastContent: "none",
          publishTime: "2020-01-18T18:08:21Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "_6ugEFALEzC2cQjRsd2oCuCfNNU",
        id: {
          kind: "youtube#video",
          videoId: "uyFjMupI5B0",
        },
        snippet: {
          publishedAt: "2018-05-25T16:53:06Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "20 MIN FULL BODY WORKOUT | At Home &amp; Equipment Free!",
          description:
            "A full body workout to get you sweating and muscles burning in only 20 MINUTES! ⭐️ GRAB MY COOKBOOK! 100+ RECIPES: https://goo.gl/yYNtBL ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/uyFjMupI5B0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/uyFjMupI5B0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/uyFjMupI5B0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2018-05-25T16:53:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "nkAfovxiHA7eCuKXglbQR3cytL0",
        id: {
          kind: "youtube#video",
          videoId: "CBsaKAPOx-I",
        },
        snippet: {
          publishedAt: "2020-01-12T18:00:03Z",
          channelId: "UCoSxzVDrViaWSTBa5DJDfVg",
          title:
            "FULL BODY WORKOUT YOU SHOULD BE DOING | Full Routine &amp; Top Tips",
          description:
            "DUMBBELL EBOOK AND CROSSLIFTR Training (Conventional or Crossfit Box) Program: https://obivincent.com Follow me on INSTAGRAM: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/CBsaKAPOx-I/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/CBsaKAPOx-I/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/CBsaKAPOx-I/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Obi Vincent",
          liveBroadcastContent: "none",
          publishTime: "2020-01-12T18:00:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "wX4RiKQgInxTu1QEkgqgIf2CBcs",
        id: {
          kind: "youtube#video",
          videoId: "1skBf6h2ksI",
        },
        snippet: {
          publishedAt: "2019-11-24T08:00:00Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "15 MIN FULL BODY HIIT WORKOUT - burn lots of calories / No Equipment I Pamela Reif",
          description:
            "wow - this one is NEXT LEVEL intense :D // Werbung You will definitely sweat and most likely struggle during those 15 minutes .. but that's alright. Just don't quit.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/1skBf6h2ksI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/1skBf6h2ksI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/1skBf6h2ksI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2019-11-24T08:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Ayi1of9tp1JiWpUm8Fx36kOAfAg",
        id: {
          kind: "youtube#video",
          videoId: "Fq3PmxITeeY",
        },
        snippet: {
          publishedAt: "2020-05-13T11:56:15Z",
          channelId: "UCTZYqASosURuJdm2ZKiOJfw",
          title:
            "NO REPEAT 45 Min FULL BODY Workout//  No Equipment/ //Warm up + Cool down/ Cardio and Strength",
          description:
            "Join me on Patreon to get more workouts, recipes and more! https://www.patreon.com/healthyfitwithty No weights necessary for this 45 min NO REPEAT FULL ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Fq3PmxITeeY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Fq3PmxITeeY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Fq3PmxITeeY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Healthy Fit with Ty",
          liveBroadcastContent: "none",
          publishTime: "2020-05-13T11:56:15Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "GFr4suTLdjHNr7591cR8btysllo",
        id: {
          kind: "youtube#video",
          videoId: "Sc-2yZaKsBU",
        },
        snippet: {
          publishedAt: "2020-09-07T12:30:01Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title: "Full Body Workout | No Equipment At Home &amp; Effective",
          description:
            "Brand new 25 mins full body burn hiit workout! No jumping alternatives included, and no equipment needed! Leave a comment with how you went with this ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Sc-2yZaKsBU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Sc-2yZaKsBU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Sc-2yZaKsBU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2020-09-07T12:30:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "RmCrwQxkxuZl9btcbrS7Si9yCdA",
        id: {
          kind: "youtube#video",
          videoId: "s5GeCXnfSLE",
        },
        snippet: {
          publishedAt: "2020-01-16T12:15:35Z",
          channelId: "UCDUlDJcPPOOQK-3UrxEyhAQ",
          title: "Full Body 30 Minute Workout: Lose Weight, Tone Muscles",
          description:
            "If you really want good weight loss results and want to workout on a regular basis, then this is the optimal workout you should do! This workout will surely work ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/s5GeCXnfSLE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/s5GeCXnfSLE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/s5GeCXnfSLE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Roberta's Gym",
          liveBroadcastContent: "none",
          publishTime: "2020-01-16T12:15:35Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "LZe4WF54hDD-7lwMLbSS2luZFak",
        id: {
          kind: "youtube#video",
          videoId: "6hgBossMfOs",
        },
        snippet: {
          publishedAt: "2016-01-30T16:47:18Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "Ultimate Full-Body Workout | Mike Vazquez",
          description:
            "Performix athlete Mike Vazquez takes calisthenics training to a whole new level. Check out his inspiring story, and try his full-body workout! ▻ Get the Full Sets ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/6hgBossMfOs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/6hgBossMfOs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/6hgBossMfOs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2016-01-30T16:47:18Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "kg8cwRdLWUrf61r_3pa75ucZg5U",
        id: {
          kind: "youtube#video",
          videoId: "OBSUUi0FAKo",
        },
        snippet: {
          publishedAt: "2020-05-06T12:32:59Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title:
            "Get Shredded 🔥 12 Min Full Body HIIT Workout | Summer Shred Challenge",
          description:
            "It's the new 2020 Summer Shred! We're kicking things off with a 12 minutes full body HIIT that's going to get you started to burn those calories away. Do drop a ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/OBSUUi0FAKo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/OBSUUi0FAKo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/OBSUUi0FAKo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2020-05-06T12:32:59Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "HVLLp4JLdYg120DWBAHtVhSsMrg",
        id: {
          kind: "youtube#video",
          videoId: "VUuL6OAQJCE",
        },
        snippet: {
          publishedAt: "2020-10-12T12:00:06Z",
          channelId: "UCL0-zfZcvpeagrmUcvt3F3A",
          title:
            "30-Minute Apartment-Friendly INTENSE FULL BODY Workout (No Noise, No Jumping!)",
          description:
            "Sign up for my free Get Fit in 30 Days workout program: https://www.rachelgulottafitness.com/. In this 30-minute full-body workout, we'll cycle through exercises ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/VUuL6OAQJCE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/VUuL6OAQJCE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/VUuL6OAQJCE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Rachel Gulotta Fitness",
          liveBroadcastContent: "none",
          publishTime: "2020-10-12T12:00:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "qc9DrZioG0_eGiRV0d9mGfa4KnY",
        id: {
          kind: "youtube#video",
          videoId: "GViX8riaHX4",
        },
        snippet: {
          publishedAt: "2020-08-03T12:21:05Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title: "30 Min FULL BODY DUMBBELL WORKOUT at Home | Muscle Building",
          description:
            "This is a follow along 30 minute full body dumbbell workout that will target every muscle group with compound movement to help you become stronger, build ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/GViX8riaHX4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/GViX8riaHX4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/GViX8riaHX4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-08-03T12:21:05Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "C2Vh-xR9b8iGxk4TpBuXuf79dfI",
        id: {
          kind: "youtube#video",
          videoId: "9ubd2Pbkp2Y",
        },
        snippet: {
          publishedAt: "2019-07-11T14:18:04Z",
          channelId: "UCK5PP5Up6Dz80dv5G4XuiiA",
          title: "Full Body Toning Workout (15 Minutes)",
          description:
            "Do you need a full-body workout to tone your entire body? Then this is the PERFECT video for you. In this routine, we will be focusing on arms, abs, legs and butt ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/9ubd2Pbkp2Y/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/9ubd2Pbkp2Y/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/9ubd2Pbkp2Y/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Holly Dolke",
          liveBroadcastContent: "none",
          publishTime: "2019-07-11T14:18:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "l32TEMvgAFFDlsp2zPlLVU50Xo0",
        id: {
          kind: "youtube#video",
          videoId: "OpRBbifg6Hw",
        },
        snippet: {
          publishedAt: "2020-07-29T20:12:58Z",
          channelId: "UCb41hIvO1CE92dX1ZB1SFZw",
          title: "MY FULL BODY WORKOUT ROUTINE. MUST WATCH!",
          description:
            "SHOP NOW CLOTHING AND SUPPLEMENTS: http://www.Jaycutler.com E BOOK: https://www.jaycutler.club FREE NEWSLETTER: https://jaymail.jaycutler.club ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/OpRBbifg6Hw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/OpRBbifg6Hw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/OpRBbifg6Hw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "JayCutlerTV",
          liveBroadcastContent: "none",
          publishTime: "2020-07-29T20:12:58Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Y2IGMa419oOc1-l3vLmxRbEkb_0",
        id: {
          kind: "youtube#video",
          videoId: "cuHwoCWFLIw",
        },
        snippet: {
          publishedAt: "2019-08-17T22:42:51Z",
          channelId: "UCOpsZxrmeDARilha1uq4slA",
          title: "NO REPEAT WORKOUT // Full body HIIT Workout with Weights",
          description:
            "FREE 12 Week Workout Plan: www.heatherrobertson.com 30 exercises in 30 minutes in this No Repeat workout. We are targeting the full body with today's HIIT ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/cuHwoCWFLIw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/cuHwoCWFLIw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/cuHwoCWFLIw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Heather Robertson",
          liveBroadcastContent: "none",
          publishTime: "2019-08-17T22:42:51Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "sp_muaFE9zV9GusUAqveG6UrpHI",
        id: {
          kind: "youtube#video",
          videoId: "CD6BCdFHogg",
        },
        snippet: {
          publishedAt: "2019-09-30T11:26:10Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title:
            "15 Mins Full Body FAT BURN Workout | No Jumping | Beginner Friendly",
          description:
            "This is episode 3 of the Lean Arms Challenge! This workout is great for burning more calories, and you can also add this workout routine to your existing workout ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/CD6BCdFHogg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/CD6BCdFHogg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/CD6BCdFHogg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2019-09-30T11:26:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "jky2qa0Ch9Zmb2Xfda64RFUq0aQ",
        id: {
          kind: "youtube#video",
          videoId: "uFXrGw50QgA",
        },
        snippet: {
          publishedAt: "2020-12-13T14:28:54Z",
          channelId: "UCMyVy_Q6xXwKFX4UleHk-vg",
          title:
            "Full Body HOME Bodyweight  WORKOUT (squats, chest ,triceps, biceps , back, shoulder )",
          description:
            "Best Bodyweight Exercises Ever (squats , chest ,triceps , biceps , back , shoulder ) #Maniac_Muscle bodyweight workout Full Body at Home bodyweight workout, ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/uFXrGw50QgA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/uFXrGw50QgA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/uFXrGw50QgA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "مهووس عضلات /كمال الأجسام",
          liveBroadcastContent: "none",
          publishTime: "2020-12-13T14:28:54Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "eiHZRhaNXOSY0zeGnuWqv6Wzg54",
        id: {
          kind: "youtube#video",
          videoId: "MWcJuLKB7RY",
        },
        snippet: {
          publishedAt: "2019-02-17T14:14:19Z",
          channelId: "UCERm5yFZ1SptUEU4wZ2vJvw",
          title:
            "The Best Science-Based Full Body Workout for Size and Strength (“Workout B”)",
          description:
            "Full body workouts (or “total body workouts”) are one of the best workout routines for muscle growth, size, and strength. A full body workout split enables you to ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/MWcJuLKB7RY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/MWcJuLKB7RY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/MWcJuLKB7RY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeremy Ethier",
          liveBroadcastContent: "none",
          publishTime: "2019-02-17T14:14:19Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "5uThTRigTZDjdkwyZ0qxrrymEgE",
        id: {
          kind: "youtube#video",
          videoId: "W4eKVKwf3rQ",
        },
        snippet: {
          publishedAt: "2019-10-09T12:44:04Z",
          channelId: "UCvGEK5_U-kLgO6-AMDPeTUQ",
          title:
            "30 min Full Body Fat Burn HIIT (NO JUMPING) - Ab, Core, Arm, Back, Leg, Thigh &amp; Cardio ~ Emi",
          description:
            "SUBSCRIBE TO JOIN THE #FEMILY 訂閱我的頻道: http://bit.ly/SubscribeToEmi ▻TURN ON NOTIFICATION BUTTON FOR THE NEWEST VIDEOS! ▻STALK ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/W4eKVKwf3rQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/W4eKVKwf3rQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/W4eKVKwf3rQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "emi wong",
          liveBroadcastContent: "none",
          publishTime: "2019-10-09T12:44:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "AslFOsEfqGfcdQekMjg8V9daws8",
        id: {
          kind: "youtube#video",
          videoId: "f8GzCmbz6YY",
        },
        snippet: {
          publishedAt: "2018-09-20T04:38:03Z",
          channelId: "UCOpsZxrmeDARilha1uq4slA",
          title: "Intense 30 Minute Full Body HIIT // No Equipment Workout",
          description:
            "FREE 12 Week Workout Plan: https://www.heatherrobertson.com This intense 30 minute full body HIIT workout will keep you on your toes with 28 different ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/f8GzCmbz6YY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/f8GzCmbz6YY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/f8GzCmbz6YY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Heather Robertson",
          liveBroadcastContent: "none",
          publishTime: "2018-09-20T04:38:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "CnP5oExlGhHlgg8SWM_Y-NSgEDk",
        id: {
          kind: "youtube#video",
          videoId: "6ZJPJpOD8KY",
        },
        snippet: {
          publishedAt: "2020-04-04T15:18:27Z",
          channelId: "UCjfG0dyMUiqKleUnkX6zBrA",
          title: "20 MINUTE FULL BODY HOME WORKOUT! No Equipment, No Noise!",
          description:
            "A brand new no equipment, no noise 20 minute home workout you can do anywhere and follow-along easily My new HOME. programme with 50 unique ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/6ZJPJpOD8KY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/6ZJPJpOD8KY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/6ZJPJpOD8KY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Natacha Océane",
          liveBroadcastContent: "none",
          publishTime: "2020-04-04T15:18:27Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "GwziqpaVGH9res5kGSOA90AMfk0",
        id: {
          kind: "youtube#video",
          videoId: "ESPWlnCIoaQ",
        },
        snippet: {
          publishedAt: "2020-07-12T12:08:23Z",
          channelId: "UCvGEK5_U-kLgO6-AMDPeTUQ",
          title:
            "BEST 30 MIN FULL BODY FAT BURN WORKOUT (with No Jumping Options) #EmiTransform",
          description:
            "join the #femily membership! http://bit.ly/femilymembers ☺SUBSCRIBE 訂閱: http://bit.ly/SubscribeToEmi #EmiTransform ▻ turn on notifications for new videos ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ESPWlnCIoaQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ESPWlnCIoaQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ESPWlnCIoaQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "emi wong",
          liveBroadcastContent: "none",
          publishTime: "2020-07-12T12:08:23Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "EOQywukG-p-KUtKh7xxyb4kS3aM",
        id: {
          kind: "youtube#video",
          videoId: "Zt2sAn9-3WM",
        },
        snippet: {
          publishedAt: "2020-10-07T17:47:47Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "30 min FULL BODY BURN HIIT WORKOUT (No Equipment At Home)",
          description:
            "Try this intense, 30 min full body fat burning workout #WithMe at home! NO equipment needed, just yourself and a bit of space! Perfect for all fitness levels from ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Zt2sAn9-3WM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Zt2sAn9-3WM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Zt2sAn9-3WM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-10-07T17:47:47Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "q9Mg6dkErfM539YR5NZpNSg5-gU",
        id: {
          kind: "youtube#video",
          videoId: "H2U3HwAyBXg",
        },
        snippet: {
          publishedAt: "2020-08-13T13:47:17Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "20 min FULL BODY Workout for TOTAL BEGINNERS (No Equipment)",
          description:
            "a 20 min fat burning, full body workout you can do at home without any equipment! A workout designed for TOTAL BEGINNERS! Wether you are just getting into ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/H2U3HwAyBXg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/H2U3HwAyBXg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/H2U3HwAyBXg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-08-13T13:47:17Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "jxyt2KC4AM9gXKYKRFw_XgVC5Xg",
        id: {
          kind: "youtube#video",
          videoId: "Tz9d7By2ytQ",
        },
        snippet: {
          publishedAt: "2020-01-16T02:43:30Z",
          channelId: "UCq7bR6RxqqOx8cptc1-0AVQ",
          title:
            "NO GYM FULL BODY WORKOUT (feat. 5 min Tabata) | 5분 전신 타바타 운동",
          description:
            "Hello, Allbengers~! This is Louis. Today's workout is FULL BODY WORKOUT for your fat loss. You can do the routine without gym and equipment. So, don't ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Tz9d7By2ytQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Tz9d7By2ytQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Tz9d7By2ytQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Allblanc TV",
          liveBroadcastContent: "none",
          publishTime: "2020-01-16T02:43:30Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "aExU7wKhVSgiWo-G-6EM6i-WUbQ",
        id: {
          kind: "youtube#video",
          videoId: "iLJ2o2aHfT8",
        },
        snippet: {
          publishedAt: "2020-01-31T06:15:02Z",
          channelId: "UCEQh2aDyPJb35GeuSYCx0Ew",
          title: "15-Min Whole Body Workout AT HOME",
          description:
            "WEIGHT LOSS WORKOUT || 15 Min Whole Body Workout at Home whole body workout FOR DAILY UPDATES FOLLOW ME ON FACEBOOK AND INSTAGRAM ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/iLJ2o2aHfT8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/iLJ2o2aHfT8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/iLJ2o2aHfT8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mukti Gautam",
          liveBroadcastContent: "none",
          publishTime: "2020-01-31T06:15:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ONUim7EBSgV-zm9UnYtgVXUcTUM",
        id: {
          kind: "youtube#video",
          videoId: "AzV3EA-1-yM",
        },
        snippet: {
          publishedAt: "2020-09-18T13:30:00Z",
          channelId: "UCYC6Vcczj8v-Y5OgpEJTFBw",
          title:
            "20 Min Full Body Workout Routine for Beginners (Follow Along) | No Gym",
          description:
            "20 Minutes Full Body Daily Workout Routine for men and women that you can do at home. Buy Mamaearth's Hair Masks - https://bit.ly/3j0tNFM (Apply discount ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/AzV3EA-1-yM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/AzV3EA-1-yM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/AzV3EA-1-yM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fit Tuber",
          liveBroadcastContent: "none",
          publishTime: "2020-09-18T13:30:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "5mQMSrUWxCZvj5P1y2fIID0GdZE",
        id: {
          kind: "youtube#video",
          videoId: "cOg1cPWYt-w",
        },
        snippet: {
          publishedAt: "2020-04-12T14:00:29Z",
          channelId: "UCOpsZxrmeDARilha1uq4slA",
          title: "Full Body STRENGTH // At Home Workout",
          description:
            "All you need is a couple of dumbbells for today's full body strength workout! We are targeting the entire body and focusing on building nice lean muscle tone and ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/cOg1cPWYt-w/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/cOg1cPWYt-w/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/cOg1cPWYt-w/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Heather Robertson",
          liveBroadcastContent: "none",
          publishTime: "2020-04-12T14:00:29Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "WBM6QG2CKbliX6y4AmjEX7RV6z4",
        id: {
          kind: "youtube#video",
          videoId: "Zv-v64uWimY",
        },
        snippet: {
          publishedAt: "2020-02-27T01:32:32Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "20 min FULL BODY WORKOUT - No Equipment (HIIT Sculpt)",
          description:
            "Try this equipment free 20 min total body workout! Tone, sculpt, and strengthen at home! ⭐️SHOP MY COOKBOOKS!: https://goo.gl/XHwUJg DO THIS ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Zv-v64uWimY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Zv-v64uWimY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Zv-v64uWimY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-02-27T01:32:32Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "RRDauqHCDwPWMubdW8p34Ka4DY4",
        id: {
          kind: "youtube#video",
          videoId: "CS9ub99ssv0",
        },
        snippet: {
          publishedAt: "2020-05-12T21:00:03Z",
          channelId: "UC6wUGwwOIvENTvJ3cRlxARA",
          title: "Full Body Workout for 30 Days QUARANTINED",
          description:
            "Dreadlock Starter Kit: www.amazon.com/shop/guntherdagreat Barber Starter Kit: www.amazon.com/shop/guntherdagreat Curly Hair Starter Kit: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/CS9ub99ssv0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/CS9ub99ssv0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/CS9ub99ssv0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Gunther Da Great",
          liveBroadcastContent: "none",
          publishTime: "2020-05-12T21:00:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "gRKqx0g2-Db_dVDIgTDKL5HoeTo",
        id: {
          kind: "youtube#video",
          videoId: "j6UyNq_TwGA",
        },
        snippet: {
          publishedAt: "2019-11-29T18:01:44Z",
          channelId: "UC7t6QJ4u8qF8pI-vibX-BUQ",
          title: "Full Body WORKOUT at GYM - 15 Exercises for Total Body MASS!",
          description:
            "Now is as good a time as any to focus on getting your body into the best shape possible. Whether you want to build muscle or completely transform your body, ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/j6UyNq_TwGA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/j6UyNq_TwGA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/j6UyNq_TwGA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "WORKOUT",
          liveBroadcastContent: "none",
          publishTime: "2019-11-29T18:01:44Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "teP6y0IhKTwP5vDRfp1wQcsZAC0",
        id: {
          kind: "youtube#video",
          videoId: "B5Ag5NIk7FQ",
        },
        snippet: {
          publishedAt: "2019-11-05T15:02:14Z",
          channelId: "UCK5PP5Up6Dz80dv5G4XuiiA",
          title: "10 Minute Full Body Home Workout",
          description:
            "This 10-minute full body workout is effective, fast and works your entire body. We will get the heart-rate up with some cardio at the beginning, then straight into ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/B5Ag5NIk7FQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/B5Ag5NIk7FQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/B5Ag5NIk7FQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Holly Dolke",
          liveBroadcastContent: "none",
          publishTime: "2019-11-05T15:02:14Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "3pA5PorVV4lvv21eEE9n1OFOQb8",
        id: {
          kind: "youtube#video",
          videoId: "F8v9SA4Ptu4",
        },
        snippet: {
          publishedAt: "2020-05-08T16:21:27Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "15 MIN FULL BODY WORKOUT / Beginner Friendly - Let&#39;s Train Together / No Equipment I Pamela Reif",
          description:
            "A NEW Full Body Routine - Beginner Friendly ♥︎ We train abs, booty, arms, back & core strength in this new routine. Everything is down on the mat - nothing in ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/F8v9SA4Ptu4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/F8v9SA4Ptu4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/F8v9SA4Ptu4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-05-08T16:21:27Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "cvEwZN_bMAbHnZdLUgf7znEepCg",
        id: {
          kind: "youtube#video",
          videoId: "F9PXg7NeVP0",
        },
        snippet: {
          publishedAt: "2015-11-11T17:09:25Z",
          channelId: "UCA9k6Z3B9ywXmwKh-zpBwfw",
          title: "30 Minute Full Body Dumbbell Workout",
          description:
            "CHECK OUT MY FULL PROGRAMS HERE: http://bit.ly/2En0HvR GET MY NUTRITION GUIDE HERE: https://bit.ly/360sOPl FOLLOW ME ON FB, IG, AND ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/F9PXg7NeVP0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/F9PXg7NeVP0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/F9PXg7NeVP0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BodyFit By Amy",
          liveBroadcastContent: "none",
          publishTime: "2015-11-11T17:09:25Z",
        },
      },
    ],
  },
];
