import React from "react";
import { motion } from "framer-motion";

import { Html5Entities } from "html-entities";

import "./YoutubeCard.css";

function YoutubeCard(props) {
  let randomVideo = props.video;
  console.log(props);
  return (
    <motion.div className={"container-element"} onClick={() => {props.click(randomVideo)}} whileHover={{scale: 0.95}}>
      <div className={"content"}>
        <div className={"video-thumbnail-container"}>
          <img src={randomVideo.snippet.thumbnails.high.url} alt={randomVideo.snippet.title}/>
        </div>
        <div className={"video-description"}>
          <h3>{Html5Entities.decode(randomVideo.snippet.channelTitle)}</h3>
          <h1>{Html5Entities.decode(randomVideo.snippet.title)}</h1>
          <h2>{Html5Entities.decode(randomVideo.snippet.description)}<div className={"fadeaway"}></div></h2>
        </div>
      </div>
    </motion.div>
  );
}

export default YoutubeCard;
