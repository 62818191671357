export const ShoulderWorkouts = [
  {
    kind: "youtube#searchListResponse",
    etag: "uUsw8w4Uq6itmhSy-fcBlNUGlCw",
    nextPageToken: "CDIQAA",
    regionCode: "GB",
    pageInfo: {
      totalResults: 1000000,
      resultsPerPage: 50,
    },
    items: [
      {
        kind: "youtube#searchResult",
        etag: "2iejswoKuTEO0GGn4PbNIm0WNGs",
        id: {
          kind: "youtube#video",
          videoId: "WWEz2m-JCzQ",
        },
        snippet: {
          publishedAt: "2017-02-14T17:57:39Z",
          channelId: "UCzGLDaTu81nJDtWK10MniGg",
          title: "5 Shoulder Exercises You Should Be Doing",
          description:
            "Training Programmes - https://mikethurston.co.uk/ » 25% OFF BULK POWDERS® Supplements (USE CODE THURSTON) - http://bit.ly/2F2UHwP » Instagram: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/WWEz2m-JCzQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/WWEz2m-JCzQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/WWEz2m-JCzQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mike Thurston",
          liveBroadcastContent: "none",
          publishTime: "2017-02-14T17:57:39Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "rWnKAx8alYVHDAwFva7uk9ZYZKI",
        id: {
          kind: "youtube#video",
          videoId: "qWcgdlaJ-tQ",
        },
        snippet: {
          publishedAt: "2020-11-02T09:05:16Z",
          channelId: "UCj209lI3ZOmOCkK8e3qFFNQ",
          title: "The BEST Shoulder Workout (DON&#39;T SKIP THIS!)",
          description:
            "Your shoulder workouts been lacking? Throw in these giant sets and get them growing! I wanted to share some of the style of workouts I've been doing recently ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/qWcgdlaJ-tQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/qWcgdlaJ-tQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/qWcgdlaJ-tQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Ryan Humiston",
          liveBroadcastContent: "none",
          publishTime: "2020-11-02T09:05:16Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ahWTVmLMhMaTSGrOnDxSgmvosPs",
        id: {
          kind: "youtube#video",
          videoId: "jv31A4Ab4nA",
        },
        snippet: {
          publishedAt: "2018-11-04T18:04:06Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "The PERFECT Shoulder Workout (Sets and Reps Included)",
          description:
            "The perfect shoulder workout should consist of exercises for your front delts, middle delts and rear delts (not necessarily in that order!). That said, even that ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/jv31A4Ab4nA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/jv31A4Ab4nA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/jv31A4Ab4nA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2018-11-04T18:04:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "SvF6X7GscIyIBzziT_BiBo2NhbQ",
        id: {
          kind: "youtube#video",
          videoId: "XfjAI0P-Jd8",
        },
        snippet: {
          publishedAt: "2020-02-06T20:22:32Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "5 Best Shoulder Exercises You&#39;re NOT Doing!",
          description:
            "If you're looking for the best shoulder exercises outside of the overhead press and side laterals to build your delts, you've come to the right place. In this video ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/XfjAI0P-Jd8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/XfjAI0P-Jd8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/XfjAI0P-Jd8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2020-02-06T20:22:32Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "7TFSRp86cGqxi--BjFPBRbovdws",
        id: {
          kind: "youtube#video",
          videoId: "6jHsraw2NIk",
        },
        snippet: {
          publishedAt: "2020-03-30T14:04:56Z",
          channelId: "UCjdxelARHGq3-3dN_neMOAQ",
          title: "Shoulder Pain Relief Stretches – 5 Minute Real Time Routine",
          description:
            "These shoulder pain relief stretches are great for helping to relieve general shoulder pain and shoulder tightness. The shoulder stretches are done in real time, ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/6jHsraw2NIk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/6jHsraw2NIk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/6jHsraw2NIk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "AskDoctorJo",
          liveBroadcastContent: "none",
          publishTime: "2020-03-30T14:04:56Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "pffyuwOxYUr0CmHpEYWpLki_2wY",
        id: {
          kind: "youtube#video",
          videoId: "GkZGLpJVC0Y",
        },
        snippet: {
          publishedAt: "2019-07-14T13:00:02Z",
          channelId: "UCOFCwvhDoUvYcfpD7RJKQwA",
          title:
            "Full Shoulder Workout For Boulder Shoulders Dumbbells &amp; Barbells ONLY!",
          description:
            "For 3 free tips on how to build muscle fast customized for your body type and your goals, take my free body type fitness quiz - http://vshred.fit/bouldershoulders ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/GkZGLpJVC0Y/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/GkZGLpJVC0Y/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/GkZGLpJVC0Y/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "V Shred",
          liveBroadcastContent: "none",
          publishTime: "2019-07-14T13:00:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "1sPt2zqc9gy1Sl_SzhOLazrTABc",
        id: {
          kind: "youtube#video",
          videoId: "aWhEpIxqYhE",
        },
        snippet: {
          publishedAt: "2016-01-03T17:00:00Z",
          channelId: "UCoguzybAI8Fgdj4Aze5tE8w",
          title: "WORLD&#39;S BEST SHOULDER WORKOUT",
          description:
            "A J Ellison is the World's Greatest Muscle Model - and his huge, thick chest is complemented by dramatic 'boulder shoulders'. GET FREE WRITTEN ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/aWhEpIxqYhE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/aWhEpIxqYhE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/aWhEpIxqYhE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fit Media Channel",
          liveBroadcastContent: "none",
          publishTime: "2016-01-03T17:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "6Wc5YvtvU1Mmwsvd28HXRBIZ7PY",
        id: {
          kind: "youtube#video",
          videoId: "qNncOv3dJTg",
        },
        snippet: {
          publishedAt: "2019-09-17T15:22:17Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "Boulder Shoulder Workout | Regan Grimes",
          description:
            "IFBB pro and Primeval Labs athlete Regan Grimes takes you through his personal shoulder workout to help you develop strong, capped delts. ▻ Shop Primeval ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/qNncOv3dJTg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/qNncOv3dJTg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/qNncOv3dJTg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2019-09-17T15:22:17Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "29UwB3ysdi3TCrAX1oZGSZYYeGw",
        id: {
          kind: "youtube#video",
          videoId: "g5oQZmk7xMc",
        },
        snippet: {
          publishedAt: "2020-08-13T18:00:10Z",
          channelId: "UCaBqRxHEMomgFU-AkSfodCw",
          title: "Perfect Home Shoulder Workout (Dumbbells Only)",
          description:
            "Join Chris Heria as he shows you a Perfect Home Shoulder Workout that you can do anywhere all you need is a pair of dumbbells. Follow along as he goes ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/g5oQZmk7xMc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/g5oQZmk7xMc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/g5oQZmk7xMc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "CHRIS HERIA",
          liveBroadcastContent: "none",
          publishTime: "2020-08-13T18:00:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "VzitfdCOgouw7ZK2eZOfsRSwLvg",
        id: {
          kind: "youtube#video",
          videoId: "PUPiRVDTAEk",
        },
        snippet: {
          publishedAt: "2019-09-03T13:00:05Z",
          channelId: "UCOFCwvhDoUvYcfpD7RJKQwA",
          title: "12 Best Shoulder Exercises for Boulder Shoulders",
          description:
            "Order Post Workout by Sculpt Nation and save 20% using code 20-workout at checkout - http://vshred.fit/12bestshoulderexercises What's going on guys!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/PUPiRVDTAEk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/PUPiRVDTAEk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/PUPiRVDTAEk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "V Shred",
          liveBroadcastContent: "none",
          publishTime: "2019-09-03T13:00:05Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "YDkOZMH465zlzQDwrdSxNu7i4RQ",
        id: {
          kind: "youtube#video",
          videoId: "eyhAIp90FPo",
        },
        snippet: {
          publishedAt: "2017-11-20T23:55:45Z",
          channelId: "UCjP1qqdUt2rRUM3r_4-4MMg",
          title:
            "Shoulder Workout - Physical Therapy Exercises for Shoulder Pain",
          description:
            "SHOULDER WORKOUT - PHYSICAL THERAPY EXERCISES FOR SHOULDER PAIN ⭐️ This shoulder workout is perfect for anyone experiencing shoulder ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/eyhAIp90FPo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/eyhAIp90FPo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/eyhAIp90FPo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jessica Valant Pilates",
          liveBroadcastContent: "none",
          publishTime: "2017-11-20T23:55:45Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "8CQ04q_40gW33fPI0AC4ktKqVyA",
        id: {
          kind: "youtube#video",
          videoId: "wH-YPotqDlg",
        },
        snippet: {
          publishedAt: "2020-08-12T16:32:19Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title: "20 Min INTENSE DUMBBELL SHOULDER WORKOUT at Home | Supersets",
          description:
            "Shoulder supersets! 20 minutes shoulder workout at home using only a pair of dumbbells and a mat! We will hit shoulders at all angles, lifting and lowering with ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/wH-YPotqDlg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/wH-YPotqDlg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/wH-YPotqDlg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-08-12T16:32:19Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "5IgLcW5IdHfUYdTAboahtyrvnd0",
        id: {
          kind: "youtube#video",
          videoId: "7RIHGvPd6g0",
        },
        snippet: {
          publishedAt: "2020-04-30T19:17:45Z",
          channelId: "UCj209lI3ZOmOCkK8e3qFFNQ",
          title:
            "SHOULDER WORKOUT With Dumbbells + Sets/Reps (GUARANTEED GROWTH!)",
          description:
            "Get notified about the course: https://ryanhumiston.com/course/ Shoulder Workout Sets & Reps: Partial Side Laterals - 3 sets/20 reps Leaning Side Laterals - 3 ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/7RIHGvPd6g0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/7RIHGvPd6g0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/7RIHGvPd6g0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Ryan Humiston",
          liveBroadcastContent: "none",
          publishTime: "2020-04-30T19:17:45Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "T1iLhi_rPXM-Qv-8r0PriuWTdXw",
        id: {
          kind: "youtube#video",
          videoId: "mUbnnR9ClJk",
        },
        snippet: {
          publishedAt: "2020-11-25T15:30:03Z",
          channelId: "UC6vkKAsph6kZuAsC5Q8MVNQ",
          title: "8 Best Shoulder Exercises for Boulder Shoulders",
          description:
            "8 Best Shoulder Exercises for Boulder Shoulders 00:00 EZ Bar Wide Grip Upright Row 00:32 Seated Dumbbell Overhead Press 01:01 Arnold Press 01:36 ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/mUbnnR9ClJk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/mUbnnR9ClJk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/mUbnnR9ClJk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "GYM BODY MOTIVATION",
          liveBroadcastContent: "none",
          publishTime: "2020-11-25T15:30:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "NwMGiLIPnwminS91L5QxyZbE2PI",
        id: {
          kind: "youtube#video",
          videoId: "V0dYd1jyLpE",
        },
        snippet: {
          publishedAt: "2019-07-07T15:59:25Z",
          channelId: "UCERm5yFZ1SptUEU4wZ2vJvw",
          title:
            "The Best Science-Based DUMBBELL Shoulder Exercises For Mass and Symmetry",
          description:
            "Big, well-defined, and balanced looking shoulders are essential when it comes to developing a broad, powerful looking upper body. But achieving them is no ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/V0dYd1jyLpE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/V0dYd1jyLpE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/V0dYd1jyLpE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeremy Ethier",
          liveBroadcastContent: "none",
          publishTime: "2019-07-07T15:59:25Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "BPLPxCwAF277cvVoKgQzjypBX1A",
        id: {
          kind: "youtube#video",
          videoId: "2Vprklw8cu8",
        },
        snippet: {
          publishedAt: "2018-01-14T13:42:55Z",
          channelId: "UCERm5yFZ1SptUEU4wZ2vJvw",
          title:
            "The Best Science-Based Shoulder Workout for Size and Symmetry",
          description:
            "DOWNLOAD YOUR FREE SHOULDER PDF VIA THE LINK BELOW: https://builtwithscience.com/pdf-downloads/shoulder-workout/ When it comes to the “best ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/2Vprklw8cu8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/2Vprklw8cu8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/2Vprklw8cu8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeremy Ethier",
          liveBroadcastContent: "none",
          publishTime: "2018-01-14T13:42:55Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ZbfeoBEj98TX5ckYTAoUDj6Iyh0",
        id: {
          kind: "youtube#video",
          videoId: "hmnvQmUPsto",
        },
        snippet: {
          publishedAt: "2020-05-10T16:36:41Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "10 Min | Home Shoulder Workout (SETS AND REPS INCLUDED!)",
          description:
            "If you have just 10 minutes and want a home shoulder workout that will let you build big shoulders at home, then this is the video for you. Here I show you a ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/hmnvQmUPsto/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/hmnvQmUPsto/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/hmnvQmUPsto/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2020-05-10T16:36:41Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "cNanEFbSk4TTDyGFxUjkwjqmC8M",
        id: {
          kind: "youtube#video",
          videoId: "Mgkpk1pVBjE",
        },
        snippet: {
          publishedAt: "2019-03-27T15:15:18Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "High-Volume Shoulder Workout for Mass | Abel Albonetti",
          description:
            "If you're familiar with Abel's shoulder training style, you already know that he uses lots of volume, substantial weights, and fatigue-inducing dropsets to build his ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Mgkpk1pVBjE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Mgkpk1pVBjE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Mgkpk1pVBjE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2019-03-27T15:15:18Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "dmLPzHhX4BjCkTFVxSx3wDBwCdY",
        id: {
          kind: "youtube#video",
          videoId: "hh2k089qfE8",
        },
        snippet: {
          publishedAt: "2015-11-23T16:18:53Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title:
            "Calum Von Moger&#39;s Shoulder Workout for Mass | Building Von Moger",
          description:
            "Hang out in the pool with Calum, go out to lunch at the Firehouse Grill, and then hit the gym for a killer shoulder workout! ▻ Get Calum's 6-Week Mass Program: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/hh2k089qfE8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/hh2k089qfE8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/hh2k089qfE8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2015-11-23T16:18:53Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "aVx5gzSos__xUSi9mIYfzHl3u7k",
        id: {
          kind: "youtube#video",
          videoId: "NRSV8kkjKAg",
        },
        snippet: {
          publishedAt: "2017-08-18T15:22:47Z",
          channelId: "UCjdxelARHGq3-3dN_neMOAQ",
          title:
            "Shoulder Tendonitis Exercises for Pain Relief - Ask Doctor Jo",
          description:
            "Shoulder exercises are great for shoulder tendonitis pain relief. This inflammation to the tendons in your shoulder can be the rotator cuff tendons or even the ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/NRSV8kkjKAg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/NRSV8kkjKAg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/NRSV8kkjKAg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "AskDoctorJo",
          liveBroadcastContent: "none",
          publishTime: "2017-08-18T15:22:47Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "hufYibrF4IF9WW1JloGPQGC21eU",
        id: {
          kind: "youtube#video",
          videoId: "qroFxwXf1EI",
        },
        snippet: {
          publishedAt: "2019-06-28T18:15:02Z",
          channelId: "UCK9JEqf7LBBx3tkrPx2xvbQ",
          title: "5 MINUTE SHOULDER WORKOUT(NO EQUIPMENT)",
          description:
            "WWW.GYMOCLOCKFITNESS.COM ——————————————— At home 5 minute shoulder workout tutorial! Follow along!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/qroFxwXf1EI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/qroFxwXf1EI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/qroFxwXf1EI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BullyJuice",
          liveBroadcastContent: "none",
          publishTime: "2019-06-28T18:15:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "D7PH-zmPrLyAzd0ZQO2P4f56jtU",
        id: {
          kind: "youtube#video",
          videoId: "9tTrrvGByK8",
        },
        snippet: {
          publishedAt: "2018-09-02T17:06:02Z",
          channelId: "UCBeKQoyinCzc6JdS_47nHKw",
          title: "TOP 5 SHOULDER EXERCISES | HOW TO TONE YOUR SHOULDERS",
          description:
            "The workout: Download my health & fitness app 'OWNU' and start your 7-day free trial today! https://ownuapp.com/join/ 1. DB press / barbell press 4set x 10reps ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/9tTrrvGByK8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/9tTrrvGByK8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/9tTrrvGByK8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Hanna Öberg",
          liveBroadcastContent: "none",
          publishTime: "2018-09-02T17:06:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "lIBEiCMsAHnosJdcooaGNAmMhIQ",
        id: {
          kind: "youtube#video",
          videoId: "XI-kfb3lk8Q",
        },
        snippet: {
          publishedAt: "2020-03-06T15:03:43Z",
          channelId: "UC2iXohrgKb7Ga-pLRmqh3fw",
          title:
            "Best Shoulder Workout For Big Deltoids W. The GODFATHER OF BODYBUILDING",
          description:
            "SUBSCRIBE TO MY CHANNEL AND NEVER MISS GAINS! https://www.youtube.com/superhumantv CHARLES GLASS WORKOUTS FOR EVERY MUSCLE ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/XI-kfb3lk8Q/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/XI-kfb3lk8Q/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/XI-kfb3lk8Q/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Troy Adashun",
          liveBroadcastContent: "none",
          publishTime: "2020-03-06T15:03:43Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "84hPMOB4kBGXovePEArcTSn6G8g",
        id: {
          kind: "youtube#video",
          videoId: "2keb031pDGk",
        },
        snippet: {
          publishedAt: "2017-03-27T11:00:01Z",
          channelId: "UCXIJ2-RSIGn53HA-x9RDevA",
          title:
            "20 Min Shoulder Workout for Women &amp; Men at Home with Dumbbells - Deltoid Exercises for Shoulders",
          description:
            "Download the FREE HASfit app: Android http://bit.ly/HASfitAndroid -- iPhone http://bit.ly/HASfitiOS Visit ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/2keb031pDGk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/2keb031pDGk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/2keb031pDGk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "HASfit",
          liveBroadcastContent: "none",
          publishTime: "2017-03-27T11:00:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "4KFbn9uL83apmVej42A9T7UjlGY",
        id: {
          kind: "youtube#video",
          videoId: "KyTAraGimfE",
        },
        snippet: {
          publishedAt: "2017-06-07T13:00:02Z",
          channelId: "UC68TLK0mAEzUyHx5x5k-S1Q",
          title:
            "The Most Effective Way to Train Shoulders | Science Explained (12 Studies)",
          description:
            "PLEASE OPEN ME ↓ Get My SHOULDER HYPERTROPHY PROGRAM Here: ‣ http://www.strcng.com/shoulderhypertrophyprogram Watch the footnote to this ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/KyTAraGimfE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/KyTAraGimfE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/KyTAraGimfE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeff Nippard",
          liveBroadcastContent: "none",
          publishTime: "2017-06-07T13:00:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "nShZ6AGn84U6m9lbXfj7wQhPNXY",
        id: {
          kind: "youtube#video",
          videoId: "GXk1GgvhyF8",
        },
        snippet: {
          publishedAt: "2016-09-06T17:02:39Z",
          channelId: "UCQPmOWNza6PMesQaWWBEhJA",
          title: "Intense 5 Minute At Home Shoulder Workout",
          description:
            "Download My Workout App Exerprise FREE - https://bit.ly/2LNA1sB Intense 5 Minute At Home Workouts Weekly Schedule HERE ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/GXk1GgvhyF8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/GXk1GgvhyF8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/GXk1GgvhyF8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Anabolic Aliens",
          liveBroadcastContent: "none",
          publishTime: "2016-09-06T17:02:39Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "v-vBY_PVxU7Jb81oal-jh0PBHXo",
        id: {
          kind: "youtube#video",
          videoId: "dS5rusWjyzU",
        },
        snippet: {
          publishedAt: "2020-10-16T17:39:08Z",
          channelId: "UCkZuCxXdE6OUGDEbETcjhPw",
          title: "Big Shoulder Workout | Seth Feroce",
          description:
            'https://axeandsledge.com/ use code "YOUTUBE" for 10% off all supplements https://allamericanroughneck.com/ NEW PUMP COVERS will be STOCKED for ...',
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/dS5rusWjyzU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/dS5rusWjyzU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/dS5rusWjyzU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Seth Feroce",
          liveBroadcastContent: "none",
          publishTime: "2020-10-16T17:39:08Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "HtjOnADPPyal4n9spzN4ZIW2iNo",
        id: {
          kind: "youtube#video",
          videoId: "qbgs3DdmAdQ",
        },
        snippet: {
          publishedAt: "2020-02-04T01:50:06Z",
          channelId: "UC8YnxGgVT62DDZ6sX1pLKGQ",
          title:
            "Mike O&#39;Hearn BEST Old School Shoulder Workout Sets And Reps",
          description:
            "Mike O'Hearn IG: https://www.instagram.com/mikeohearn/ My website to find all the shirts and workout plans and so much more: http://www.mikeohearn.com/ ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/qbgs3DdmAdQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/qbgs3DdmAdQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/qbgs3DdmAdQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mike O'Hearn",
          liveBroadcastContent: "none",
          publishTime: "2020-02-04T01:50:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Nbqvwe3446NLEvEtsPPwnFoeumQ",
        id: {
          kind: "youtube#video",
          videoId: "xnrJCzlOpS8",
        },
        snippet: {
          publishedAt: "2020-07-17T12:30:05Z",
          channelId: "UC12K7FCbzFG5BOMJrHn-sog",
          title: "7 Best Dumbbell SHOULDER Workout | Yatinder Singh",
          description:
            "In this video, I have shared a perfectly crafted Shoulder Workout routine using only Dumbbells. You can perform it at home as well as there are no other ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/xnrJCzlOpS8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/xnrJCzlOpS8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/xnrJCzlOpS8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Yatinder Singh",
          liveBroadcastContent: "none",
          publishTime: "2020-07-17T12:30:05Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "sG079zAUaQMUfr-KtXZB5E2wUw4",
        id: {
          kind: "youtube#video",
          videoId: "nYB6akjXdbI",
        },
        snippet: {
          publishedAt: "2019-05-15T14:23:19Z",
          channelId: "UCmSEdfW3LpEKyLiCDWBDdVQ",
          title: "The Perfect Shoulder Workout For Monster Delts",
          description:
            "So you want bigger delts? Well today is your lucky day. This is the perfect shoulder workout for just that. Anyone can have monster delts if you just follow my 4 ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/nYB6akjXdbI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/nYB6akjXdbI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/nYB6akjXdbI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "mountaindog1",
          liveBroadcastContent: "none",
          publishTime: "2019-05-15T14:23:19Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "CbT0uRjR6M1Z_99NeT8xsQibGgM",
        id: {
          kind: "youtube#video",
          videoId: "aJXfalSciFg",
        },
        snippet: {
          publishedAt: "2017-11-15T15:48:01Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "Plateau-Busting Shoulder Workout for Mass | Abel Albonetti",
          description:
            "Stuck in a rut on the road to bigger and better shoulders? Abel Albonetti offers a hard-hitting, high-volume workout to break the stalemate and pump up your ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/aJXfalSciFg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/aJXfalSciFg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/aJXfalSciFg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2017-11-15T15:48:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "aE9zPk2vnSitPEc53I5_6dnjS8Y",
        id: {
          kind: "youtube#video",
          videoId: "xXsUexaQEp0",
        },
        snippet: {
          publishedAt: "2018-09-12T18:17:52Z",
          channelId: "UCjdxelARHGq3-3dN_neMOAQ",
          title:
            "7 Best Frozen Shoulder Exercises &amp; Stretches - Ask Doctor Jo",
          description:
            "Frozen shoulder, or adhesive capsulitis, is a condition that causes stiffness and pain in your shoulder joint from adhesion build up. These stretches & exercises ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/xXsUexaQEp0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/xXsUexaQEp0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/xXsUexaQEp0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "AskDoctorJo",
          liveBroadcastContent: "none",
          publishTime: "2018-09-12T18:17:52Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "KoXgB3mGt3XiCMbI5cLjv84hVvo",
        id: {
          kind: "youtube#video",
          videoId: "Ufo_wePsRvo",
        },
        snippet: {
          publishedAt: "2018-09-08T18:03:39Z",
          channelId: "UC_Ih0f1H_eyHeLLT0Tzuh8g",
          title:
            "THE MOST POWERFUL SHOULDERS ROUTINE EVER! | WITH BOUNTY TANK AND BODYTRACEFIT",
          description:
            "TRAINING PROGRAMS: https://www.simeonpanda.com JUST LIFT. CLOTHING: https://www.justlift.com SP AESTHETICS: https://www.sp-aesthetics.com ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Ufo_wePsRvo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Ufo_wePsRvo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Ufo_wePsRvo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Simeon Panda",
          liveBroadcastContent: "none",
          publishTime: "2018-09-08T18:03:39Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "zAnvLsR2HHjQ56L-nKuvJp6fZTw",
        id: {
          kind: "youtube#video",
          videoId: "pFEdK8UazKQ",
        },
        snippet: {
          publishedAt: "2019-11-15T14:04:36Z",
          channelId: "UC5_i5V3xXxdqF5VKVmJWVZQ",
          title:
            "Victor Martinez&#39;s Shoulder Workout at Muscle &amp; Strength",
          description:
            "IFBB Pro and MHP athlete, Victor Martinez, shows us how he's managed to continue to build his shoulders while training around injury prevention.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/pFEdK8UazKQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/pFEdK8UazKQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/pFEdK8UazKQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Muscle & Strength",
          liveBroadcastContent: "none",
          publishTime: "2019-11-15T14:04:36Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "PnmvKm36QhevHCSE31jyds6AOF8",
        id: {
          kind: "youtube#video",
          videoId: "ClBKkGlgMms",
        },
        snippet: {
          publishedAt: "2018-09-02T16:00:01Z",
          channelId: "UCl1Tkl0amPsxWJ1HLQdj92A",
          title: "SCULPT YOUR SHOULDERS  Full workout",
          description:
            "Hey Lovely people As you know a while back I was releasing really in-depth videos on different muscle groups. I have decided to bring that back but in a more ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ClBKkGlgMms/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ClBKkGlgMms/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ClBKkGlgMms/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Krissy Cela",
          liveBroadcastContent: "none",
          publishTime: "2018-09-02T16:00:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ikGXkT390EI9JujooznkXjRWuag",
        id: {
          kind: "youtube#video",
          videoId: "djWz9xQmiWw",
        },
        snippet: {
          publishedAt: "2020-12-27T12:54:10Z",
          channelId: "UCvGEK5_U-kLgO6-AMDPeTUQ",
          title:
            "90 DEGREE SHOULDERS WORKOUT | Fix Rounded Shoulders &amp; Hunchback in 10 min ~ Emi",
          description:
            "this video is in collaboration with https://www.zyviz.com - visualizing how AI dramatically reduces your video advertising budget ▻join the #femily membership!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/djWz9xQmiWw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/djWz9xQmiWw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/djWz9xQmiWw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "emi wong",
          liveBroadcastContent: "none",
          publishTime: "2020-12-27T12:54:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Bg1JutAtNV-vsCCScTwPdx5w5EA",
        id: {
          kind: "youtube#video",
          videoId: "t-h0T3dE8t4",
        },
        snippet: {
          publishedAt: "2018-05-30T00:00:01Z",
          channelId: "UCkZuCxXdE6OUGDEbETcjhPw",
          title: "Giant Sets &amp; Shoulder Training | Seth Feroce",
          description:
            "https://axeandsledge.com https://allamericanroughneck.com Warm Up - Get a sweat going before you start your actual training. (Side laterals, front Raises, light ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/t-h0T3dE8t4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/t-h0T3dE8t4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/t-h0T3dE8t4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Seth Feroce",
          liveBroadcastContent: "none",
          publishTime: "2018-05-30T00:00:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "7_-B3bgP_IxCJgqSThwMKIs90Ws",
        id: {
          kind: "youtube#video",
          videoId: "kGMDFFaKEGQ",
        },
        snippet: {
          publishedAt: "2019-03-25T16:58:55Z",
          channelId: "UCqV2z4XYLcOLT27AmGT0M6w",
          title:
            "Arnold Schwarzenegger&#39;s Full BEAST Shoulder Workout for Boulder Shoulders",
          description:
            "We decided to mix things up today so we gave one of Arnold Schwarzenegger's shoulder workout a try. It was KILLER! Try it out for yourself. Full Workout: 1) ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/kGMDFFaKEGQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/kGMDFFaKEGQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/kGMDFFaKEGQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Colossus Fitness",
          liveBroadcastContent: "none",
          publishTime: "2019-03-25T16:58:55Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "-Cwsh0TbRH5B65nqqZysmDPsmeU",
        id: {
          kind: "youtube#video",
          videoId: "GA2l1hopJEM",
        },
        snippet: {
          publishedAt: "2017-10-30T20:20:11Z",
          channelId: "UCCwj1xAY55Dd-H281T1g-bQ",
          title:
            "Frozen shoulder exercises - how I got relief in just two days",
          description:
            "Looking for effective frozen shoulder exercises at home? Learn shoulder stretches and exercises that helped Matt unfreeze his frozen shoulder fast! Plus learn ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/GA2l1hopJEM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/GA2l1hopJEM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/GA2l1hopJEM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Upright Health",
          liveBroadcastContent: "none",
          publishTime: "2017-10-30T20:20:11Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "MVHCuEm5esKHsGQrncWRP48W6E0",
        id: {
          kind: "youtube#video",
          videoId: "H-PuyYPwTrs",
        },
        snippet: {
          publishedAt: "2020-12-13T13:00:10Z",
          channelId: "UCgOO-SRCWE8lhYqpo2kKvFw",
          title: "Road to Olympia Ep6: Shoulder Workout Ft Hany Rambod",
          description:
            "My Instagram: www.instagram.com/andreideiu_ ➤ BULK or SHRED training & diet plans: www.3dgains.com ➤ Outfit: https://www.boohooman.com ➤ My ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/H-PuyYPwTrs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/H-PuyYPwTrs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/H-PuyYPwTrs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Andrei Deiu",
          liveBroadcastContent: "none",
          publishTime: "2020-12-13T13:00:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "FNGuEK791LmF0odEACRjIXwguOo",
        id: {
          kind: "youtube#video",
          videoId: "GE80Ri3HnVY",
        },
        snippet: {
          publishedAt: "2019-11-07T17:51:55Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "How to Get Wider Shoulders (FAST!!)",
          description:
            "If you want to build wider shoulders fast you need to narrow your shoulder exercise selection and broaden the weight selection you use when performing them.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/GE80Ri3HnVY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/GE80Ri3HnVY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/GE80Ri3HnVY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2019-11-07T17:51:55Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "DQZpR0mabnycmbBjsPuJmWPbJ8U",
        id: {
          kind: "youtube#video",
          videoId: "5E5HsulA2jo",
        },
        snippet: {
          publishedAt: "2020-03-29T04:07:25Z",
          channelId: "UCf54LUqu33cT8Okhj5kn4cA",
          title:
            "Frozen Shoulder Exercises V2 - Adhesive Capsulitis - Calcific Tendonitis - Shoulder Stiffness",
          description:
            "Frozen shoulder exercises to help lubricate and loosen your stiff shoulders. Along with these exercises, get some ESWT (extracorporeal shockwave therapy) to ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/5E5HsulA2jo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/5E5HsulA2jo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/5E5HsulA2jo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Adam Fields DC",
          liveBroadcastContent: "none",
          publishTime: "2020-03-29T04:07:25Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "sT_WK6B-H7jmQaQYatJ_oK9fwT8",
        id: {
          kind: "youtube#video",
          videoId: "Z68by50la9Q",
        },
        snippet: {
          publishedAt: "2018-12-03T22:44:45Z",
          channelId: "UCQPmOWNza6PMesQaWWBEhJA",
          title:
            "Intense 10 Minute At Home Shoulder Workout Bodyweight Finisher",
          description:
            "Long Term Fitness Success Training #3 - Free Masterclass: https://www.anabolicalien.com/alieninvasion3D2b9ck5C Download My Workout App Exerprise ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Z68by50la9Q/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Z68by50la9Q/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Z68by50la9Q/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Anabolic Aliens",
          liveBroadcastContent: "none",
          publishTime: "2018-12-03T22:44:45Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "nqwl5WFRt2rUi0lDusRgQV1xOx8",
        id: {
          kind: "youtube#video",
          videoId: "8zoncTsEpq8",
        },
        snippet: {
          publishedAt: "2018-07-30T19:01:45Z",
          channelId: "UCmSEdfW3LpEKyLiCDWBDdVQ",
          title: "Old School Shoulder Workout | For Mass",
          description:
            "We had to dig deep in the vault for this shoulder workout. We are bringing back some old school shoulder exercises. Let me know what your favorite old school ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8zoncTsEpq8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8zoncTsEpq8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8zoncTsEpq8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "mountaindog1",
          liveBroadcastContent: "none",
          publishTime: "2018-07-30T19:01:45Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "vck7Rjn2E6o5JCT5IASBS9ltXqY",
        id: {
          kind: "youtube#video",
          videoId: "cxn0l7ejTbc",
        },
        snippet: {
          publishedAt: "2019-04-11T19:23:24Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "The BEST Dumbbell Exercises - SHOULDERS EDITION!",
          description:
            "In today's video we look at the best dumbbell exercises for shoulders. We're going to focus on several areas of training: from strength, to power as well as ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/cxn0l7ejTbc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/cxn0l7ejTbc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/cxn0l7ejTbc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2019-04-11T19:23:24Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "8AqPqbvrJkDoXA-yt0OrtxPlgng",
        id: {
          kind: "youtube#video",
          videoId: "UovaWXBEJFQ",
        },
        snippet: {
          publishedAt: "2019-06-04T16:00:04Z",
          channelId: "UCkZuCxXdE6OUGDEbETcjhPw",
          title: "Total Shoulder Workout - Pushing Blood with Seth Feroce",
          description:
            "https://axeandsledge.com/ https://allamericanroughneck.com/ Pre-Workout Shake: 2 Scoops Hydraulic 1 Scoop of Demo Day Warm-Up - Get a sweat going, ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/UovaWXBEJFQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/UovaWXBEJFQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/UovaWXBEJFQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Seth Feroce",
          liveBroadcastContent: "none",
          publishTime: "2019-06-04T16:00:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "oxBZALkoRXq5Oc8uBLg9ZjWMMPg",
        id: {
          kind: "youtube#video",
          videoId: "Aq6RMo7RJ9c",
        },
        snippet: {
          publishedAt: "2019-06-06T14:02:49Z",
          channelId: "UCJASBOyF4Fkd26SIwRhLuZw",
          title:
            "How To ELIMINATE Shoulder Pain - Shoulder Impingement Exercises",
          description:
            "6 of the best physical therapy exercises to eliminate shoulder pain. Shoulder impingement is one of the most-common causes of shoulder pain that I encounter ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Aq6RMo7RJ9c/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Aq6RMo7RJ9c/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Aq6RMo7RJ9c/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Tone and Tighten",
          liveBroadcastContent: "none",
          publishTime: "2019-06-06T14:02:49Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "KK5v_rWoXFrFIU736O4LPrhUI8I",
        id: {
          kind: "youtube#video",
          videoId: "eH5VHVw28UU",
        },
        snippet: {
          publishedAt: "2016-08-25T16:05:15Z",
          channelId: "UCU3juAek2IQClkV7eUw-a2w",
          title:
            "Complete SHOULDER WORKOUT ROUTINE for EXTREME PUMP! BBRT #19 (Hindi / Punjabi)",
          description:
            "For high quality protein powders and supplements I highly recommend MY PROTEIN Click on the link below, use code iKNOWSUNNY to get 25% to 40% off!!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/eH5VHVw28UU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/eH5VHVw28UU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/eH5VHVw28UU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MY BOLLYWOOD BODY",
          liveBroadcastContent: "none",
          publishTime: "2016-08-25T16:05:15Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "dnA0iT2PhXMYbMlnTEVE6Jxe1WM",
        id: {
          kind: "youtube#video",
          videoId: "AtnbvrqKS80",
        },
        snippet: {
          publishedAt: "2020-06-27T18:00:10Z",
          channelId: "UCoguzybAI8Fgdj4Aze5tE8w",
          title: "YOUR NEW FAVOURITE SHOULDER WORKOUT",
          description:
            "Here's your new favourite shoulder workout with shredded Hungarian fitness sensation Zsolt Papp. Working all three parts of the shoulder - Rear Delt, Front Delt ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/AtnbvrqKS80/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/AtnbvrqKS80/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/AtnbvrqKS80/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fit Media Channel",
          liveBroadcastContent: "none",
          publishTime: "2020-06-27T18:00:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "6lrCx-CmA7qISTAhnsQq41aHRuQ",
        id: {
          kind: "youtube#video",
          videoId: "frrPIRs1tjA",
        },
        snippet: {
          publishedAt: "2020-08-28T15:44:24Z",
          channelId: "UCI2ByG57kHLeVbWgw1PgkmQ",
          title:
            "3 Easy Exercises For Frozen Shoulder, Frozen Shoulder Treatment, Frozen Shoulder Physiotherapy",
          description:
            "3 Best Exercises for Frozen shoulder recovery. Easy Mobility in Adhesive Capsulitis In this video Dr. Varun Wasil- MPT(Orthopaedics) from Sukoon Physical ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/frrPIRs1tjA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/frrPIRs1tjA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/frrPIRs1tjA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Sukoon physical therapy",
          liveBroadcastContent: "none",
          publishTime: "2020-08-28T15:44:24Z",
        },
      },
    ],
  },
];
