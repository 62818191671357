export const BackWorkouts = [
  {
    kind: "youtube#searchListResponse",
    etag: "03BfG2z0y6OMXUvHb6fOifBY1C0",
    nextPageToken: "CDIQAA",
    regionCode: "GB",
    pageInfo: {
      totalResults: 1000000,
      resultsPerPage: 50,
    },
    items: [
      {
        kind: "youtube#searchResult",
        etag: "lMyFI8QzEAfnZ9lJoldi5U4UgfQ",
        id: {
          kind: "youtube#video",
          videoId: "eE7dzM0iexc",
        },
        snippet: {
          publishedAt: "2019-02-17T18:16:42Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "The PERFECT Back Workout (Sets and Reps Included)",
          description:
            "The perfect back workout should consist of exercises for not just the lats and traps but other important muscles of the back as well. That said, even that doesn't ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/eE7dzM0iexc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/eE7dzM0iexc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/eE7dzM0iexc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2019-02-17T18:16:42Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ROAoNbLfIqQEwnQH8QNj6YKCXy0",
        id: {
          kind: "youtube#video",
          videoId: "QGyaUK-2Zvo",
        },
        snippet: {
          publishedAt: "2020-03-01T21:00:10Z",
          channelId: "UCoguzybAI8Fgdj4Aze5tE8w",
          title: "WORLD&#39;S BEST BACK WORKOUT",
          description:
            "The world's best back workout with Nathan Williams, acknowledged to have the best back on the natural bodybuilding scene. Here he takes us through his ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/QGyaUK-2Zvo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/QGyaUK-2Zvo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/QGyaUK-2Zvo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fit Media Channel",
          liveBroadcastContent: "none",
          publishTime: "2020-03-01T21:00:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "eCIel3NciSCF4XS4H69o9tVz55I",
        id: {
          kind: "youtube#video",
          videoId: "JAuNs5FnAOU",
        },
        snippet: {
          publishedAt: "2020-05-15T05:00:06Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "10 MIN BACK WORKOUT - upper back, lower back, lats &amp; neck / Equipment: Bottles I Pamela Reif",
          description:
            "I used 1 liter bottles ♥︎ it's a TOTAL BACK Workout - using water bottles, wine bottles, packs of rice .. or whatever you have at home! / Werbung This was the ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/JAuNs5FnAOU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/JAuNs5FnAOU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/JAuNs5FnAOU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-05-15T05:00:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "MCCw3iLc76DPVxvxBzljoaBYdso",
        id: {
          kind: "youtube#video",
          videoId: "8LJ3Q3Fsrzs",
        },
        snippet: {
          publishedAt: "2017-08-28T12:38:19Z",
          channelId: "UCERm5yFZ1SptUEU4wZ2vJvw",
          title: "The Best Science-Based Back Workout (TARGET EVERY MUSCLE!)",
          description:
            "In this video I discuss the best back training workout that utilizes exercises for a big back, as well as exercises for a wider back based on current scientific ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8LJ3Q3Fsrzs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8LJ3Q3Fsrzs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8LJ3Q3Fsrzs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeremy Ethier",
          liveBroadcastContent: "none",
          publishTime: "2017-08-28T12:38:19Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "tAQnoHOnuzmxYBYjSh8lYIN61Rw",
        id: {
          kind: "youtube#video",
          videoId: "lA7dbOmxs5I",
        },
        snippet: {
          publishedAt: "2015-07-15T18:38:01Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "Abel Albonetti&#39;s Ultimate Back Workout",
          description:
            "If you're looking to round out your muscular physique, a strong back is an absolute must. Try this intense, high-volume workout to put on size and build a stacked ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/lA7dbOmxs5I/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/lA7dbOmxs5I/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/lA7dbOmxs5I/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2015-07-15T18:38:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "InuOaj4tTRGWJRQ5FxIovY414Ls",
        id: {
          kind: "youtube#video",
          videoId: "MOn1NKnLEFA",
        },
        snippet: {
          publishedAt: "2019-10-20T17:00:03Z",
          channelId: "UCoSxzVDrViaWSTBa5DJDfVg",
          title:
            "THE BEST BACK WORKOUT FOR A BIGGER BACK | Full Workout &amp; Top Tips",
          description:
            "Training Plans CROSSLIFTR Training (Conventional or Crossfit Box) Program: https://obivincent.com/ Follow me on INSTAGRAM: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/MOn1NKnLEFA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/MOn1NKnLEFA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/MOn1NKnLEFA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Obi Vincent",
          liveBroadcastContent: "none",
          publishTime: "2019-10-20T17:00:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "w8W_2Z9373M5EEaAQIqBZtt3lAM",
        id: {
          kind: "youtube#video",
          videoId: "JQeOhQoi3GY",
        },
        snippet: {
          publishedAt: "2016-09-20T15:06:35Z",
          channelId: "UCQPmOWNza6PMesQaWWBEhJA",
          title: "Intense 5 Minute At Home Back Workout",
          description:
            "Download My Workout App Exerprise FREE - https://bit.ly/2H7Wi0N Intense 5 Minute At Home Workouts Weekly Schedule HERE ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/JQeOhQoi3GY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/JQeOhQoi3GY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/JQeOhQoi3GY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Anabolic Aliens",
          liveBroadcastContent: "none",
          publishTime: "2016-09-20T15:06:35Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "eQBr7EuX0jTLDFf-v6fJpzhxR0s",
        id: {
          kind: "youtube#video",
          videoId: "NGEZY_Gf-2I",
        },
        snippet: {
          publishedAt: "2020-07-15T16:00:06Z",
          channelId: "UCj209lI3ZOmOCkK8e3qFFNQ",
          title: "BACK WORKOUT To Get That WIDE &amp; THICK V-Taper!",
          description:
            "Ready for a rough back workout... Let's get after it! I realized last night that I haven't filmed a FULL back workout in a while, only pieces or given insights on how ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/NGEZY_Gf-2I/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/NGEZY_Gf-2I/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/NGEZY_Gf-2I/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Ryan Humiston",
          liveBroadcastContent: "none",
          publishTime: "2020-07-15T16:00:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "xSAudZ4X3c-xhrenFc3J4crIkvU",
        id: {
          kind: "youtube#video",
          videoId: "ZOBbaws7ebM",
        },
        snippet: {
          publishedAt: "2019-10-18T16:00:11Z",
          channelId: "UCkZuCxXdE6OUGDEbETcjhPw",
          title: "Seth Feroce | Back Workout - Training To Look Good Naked",
          description:
            "https://axeandsledge.com/ https://allamericanroughneck.com/ LOOK GOOD NAKED tee available Friday 10/18/19 - 8PM EST on ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ZOBbaws7ebM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ZOBbaws7ebM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ZOBbaws7ebM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Seth Feroce",
          liveBroadcastContent: "none",
          publishTime: "2019-10-18T16:00:11Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "9HYDZuYIAKdPgEmuyN9sLWdD8Sk",
        id: {
          kind: "youtube#video",
          videoId: "upYjtQEbjAI",
        },
        snippet: {
          publishedAt: "2019-09-08T13:00:11Z",
          channelId: "UCOFCwvhDoUvYcfpD7RJKQwA",
          title: "Full Back Day Workout For BIGGER Back Muscles (DO THIS!)",
          description:
            "Take your workouts to the next level, get insane pumps and build a bigger back faster using Pre Workout by Sculpt Nation, save 20% using code 20-workout ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/upYjtQEbjAI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/upYjtQEbjAI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/upYjtQEbjAI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "V Shred",
          liveBroadcastContent: "none",
          publishTime: "2019-09-08T13:00:11Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "CQFcof5mq91AcOQpZzzoH7_1LOU",
        id: {
          kind: "youtube#video",
          videoId: "imRJUblCTjw",
        },
        snippet: {
          publishedAt: "2020-08-30T15:12:09Z",
          channelId: "UC_nfjMmJlJXrhl2xbP9wgRA",
          title: "NO GYM FULL BACK WORKOUT AT HOME | NO EQUIPMENT NEEDED",
          description:
            "NO GYM FULL BACK WORKOUT AT HOME | NO EQUIPMENT NEEDED #workout #burnfat #homeworkout My Instagram: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/imRJUblCTjw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/imRJUblCTjw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/imRJUblCTjw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "NEXT Workout",
          liveBroadcastContent: "none",
          publishTime: "2020-08-30T15:12:09Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "nKzYIkezamNJRCxnsyAT9xxva_g",
        id: {
          kind: "youtube#video",
          videoId: "NoRkALo4AMQ",
        },
        snippet: {
          publishedAt: "2019-04-29T08:15:28Z",
          channelId: "UC_Ih0f1H_eyHeLLT0Tzuh8g",
          title: "10 EXERCISES TO BUILD A BIG BACK | ADD THESE TO YOUR ROUTINE",
          description:
            "DOWNLOAD MY ROUTINES AT: https://www.simeonpanda.com INNO SUPPS: https://www.innosupps.com JUST LIFT. CLOTHING: https://www.justlift.com ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/NoRkALo4AMQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/NoRkALo4AMQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/NoRkALo4AMQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Simeon Panda",
          liveBroadcastContent: "none",
          publishTime: "2019-04-29T08:15:28Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "H2exVyZO_7BxbcpY0ez51fumCto",
        id: {
          kind: "youtube#video",
          videoId: "ty3U4R8fbLI",
        },
        snippet: {
          publishedAt: "2017-09-20T13:43:15Z",
          channelId: "UCvGEK5_U-kLgO6-AMDPeTUQ",
          title:
            "10 min Intense BURN BACK FAT No Equipment Workout | HIIT At Home Routine",
          description:
            "SUBSCRIBE FOR WEEKLY VIDEOS ▻ http://bit.ly/SubscribeToEmi ♡ADD ME ON INSTAGRAM: @EmiWong_ ▻ https://www.instagram.com/emiwong_ ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ty3U4R8fbLI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ty3U4R8fbLI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ty3U4R8fbLI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "emi wong",
          liveBroadcastContent: "none",
          publishTime: "2017-09-20T13:43:15Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "xTnV7weUo3U0U_j-NRG2_dsleLs",
        id: {
          kind: "youtube#video",
          videoId: "GJ8pAIICoek",
        },
        snippet: {
          publishedAt: "2020-02-05T16:26:32Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "Favorite Mass Building Back Workout | IFBB Pro Regan Grimes",
          description:
            "Add thickness and mass to your back with this beast-tested, pro-approved back blast from Primeval Labs-sponsored athlete Regan Grimes. ▻ Shop Primeval ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/GJ8pAIICoek/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/GJ8pAIICoek/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/GJ8pAIICoek/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2020-02-05T16:26:32Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "9Osx6OiTtE6yH9uWemlbjCmYE8Q",
        id: {
          kind: "youtube#video",
          videoId: "qqEw8XH-feI",
        },
        snippet: {
          publishedAt: "2020-02-06T19:00:10Z",
          channelId: "UCaBqRxHEMomgFU-AkSfodCw",
          title: "Home Back Workout (DUMBBELLS ONLY)",
          description:
            "Follow Chris Heria as he goes through his Back workout with exercises specifically chosen to target all areas of your Back. Join him through this Home Back ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/qqEw8XH-feI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/qqEw8XH-feI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/qqEw8XH-feI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "CHRIS HERIA",
          liveBroadcastContent: "none",
          publishTime: "2020-02-06T19:00:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "OgZ481lUyvAfJxGcR8FH61FwbrM",
        id: {
          kind: "youtube#video",
          videoId: "Eh93DTXfahk",
        },
        snippet: {
          publishedAt: "2017-01-15T19:01:29Z",
          channelId: "UCzGLDaTu81nJDtWK10MniGg",
          title: "5 Back Exercises You Should Be Doing",
          description:
            "Training Programmes - https://mikethurston.co.uk/ » 25% OFF BULK POWDERS® Supplements (USE CODE THURSTON) - http://bit.ly/2F2UHwP » Instagram: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Eh93DTXfahk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Eh93DTXfahk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Eh93DTXfahk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mike Thurston",
          liveBroadcastContent: "none",
          publishTime: "2017-01-15T19:01:29Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "SEfCeHl5TqJHFW9c_bgXB2Kx8hU",
        id: {
          kind: "youtube#video",
          videoId: "Mh_3ddy9zbY",
        },
        snippet: {
          publishedAt: "2020-01-09T11:45:01Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title: "10 min Back &amp; Arms Workout",
          description:
            "Start your New Year's Resolutions with my 28 Day Flat Tummy Challenge! 6 episodes this month including a full body workout, a morning hiit cardio, flat stomach ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Mh_3ddy9zbY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Mh_3ddy9zbY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Mh_3ddy9zbY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2020-01-09T11:45:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "p0vfliHlxmAxV5lfC0fiLXW2ja8",
        id: {
          kind: "youtube#video",
          videoId: "Fqg-jPvjlS4",
        },
        snippet: {
          publishedAt: "2017-08-18T13:12:26Z",
          channelId: "UCzBXK1ttdd2MS5Qu5Qn4Yzg",
          title: "Beginner Back Workout",
          description:
            "Looking For Personalized Meal Plan and or Coaching, see links below: Custom Meal Plans: http://www.waterjugfitness.com/custom-meal-plans Fitness ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Fqg-jPvjlS4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Fqg-jPvjlS4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Fqg-jPvjlS4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Water Jug Fitness",
          liveBroadcastContent: "none",
          publishTime: "2017-08-18T13:12:26Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "03uNgbgv2B59q5AU7TzssSMLDy8",
        id: {
          kind: "youtube#video",
          videoId: "G_L4oai_ivY",
        },
        snippet: {
          publishedAt: "2020-06-07T17:33:47Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "Back Workout to Gain Muscle (SKINNY GUYS!)",
          description:
            "If you have been looking for a full back workout to gain muscle and build a bigger wider back, especially if you are a skinny guy, then this is the video for you.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/G_L4oai_ivY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/G_L4oai_ivY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/G_L4oai_ivY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2020-06-07T17:33:47Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "58BNJMJ7fVjpK6HFmrcF4IlBhRw",
        id: {
          kind: "youtube#video",
          videoId: "1dJ-d7tVwEk",
        },
        snippet: {
          publishedAt: "2019-07-23T14:49:06Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title:
            "10 min Intense BACK WORKOUT (At Home &amp; Apartment Friendly)",
          description:
            "This at home back workout is done with minimal equipment (dumbbell only) and is only 10 minutes! THE MAT I USE (Exercise 6X4): ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/1dJ-d7tVwEk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/1dJ-d7tVwEk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/1dJ-d7tVwEk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2019-07-23T14:49:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "lnLgKFc1qrIZufUBOD34OqPRUmo",
        id: {
          kind: "youtube#video",
          videoId: "lhBRwpUc0tg",
        },
        snippet: {
          publishedAt: "2019-06-18T18:15:00Z",
          channelId: "UCK9JEqf7LBBx3tkrPx2xvbQ",
          title: "5 MINUTE BACK WORKOUT(NO EQUIPMENT)",
          description:
            "WWW.GYMOCLOCKFITNESS.COM ——————————————— At home 5 minute back workout tutorial! Follow along! ———————————————",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/lhBRwpUc0tg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/lhBRwpUc0tg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/lhBRwpUc0tg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BullyJuice",
          liveBroadcastContent: "none",
          publishTime: "2019-06-18T18:15:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "465fYJj8eePj8iAloLrLMKtTpfo",
        id: {
          kind: "youtube#video",
          videoId: "Qaq04kuSnZc",
        },
        snippet: {
          publishedAt: "2019-05-22T14:46:26Z",
          channelId: "UCmSEdfW3LpEKyLiCDWBDdVQ",
          title: "The Perfect Old School Back Workout",
          description:
            "Yet another workout in the perfect workout series. Today we are doing a 4 exercise back workout. This will totally destroy you back and give you a crazy pump.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Qaq04kuSnZc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Qaq04kuSnZc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Qaq04kuSnZc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "mountaindog1",
          liveBroadcastContent: "none",
          publishTime: "2019-05-22T14:46:26Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "XfDm0ZVaHjDNj8WrGW7KjAtlLjI",
        id: {
          kind: "youtube#video",
          videoId: "s8taXR3mYa8",
        },
        snippet: {
          publishedAt: "2019-12-19T03:00:11Z",
          channelId: "UC6vkKAsph6kZuAsC5Q8MVNQ",
          title: "9 Exercises To Build A Big Back - Gym Body Motivation",
          description:
            "9 Exercises To Build A Big Back - Gym Body Motivation Ex1: Pull-up Ex2: Wide-grip Lat Pull-down Ex3: Machine Front Pull-down Ex4: Machine High Row Ex5: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/s8taXR3mYa8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/s8taXR3mYa8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/s8taXR3mYa8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "GYM BODY MOTIVATION",
          liveBroadcastContent: "none",
          publishTime: "2019-12-19T03:00:11Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "XFfBvoZo94bWMHjG3Z7DoqrpdAE",
        id: {
          kind: "youtube#video",
          videoId: "12xHxUnBEiI",
        },
        snippet: {
          publishedAt: "2017-12-30T17:21:30Z",
          channelId: "UC68TLK0mAEzUyHx5x5k-S1Q",
          title:
            "The Most Scientific Way to Train Your BACK  | Training Science Explained",
          description:
            "For 10% off your first purchase: http://squarespace.com/nippard My Back Hypertrophy Program: http://www.strcng.com/backhypertrophyprogram ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/12xHxUnBEiI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/12xHxUnBEiI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/12xHxUnBEiI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeff Nippard",
          liveBroadcastContent: "none",
          publishTime: "2017-12-30T17:21:30Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "3LxlUekdGuM7fBHFcyJznONKyag",
        id: {
          kind: "youtube#video",
          videoId: "ITdlvmHpFc0",
        },
        snippet: {
          publishedAt: "2019-09-25T11:45:00Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title: "Sexy Back &amp; Abs Workout | 10 Mins (No Equipment)",
          description:
            "This 3 weeks lean arms challenge is great for building some upper body strength and at the same time help to tone your arms. Combine this exercise with other ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ITdlvmHpFc0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ITdlvmHpFc0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ITdlvmHpFc0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2019-09-25T11:45:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "P78VVBLNfyjmjQAqW0AVm0OOfYo",
        id: {
          kind: "youtube#video",
          videoId: "voVM_Re7nOQ",
        },
        snippet: {
          publishedAt: "2020-02-18T15:04:13Z",
          channelId: "UCK5PP5Up6Dz80dv5G4XuiiA",
          title: "Quick back workout (5 mins)",
          description:
            "SIGN UP TO SEE THE BEST PROGRAM FOR YOU HERE: https://bit.ly/3bgA0ur ----------- A 5 Minute back workout is back due to high demand. This routine will ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/voVM_Re7nOQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/voVM_Re7nOQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/voVM_Re7nOQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Holly Dolke",
          liveBroadcastContent: "none",
          publishTime: "2020-02-18T15:04:13Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "-7DTP4lbzysMXaT1N8t7y3Ju61E",
        id: {
          kind: "youtube#video",
          videoId: "svY2X7tLp7s",
        },
        snippet: {
          publishedAt: "2017-06-22T00:37:47Z",
          channelId: "UCYlG9AHZStenkJgXLj6w61A",
          title: "OLD SCHOOL BACK WORKOUT FOR THICKNESS",
          description:
            "STAUNCH: http://www.staunchnation.com/supplements/ WEBSITE: http://calumvonmoger.com OFFICIAL APPAREL: http://teamvonmoger.com INSTAGRAM: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/svY2X7tLp7s/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/svY2X7tLp7s/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/svY2X7tLp7s/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Calum von Moger",
          liveBroadcastContent: "none",
          publishTime: "2017-06-22T00:37:47Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "vpEAhMg15s0CHRsMb3YiS7gC9K8",
        id: {
          kind: "youtube#video",
          videoId: "14LTcAIwBoI",
        },
        snippet: {
          publishedAt: "2020-07-29T15:30:34Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "20 min AT HOME BACK WORKOUT (Tone, Build, &amp; Define)",
          description:
            "Do this intense back sculpt #WithMe using just a pair of dumbbells! This is a 20 min (approx) circuit designed to tone, build, & define that back! #HomeWorkout ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/14LTcAIwBoI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/14LTcAIwBoI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/14LTcAIwBoI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-07-29T15:30:34Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "P7MsgQ_aAKKhSCnWw96HNyTEjiw",
        id: {
          kind: "youtube#video",
          videoId: "ClzTDsQDC0E",
        },
        snippet: {
          publishedAt: "2020-07-23T18:00:03Z",
          channelId: "UCaBqRxHEMomgFU-AkSfodCw",
          title: "Quick And Effective Home Back Workout (Dumbbells Only)",
          description:
            "Follow Along With Chris Heria as he does a Quick And Effective Home Back Workout. All you need is a pair of dumbbells to start making insane back gains today ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ClzTDsQDC0E/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ClzTDsQDC0E/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ClzTDsQDC0E/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "CHRIS HERIA",
          liveBroadcastContent: "none",
          publishTime: "2020-07-23T18:00:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "EWaEXFW6AD1RAObQ4cyINEXZuyI",
        id: {
          kind: "youtube#video",
          videoId: "6oc4e1mxY7I",
        },
        snippet: {
          publishedAt: "2017-07-04T03:00:01Z",
          channelId: "UCU3juAek2IQClkV7eUw-a2w",
          title: "TUESDAY: Complete Back Workout! (Hindi / Punjabi)",
          description:
            "For high quality protein powders and supplements I highly recommend MY PROTEIN Click on the link below, use code iKNOWSUNNY to get 25% to 40% off!!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/6oc4e1mxY7I/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/6oc4e1mxY7I/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/6oc4e1mxY7I/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MY BOLLYWOOD BODY",
          liveBroadcastContent: "none",
          publishTime: "2017-07-04T03:00:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "oApRENPsajaj6-_8LuF7sPidNsU",
        id: {
          kind: "youtube#video",
          videoId: "p1GERX3lf3Y",
        },
        snippet: {
          publishedAt: "2013-01-25T16:56:58Z",
          channelId: "UCiP6wD_tYlYLYh3agzbByWQ",
          title:
            "Toning Lower Back Workout Routine - Best Lower Back Exercises at Home with Fitness Blender",
          description:
            "Full routine info for this Fitness Blender Lower Back Workout @ https://gofb.info/lg8HvZ At-home Workout Programs @ https://gofb.info/Programs Exclusive ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/p1GERX3lf3Y/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/p1GERX3lf3Y/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/p1GERX3lf3Y/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "FitnessBlender",
          liveBroadcastContent: "none",
          publishTime: "2013-01-25T16:56:58Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "jUKXzvbswyZqXGVv60dstapcySo",
        id: {
          kind: "youtube#video",
          videoId: "eVsTMjHAJWY",
        },
        snippet: {
          publishedAt: "2020-12-25T08:32:06Z",
          channelId: "UCn8Fiasqd-6G3A6AS322mZA",
          title: "Complete Back Workout for Mass",
          description:
            "Visit Myprotein: https://bit.ly/3kOGW58 use code GAURAV30 for orders above 6k Hope you love this video. this is mire of a documentary type workout. let me ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/eVsTMjHAJWY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/eVsTMjHAJWY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/eVsTMjHAJWY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "FitMuscle TV",
          liveBroadcastContent: "none",
          publishTime: "2020-12-25T08:32:06Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "i94ibeFNBKzlV2Srm9x7teCJX4M",
        id: {
          kind: "youtube#video",
          videoId: "Z4g7FrUYrks",
        },
        snippet: {
          publishedAt: "2018-11-05T16:45:25Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "10-Move Back Annihilation Workout | Abel Albonetti",
          description:
            "This 10-exercise workout targets every muscle in your back. But it's not for the faint of heart. See if you have what it takes. This back workout for Abel Albonetti is ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Z4g7FrUYrks/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Z4g7FrUYrks/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Z4g7FrUYrks/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2018-11-05T16:45:25Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "y3A6vV14j6EWT3j9oDEwBkeJ8Q4",
        id: {
          kind: "youtube#video",
          videoId: "E0JNbBccexE",
        },
        snippet: {
          publishedAt: "2020-01-29T08:35:18Z",
          channelId: "UC_Ih0f1H_eyHeLLT0Tzuh8g",
          title:
            "5 EXERCISES TO BUILD A WIDE V-TAPERED BACK | ADD THESE TO YOUR ROUTINE",
          description:
            "Sign up to the Elimin8 Challenge $20000 to achieve YOUR goals! https://www.elimin8.com = Win a chance to come train with me from anywhere in the ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/E0JNbBccexE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/E0JNbBccexE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/E0JNbBccexE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Simeon Panda",
          liveBroadcastContent: "none",
          publishTime: "2020-01-29T08:35:18Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "RFn84f3RPU7k0hirFVMHAtyArXk",
        id: {
          kind: "youtube#video",
          videoId: "bnLoxTvKmc4",
        },
        snippet: {
          publishedAt: "2019-02-22T17:00:05Z",
          channelId: "UCOFCwvhDoUvYcfpD7RJKQwA",
          title: "Full Back Workout Using Only Dumbbells (FORM EXPLAINED!)",
          description:
            "For 3 free tips for gaining muscle faster for your body type, take my free body type quiz - http://vshred.fit/fullbackworkout Whats goin on guys! In today's video ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/bnLoxTvKmc4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/bnLoxTvKmc4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/bnLoxTvKmc4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "V Shred",
          liveBroadcastContent: "none",
          publishTime: "2019-02-22T17:00:05Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "pulm8mvBYOVZA9ghH68GiHoqYHs",
        id: {
          kind: "youtube#video",
          videoId: "a9_JPgwW9nw",
        },
        snippet: {
          publishedAt: "2019-08-25T17:29:03Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "The BEST Dumbbell Exercises - BACK EDITION!",
          description:
            "The best dumbbell exercises for back are based on the criteria for what you are trying to specifically train for. In this video, as I've done in this entire series, I'm ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/a9_JPgwW9nw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/a9_JPgwW9nw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/a9_JPgwW9nw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2019-08-25T17:29:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "oaf7SA2BLQrp613nyFr0YflJJrI",
        id: {
          kind: "youtube#video",
          videoId: "qx8WOQ0kVBw",
        },
        snippet: {
          publishedAt: "2020-02-25T02:07:20Z",
          channelId: "UC8YnxGgVT62DDZ6sX1pLKGQ",
          title: "PERFECT Back Workout | Mike O&#39;Hearn",
          description:
            "Mike O'Hearn IG: https://www.instagram.com/mikeohearn/ My website to find all the shirts and workout plans and so much more: http://www.mikeohearn.com/ ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/qx8WOQ0kVBw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/qx8WOQ0kVBw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/qx8WOQ0kVBw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mike O'Hearn",
          liveBroadcastContent: "none",
          publishTime: "2020-02-25T02:07:20Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "OK8a5CVhDcSGOo0ulE88zyu1ydE",
        id: {
          kind: "youtube#video",
          videoId: "xLHsn8bKFSo",
        },
        snippet: {
          publishedAt: "2019-12-20T21:52:04Z",
          channelId: "UCj209lI3ZOmOCkK8e3qFFNQ",
          title: "Better Than The Best Back Workout Video Ever... Pt.1",
          description:
            "Trying to get a bigger, thicker and simply a better back? This is part 1 in my back workout series where I'm running through my favorite back exercises and ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/xLHsn8bKFSo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/xLHsn8bKFSo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/xLHsn8bKFSo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Ryan Humiston",
          liveBroadcastContent: "none",
          publishTime: "2019-12-20T21:52:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "4y185BXYSWYlP5MJSCibAsrm0ls",
        id: {
          kind: "youtube#video",
          videoId: "EZe2uJTHeHI",
        },
        snippet: {
          publishedAt: "2020-10-19T13:30:01Z",
          channelId: "UC6vkKAsph6kZuAsC5Q8MVNQ",
          title: "10 Exercises To Build A Big Back - Gym Body Motivation",
          description:
            "10 Exercises To Build A Big Back - Gym Body Motivation 00:08 Ex1: Inverted Row 00:30 Ex2: Pull Up 01:01 Ex3: Cable Pulldown 01:31 Ex4: Behind The Neck ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/EZe2uJTHeHI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/EZe2uJTHeHI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/EZe2uJTHeHI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "GYM BODY MOTIVATION",
          liveBroadcastContent: "none",
          publishTime: "2020-10-19T13:30:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "DhrEWWpz_tQ1uM18gdjlD88O1rU",
        id: {
          kind: "youtube#video",
          videoId: "luNX18oyGmA",
        },
        snippet: {
          publishedAt: "2020-01-05T18:33:41Z",
          channelId: "UCzGLDaTu81nJDtWK10MniGg",
          title: "Big Back Workout With ANDREW JACKED",
          description:
            "First training session in Dubai with a training partner, the one and only 'not so jacked' Andrew Jacked. » Training Programmes - https://mikethurston.co.uk/ » My ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/luNX18oyGmA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/luNX18oyGmA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/luNX18oyGmA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mike Thurston",
          liveBroadcastContent: "none",
          publishTime: "2020-01-05T18:33:41Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "fu0SJXJyfDwg7YPLUpabEKZg0k0",
        id: {
          kind: "youtube#video",
          videoId: "WuTNvgToNJY",
        },
        snippet: {
          publishedAt: "2019-08-02T12:58:51Z",
          channelId: "UC5_i5V3xXxdqF5VKVmJWVZQ",
          title: "Back Workout | Charles Glass Trains Errol Moore at the Mecca",
          description:
            "Nutrex athlete and IFBB Pro, Errol Moore, goes through a back workout in with his coach, Charles Glass, at Gold's Gym Venice. Errol's Back Workout: Behind the ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/WuTNvgToNJY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/WuTNvgToNJY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/WuTNvgToNJY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Muscle & Strength",
          liveBroadcastContent: "none",
          publishTime: "2019-08-02T12:58:51Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "u9FZbI-0tKIn3WtIxhf3XCXTnYI",
        id: {
          kind: "youtube#video",
          videoId: "umBna1_qI8A",
        },
        snippet: {
          publishedAt: "2020-10-06T17:46:27Z",
          channelId: "UCj209lI3ZOmOCkK8e3qFFNQ",
          title:
            "ABSOLUTE BEST Dumbbell Back Workout Ever (YOU NEED THESE EXERCISES!)",
          description:
            "Ready for a dumbbell back workout that will ACTUALLY get you results... Let's get after it. Recently I realized how much I was relying on machines during my ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/umBna1_qI8A/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/umBna1_qI8A/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/umBna1_qI8A/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Ryan Humiston",
          liveBroadcastContent: "none",
          publishTime: "2020-10-06T17:46:27Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "KLr1rGDGWy4xaVP16ifOaKvTH1w",
        id: {
          kind: "youtube#video",
          videoId: "apWQucmQU5w",
        },
        snippet: {
          publishedAt: "2019-05-09T16:14:56Z",
          channelId: "UC0CRYvGlWGlsGxBNgvkUbAg",
          title: "8 BEST Exercises For a WIDER BACK!",
          description:
            "These are the 8 best exercises to build a WIDER BACK. You'll learn how to build big lats and how to get a bigger, thicker, v taper back just by using barbells, ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/apWQucmQU5w/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/apWQucmQU5w/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/apWQucmQU5w/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Gravity Transformation - Fat Loss Experts",
          liveBroadcastContent: "none",
          publishTime: "2019-05-09T16:14:56Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "HsIjlefVNOUfE9pC62RlKUkz3Jo",
        id: {
          kind: "youtube#video",
          videoId: "COwGXr1RIlI",
        },
        snippet: {
          publishedAt: "2019-04-16T13:01:29Z",
          channelId: "UCK5PP5Up6Dz80dv5G4XuiiA",
          title: "Back Workout for Women (No Equipment)",
          description:
            "This routine targets all areas of your back, to give burn fat and give you a toned and sculpted look! ♡ DO THE QUIZ TO KNOW WHAT DIET AND TRAINING IS ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/COwGXr1RIlI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/COwGXr1RIlI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/COwGXr1RIlI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Holly Dolke",
          liveBroadcastContent: "none",
          publishTime: "2019-04-16T13:01:29Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "oF1eC5IYPskXug7QDERc2bOBAz4",
        id: {
          kind: "youtube#video",
          videoId: "lEfB7XYi8k4",
        },
        snippet: {
          publishedAt: "2016-08-02T13:30:01Z",
          channelId: "UCEQi1ZNJiw3YMRwni0OLsTQ",
          title: "Get RID Of BACK FAT | Full Back Workout",
          description:
            "Follow me on Instagram for daily Fit Tips With Whit! https://www.instagram.com/whitneyysimmons/?hl=en Welcome back, babes! There is nothing sexier than a ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/lEfB7XYi8k4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/lEfB7XYi8k4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/lEfB7XYi8k4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Whitney Simmons",
          liveBroadcastContent: "none",
          publishTime: "2016-08-02T13:30:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "dcJbMNj-NWAc9QlHz65f7zcuQwQ",
        id: {
          kind: "youtube#video",
          videoId: "OXvQe9payHw",
        },
        snippet: {
          publishedAt: "2016-07-03T01:21:59Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "Best Back Workout Video Ever (HIT EVERY MUSCLE!!)",
          description:
            "Build a complete back with this workout - http://athleanx.com/x/the-complete-physique It won't take long for you to see why I'm calling this the best back workout ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/OXvQe9payHw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/OXvQe9payHw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/OXvQe9payHw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2016-07-03T01:21:59Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "L1n1_r8xWWj7h48N95tZSZ__Y5w",
        id: {
          kind: "youtube#video",
          videoId: "twjhKJtHwfg",
        },
        snippet: {
          publishedAt: "2018-09-13T17:00:07Z",
          channelId: "UCl1Tkl0amPsxWJ1HLQdj92A",
          title: "5 MUST DO BACK EXERCISES |  Honestly the best!",
          description:
            "Hey Lovely people These are the best 5 exercise you must do on back day! Focus on contraction and please focus on FORM not lifting heavy!!!! I hope you liked ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/twjhKJtHwfg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/twjhKJtHwfg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/twjhKJtHwfg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Krissy Cela",
          liveBroadcastContent: "none",
          publishTime: "2018-09-13T17:00:07Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "oYiCKb9zSuo9o4HOVVa7Y5X5uo8",
        id: {
          kind: "youtube#video",
          videoId: "lzA81eLSsjg",
        },
        snippet: {
          publishedAt: "2019-12-26T17:05:31Z",
          channelId: "UC3-xcOU2D7f9XA5JQm2Lgtw",
          title: "BEST BACK WORKOUT EVER! Maximum Results",
          description:
            "WORKOUT PROGRAMS & RG APPAREL https://www.regangrimes.com PRIMEVAL LABS 25% OFF CODE “REGAN25” https://primevallabs.com/?p=HkxSp1e1r ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/lzA81eLSsjg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/lzA81eLSsjg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/lzA81eLSsjg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Regan Grimes",
          liveBroadcastContent: "none",
          publishTime: "2019-12-26T17:05:31Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "5thYACjGGLPixZfKwYpdQBAY5j8",
        id: {
          kind: "youtube#video",
          videoId: "2jF12VbWvHk",
        },
        snippet: {
          publishedAt: "2018-04-12T03:56:10Z",
          channelId: "UCKfr4Suk97-gB6WkL2CP_eA",
          title: "BACK WORKOUT FOR MASS 104",
          description:
            "BODYBUILDING CLOTHING FOR REAL BODYBUILDERS https://www.totalalphabrand.com/ Coaching and training programs https://www.damienpatrick.com ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/2jF12VbWvHk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/2jF12VbWvHk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/2jF12VbWvHk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Damien Patrick",
          liveBroadcastContent: "none",
          publishTime: "2018-04-12T03:56:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "vcwWye5s6d0DUX6y_19nPzL72mA",
        id: {
          kind: "youtube#video",
          videoId: "-j1E4LFtUTM",
        },
        snippet: {
          publishedAt: "2020-08-11T23:45:46Z",
          channelId: "UCbm8FxO0gBbZ19iydL9g3ZQ",
          title: "INTENSE BACK WORKOUT &amp; Jeep Upgrades",
          description:
            "Taking you through a real intense back workout and explaining more of my mentality when it comes to how I structure my workouts to force my body to grow. Also ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/-j1E4LFtUTM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/-j1E4LFtUTM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/-j1E4LFtUTM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "JustMerk",
          liveBroadcastContent: "none",
          publishTime: "2020-08-11T23:45:46Z",
        },
      },
    ],
  },
];
