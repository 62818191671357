import React, { useState } from "react";

import { ArmWorkouts } from "../Workouts/ArmWorkouts";
import { BackWorkouts } from "../Workouts/BackWorkouts";
import { CardioWorkouts } from "../Workouts/CardioWorkouts";
import { ChestWorkouts } from "../Workouts/ChestWorkouts";
import { CoreWorkouts } from "../Workouts/CoreWorkouts";
import { GluteWorkouts } from "../Workouts/GluteWorkouts";
import { LegWorkouts } from "../Workouts/LegWorkouts";
import { ShoulderWorkouts } from "../Workouts/ShoulderWorkouts";
import { WholeBodyWorkouts } from "../Workouts/WholeBodyWorkouts";

import "./NavBar.css";

function NavBar(props) {
  const [hide, setClick] = useState(true);
  const handleClick = () => setClick(!hide);
  const closeMobileMenu = () => setClick(true);

  return (
    <div className={"screen"}>
      <nav>
        <ul className={hide ? "nav-links": "nav-links nav-active"}>
          <li onClick={closeMobileMenu}>
            <button className="button" onClick={() => props.click(props.onSet, ArmWorkouts)}>Arms</button>
          </li>
          <li onClick={closeMobileMenu}>
            <button className="button" onClick={() => props.click(props.onSet, BackWorkouts)}>Back</button>
          </li>
          <li onClick={closeMobileMenu}>
            <button className="button" onClick={() => props.click(props.onSet, CardioWorkouts)}>Cardio</button>
          </li>
          <li onClick={closeMobileMenu}>
            <button className="button" onClick={() => props.click(props.onSet, ChestWorkouts)}>Chest</button>
          </li>
          <li onClick={closeMobileMenu}>
            <button className="button" onClick={() => props.click(props.onSet, CoreWorkouts)}>Core</button>
          </li>
          <li onClick={closeMobileMenu}>
            <button className="button" onClick={() => props.click(props.onSet, GluteWorkouts)}>Glutes</button>
          </li>
          <li onClick={closeMobileMenu}>
            <button className="button" onClick={() => props.click(props.onSet, LegWorkouts)}>Legs</button>
          </li>
          <li onClick={closeMobileMenu}>
            <button className="button" onClick={() => props.click(props.onSet, ShoulderWorkouts)}>Shoulders</button>
          </li>
          <li onClick={closeMobileMenu}>
            <button className="button" onClick={() => props.click(props.onSet, WholeBodyWorkouts)}>Whole Body</button>
          </li>
        </ul>
        <div className={"burger"} onClick={handleClick}>
          <div className={"line1"}></div>
          <div className={"line2"}></div>
          <div className={"line3"}></div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
