export const ArmWorkouts = [
  {
    kind: "youtube#searchListResponse",
    etag: "VStHbts3q43gyBIJrPosbN1n2R0",
    nextPageToken: "CDIQAA",
    regionCode: "GB",
    pageInfo: {
      totalResults: 1000000,
      resultsPerPage: 50,
    },
    items: [
      {
        kind: "youtube#searchResult",
        etag: "gIVW4qn_pldz6v0TANqpwsGSBl0",
        id: {
          kind: "youtube#video",
          videoId: "UyTR2EjTAXU",
        },
        snippet: {
          publishedAt: "2018-12-13T13:49:57Z",
          channelId: "UCK5PP5Up6Dz80dv5G4XuiiA",
          title: "Tone Your Arms Workout - No Equipment (QUICK + INTENSE)",
          description:
            "DO THE QUIZ TO KNOW WHAT DIET AND TRAINING IS BEST FOR YOU: https://bit.ly/2YNaO9U Tone Arms | Tone Arms At Home | Workout | Quick | Intense ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/UyTR2EjTAXU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/UyTR2EjTAXU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/UyTR2EjTAXU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Holly Dolke",
          liveBroadcastContent: "none",
          publishTime: "2018-12-13T13:49:57Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "EDgF3S9K8SQImk9hBMf3PfrpbXQ",
        id: {
          kind: "youtube#video",
          videoId: "UY6-JzdnHUM",
        },
        snippet: {
          publishedAt: "2020-09-18T16:30:01Z",
          channelId: "UCyiNCEHLyKbSUrrncVr9KvQ",
          title: "15 MINUTE ARM WORKOUT (DUMBBELLS ONLY)",
          description:
            "Get ready for one of the best Home Arm Workouts of your LIFE! Let's do this! A dumbbell only arm workout that you can do from the comfort of your own home!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/UY6-JzdnHUM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/UY6-JzdnHUM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/UY6-JzdnHUM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fraser Wilson",
          liveBroadcastContent: "none",
          publishTime: "2020-09-18T16:30:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "OuwIrU5V_e3AYSlsDLsYePBIzdA",
        id: {
          kind: "youtube#video",
          videoId: "j64BBgBGNIU",
        },
        snippet: {
          publishedAt: "2019-10-02T11:17:08Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title: "10 Mins Toned Arms Workout | No Equipment",
          description:
            "This is the final episode of the Lean Arms Challenge! This workout will really work those arms of yours and it's all standing exercises and low impact so it's great ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/j64BBgBGNIU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/j64BBgBGNIU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/j64BBgBGNIU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2019-10-02T11:17:08Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "9gJz1qxny5QnEaoJO0E8PAAmqSs",
        id: {
          kind: "youtube#video",
          videoId: "l0CwCvJbGZI",
        },
        snippet: {
          publishedAt: "2019-06-03T19:42:32Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "10 MIN TONED ARMS WORKOUT (At Home Minimal Equipment)",
          description:
            "A 10 minute arms workout to tone that upper body! All you need is a pair of dumbbells! ⭐️SHOP MY COOKBOOKS! 100+ RECIPES: https://goo.gl/XHwUJg ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/l0CwCvJbGZI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/l0CwCvJbGZI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/l0CwCvJbGZI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2019-06-03T19:42:32Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Vy67ewSQxw97SHb_xnMmUt7H3AI",
        id: {
          kind: "youtube#video",
          videoId: "8fvT3sYfzLo",
        },
        snippet: {
          publishedAt: "2017-09-14T23:29:11Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "8 Minute Arm Workout (SHOCK YOUR ARMS!)",
          description:
            "Pick your program here - http://athleanx.com/x/my-workouts Subscribe to this channel here - http://bit.ly/2b0coMW The key to any arm workout is intensity.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8fvT3sYfzLo/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8fvT3sYfzLo/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8fvT3sYfzLo/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2017-09-14T23:29:11Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Hc1QjBdWXbwhTTS1ejE0ZWo4fIE",
        id: {
          kind: "youtube#video",
          videoId: "RTF4ADblouI",
        },
        snippet: {
          publishedAt: "2019-10-13T16:15:00Z",
          channelId: "UCK9JEqf7LBBx3tkrPx2xvbQ",
          title: "5 MINUTE ARM WORKOUT(NO EQUIPMENT)",
          description: "WWW.GYMOCLOCKFITNESS.COM ———————————————",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/RTF4ADblouI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/RTF4ADblouI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/RTF4ADblouI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BullyJuice",
          liveBroadcastContent: "none",
          publishTime: "2019-10-13T16:15:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "p6jFytYIlOHUz7Nx54P0MqNR3C0",
        id: {
          kind: "youtube#video",
          videoId: "W2Mq_c-dgVY",
        },
        snippet: {
          publishedAt: "2020-03-28T16:21:32Z",
          channelId: "UCWN2FPlvg9r-LnUyepH9IaQ",
          title: "5 Minutes Arm Workout For Toning | How To Lose Arm Fat",
          description:
            "An incredible 5 minute arm workout that will tone and sculpt like never before. If you struggling to lose extra arm fat or simply looking for a way to gain more lean ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/W2Mq_c-dgVY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/W2Mq_c-dgVY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/W2Mq_c-dgVY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Boho Beautiful",
          liveBroadcastContent: "none",
          publishTime: "2020-03-28T16:21:32Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "xqeU7w-6bAgbILVXsngZU7uEsIE",
        id: {
          kind: "youtube#video",
          videoId: "Y346900i9qE",
        },
        snippet: {
          publishedAt: "2020-10-18T15:30:02Z",
          channelId: "UCIJwWYOfsCfz6PjxbONYXSg",
          title: "15 Minute Arm Burnout (weightless upper body workout)",
          description:
            "You guys have been asking for this for a while, so today I put together some of my favorite arm exercises you can do at home!! Best part? No equipment is ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Y346900i9qE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Y346900i9qE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Y346900i9qE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "blogilates",
          liveBroadcastContent: "none",
          publishTime: "2020-10-18T15:30:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "V6oy4BuT1Xazhzuzd8YVA67pR-E",
        id: {
          kind: "youtube#video",
          videoId: "8qDDtm6BOfw",
        },
        snippet: {
          publishedAt: "2020-02-27T12:02:13Z",
          channelId: "UCce7Uuc8LILSkgzavNyDL9g",
          title:
            "Slim Arms in 30 DAYs! | 8 Min Beginner Friendly Standing Workout ( No Equipment )",
          description:
            "LoseFlabbyArm #TonedArmsFast #HomeWorkout (Subtitles has been uploaded) Slim Arms Program ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8qDDtm6BOfw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8qDDtm6BOfw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8qDDtm6BOfw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "April Han",
          liveBroadcastContent: "none",
          publishTime: "2020-02-27T12:02:13Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "cQ-7scvlGgjXTGiCVz4GVO2y4ds",
        id: {
          kind: "youtube#video",
          videoId: "cF0niVnmMY0",
        },
        snippet: {
          publishedAt: "2013-03-29T20:00:27Z",
          channelId: "UCM1Nde-9eorUhq-teaWlgUA",
          title: "Total Arm Workout",
          description:
            "Sign up for our newsletter: http://goo.gl/UwnMd Subscribe: http://goo.gl/qR0gi On today's episode of XHIT, fitness trainer Rebecca-Louise shows you how to get ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/cF0niVnmMY0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/cF0niVnmMY0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/cF0niVnmMY0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "XHIT Daily",
          liveBroadcastContent: "none",
          publishTime: "2013-03-29T20:00:27Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "WwljqWZ2yCXsSubJsDKtQOzhMtI",
        id: {
          kind: "youtube#video",
          videoId: "J7buRPxIY1c",
        },
        snippet: {
          publishedAt: "2017-01-08T17:35:24Z",
          channelId: "UCzGLDaTu81nJDtWK10MniGg",
          title: "Full Biceps &amp; Triceps Workout For Bigger Arms",
          description:
            "Training Programmes - https://mikethurston.co.uk/ » Website: http://www.mikethurston.co.uk/ » Instagram: https://www.instagram.com/mikethurston » Facebook: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/J7buRPxIY1c/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/J7buRPxIY1c/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/J7buRPxIY1c/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mike Thurston",
          liveBroadcastContent: "none",
          publishTime: "2017-01-08T17:35:24Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "lW1RnG0RQx8b3edmuOoHXvwMPt0",
        id: {
          kind: "youtube#video",
          videoId: "4pcaD3wInqM",
        },
        snippet: {
          publishedAt: "2012-02-06T04:41:37Z",
          channelId: "UCMe-LG42uv06u_UCKIoZH3Q",
          title: "Get Madonna&#39;s Arms With This 10-Minute Workout",
          description:
            "Toned arms never go out of style. If your shoulders have been hidden under a thick, cozy sweater for the last couple of months, it's time to start prepping them for ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/4pcaD3wInqM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/4pcaD3wInqM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/4pcaD3wInqM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "popsugarliving",
          liveBroadcastContent: "none",
          publishTime: "2012-02-06T04:41:37Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "0D_lH0PeGhRDCtKsg04zwnWuJb0",
        id: {
          kind: "youtube#video",
          videoId: "CLccU7tk7es",
        },
        snippet: {
          publishedAt: "2018-08-25T16:00:00Z",
          channelId: "UCOFCwvhDoUvYcfpD7RJKQwA",
          title: "7 Bicep Exercises for Bigger Arms (DON&#39;T SKIP THESE!)",
          description:
            "For more workouts on how to get big biceps along with a nutrition plan designed for your goals, take my free body type quiz and get the right plan for you ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/CLccU7tk7es/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/CLccU7tk7es/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/CLccU7tk7es/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "V Shred",
          liveBroadcastContent: "none",
          publishTime: "2018-08-25T16:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "yb99R3kXxv185cdHzU_T0CcOYSA",
        id: {
          kind: "youtube#video",
          videoId: "3vw0w_j7O1A",
        },
        snippet: {
          publishedAt: "2019-05-26T17:00:00Z",
          channelId: "UCoSxzVDrViaWSTBa5DJDfVg",
          title:
            "FULL BICEPS &amp; TRICEPS WORKOUT YOU SHOULD BE DOING FOR BIGGER ARMS",
          description:
            "50% off CROSSLIFTR Training Program https://obivincent.com/ Follow me on INSTAGRAM: https://www.instagram.com/obi_vincent/ BREAKTHEMOULD ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/3vw0w_j7O1A/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/3vw0w_j7O1A/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/3vw0w_j7O1A/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Obi Vincent",
          liveBroadcastContent: "none",
          publishTime: "2019-05-26T17:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "9dC4xJrzBDMbh9M4JB3iSancYEU",
        id: {
          kind: "youtube#video",
          videoId: "hAGfBjvIRFI",
        },
        snippet: {
          publishedAt: "2013-06-10T18:45:24Z",
          channelId: "UCM1Nde-9eorUhq-teaWlgUA",
          title: "How To Lose Arm Fat",
          description:
            "Sign up for our newsletter: http://goo.gl/UwnMd Subscribe: http://goo.gl/qR0gi On today's episode of XHIT, fitness trainer Rebecca-Louise shows you how to get ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/hAGfBjvIRFI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/hAGfBjvIRFI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/hAGfBjvIRFI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "XHIT Daily",
          liveBroadcastContent: "none",
          publishTime: "2013-06-10T18:45:24Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "90tswVoURg40RckSUFwdpx_XtME",
        id: {
          kind: "youtube#video",
          videoId: "83W7o34NFpA",
        },
        snippet: {
          publishedAt: "2014-05-19T20:00:02Z",
          channelId: "UCM1Nde-9eorUhq-teaWlgUA",
          title: "XHIT - Total Arm Workout",
          description: "",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/83W7o34NFpA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/83W7o34NFpA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/83W7o34NFpA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "XHIT Daily",
          liveBroadcastContent: "none",
          publishTime: "2014-05-19T20:00:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "lJYu9bPrbZPghPp-XER21xMOLYM",
        id: {
          kind: "youtube#video",
          videoId: "DHOPWvO3ZcI",
        },
        snippet: {
          publishedAt: "2020-03-22T08:00:04Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "10 MIN UPPER BODY WORKOUT - Back, Arms &amp; Chest / No Equipment I Pamela Reif",
          description:
            "SURPRISE ♥︎ due to the current situation, I decided to NOT wait another 2 weeks until I upload the next workout. And being at home .. I felt like we are in ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/DHOPWvO3ZcI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/DHOPWvO3ZcI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/DHOPWvO3ZcI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-03-22T08:00:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "CLp2lxhvIcPHmOH6wtlZ4tWnV28",
        id: {
          kind: "youtube#video",
          videoId: "VgNEBPEHyHA",
        },
        snippet: {
          publishedAt: "2020-08-11T14:00:52Z",
          channelId: "UCK5PP5Up6Dz80dv5G4XuiiA",
          title: "EXTREME Arm Burn - 7 mins - Level Up!",
          description:
            "MY QUIZ: FIND OUT WHAT DIET & TRAINING IS BEST FOR YOU: https://bit.ly/31IXWDG ----- Welcome to Level Up Series: where we will be doing a mix of ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/VgNEBPEHyHA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/VgNEBPEHyHA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/VgNEBPEHyHA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Holly Dolke",
          liveBroadcastContent: "none",
          publishTime: "2020-08-11T14:00:52Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "6k9qEWIXGs2fCFRVUPC304hZfPI",
        id: {
          kind: "youtube#video",
          videoId: "X6gWQ1Lcvjg",
        },
        snippet: {
          publishedAt: "2020-07-13T18:07:02Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title:
            "15 Min DUMBBELL ARMS &amp; SHOULDER WORKOUT at Home | No Repeat",
          description:
            "This is a no repeat arm and shoulder workout at home using only a pair of dumbbells and a mat! We will hit shoulders, the biceps and triceps lifting and lowering ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/X6gWQ1Lcvjg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/X6gWQ1Lcvjg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/X6gWQ1Lcvjg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-07-13T18:07:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Nv8KCrJIEU2_KAzu3PGcuOxomkY",
        id: {
          kind: "youtube#video",
          videoId: "XNTj8RZVcrE",
        },
        snippet: {
          publishedAt: "2019-05-13T06:36:19Z",
          channelId: "UCqjwF8rxRsotnojGl4gM0Zw",
          title: "Bigger Arms Workout (Dumbbells Only)",
          description:
            "Join Chris Heria as he shows you how to get a bigger Arms with just Dumbbells. Follow Along with this full Arm Workout Specifically designed to maximize your ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/XNTj8RZVcrE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/XNTj8RZVcrE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/XNTj8RZVcrE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "THENX",
          liveBroadcastContent: "none",
          publishTime: "2019-05-13T06:36:19Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "D8Gotw4xFGiK2HM5p4unOhqRHa4",
        id: {
          kind: "youtube#video",
          videoId: "PJO1hQSMPT4",
        },
        snippet: {
          publishedAt: "2017-08-02T16:00:03Z",
          channelId: "UCBINFWq52ShSgUFEoynfSwg",
          title: "4-Minute No-Weight, Arm Toning Workout | Class FitSugar",
          description:
            "No equipment needed to tone up your arms in 4 minutes — from ModelFIT. On Anna: Sweaty Betty and Athletic Propulsion Labs (APL) shoes On Sarah: Sweaty ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/PJO1hQSMPT4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/PJO1hQSMPT4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/PJO1hQSMPT4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "POPSUGAR Fitness",
          liveBroadcastContent: "none",
          publishTime: "2017-08-02T16:00:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "WiTfO6rzU4TwDrJNRsnwedFwCKs",
        id: {
          kind: "youtube#video",
          videoId: "MgMFdwd7g-c",
        },
        snippet: {
          publishedAt: "2015-01-27T20:19:50Z",
          channelId: "UCM1Nde-9eorUhq-teaWlgUA",
          title: "Total Arm Workout",
          description:
            "XFIT Daily hits you with intense, full-body workouts five days a week. Trainers Jason Charchan and Michael Patarino lead you through a grueling series of ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/MgMFdwd7g-c/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/MgMFdwd7g-c/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/MgMFdwd7g-c/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "XHIT Daily",
          liveBroadcastContent: "none",
          publishTime: "2015-01-27T20:19:50Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "cADdfUk601b0P300rzYpFtjbOmg",
        id: {
          kind: "youtube#video",
          videoId: "S5kOK3bxfro",
        },
        snippet: {
          publishedAt: "2017-10-25T15:22:25Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "Super-Pump Arm Workout for Mass | Abel Albonetti",
          description:
            "Think you know how to use supersets to get a super pump? Abel Albonetti shares his killer arm workout to help you get the best biceps and triceps pump of your ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/S5kOK3bxfro/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/S5kOK3bxfro/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/S5kOK3bxfro/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2017-10-25T15:22:25Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "XV8vdKkBRymhY9OahanTHBNx_5g",
        id: {
          kind: "youtube#video",
          videoId: "e_HviaEIEas",
        },
        snippet: {
          publishedAt: "2020-04-17T05:00:12Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "10 MIN TONED ARMS - quick &amp; intense at home / with water bottles I Pamela Reif",
          description:
            "TIP: fill up your bottles as FULL AS POSSIBLE. If there's air in the bottle, the water will move from side to side - which is a little annoying. / Werbung I know ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/e_HviaEIEas/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/e_HviaEIEas/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/e_HviaEIEas/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-04-17T05:00:12Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "E5wZyzy3TqmIhMo7L78z_dP_VVQ",
        id: {
          kind: "youtube#video",
          videoId: "Q8hvLCVOE9s",
        },
        snippet: {
          publishedAt: "2020-05-05T19:05:07Z",
          channelId: "UC0wI5OyvSnptLFCd7YcXulQ",
          title: "DUMBBELL ONLY ARM WORKOUT / Get Huge Arms At Home",
          description:
            "HOME WORKOUT GUIDES (BODYWEIGHT, DBS & RESISTANCE BAND) https://marinokatsouris.co.uk/ Shop Myprotein: http://tidd.ly/6f1dd1d5 Use Code ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Q8hvLCVOE9s/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Q8hvLCVOE9s/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Q8hvLCVOE9s/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Marino Katsouris",
          liveBroadcastContent: "none",
          publishTime: "2020-05-05T19:05:07Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "_YjrkNe4YwvszLjt0Ze9HeNWSeE",
        id: {
          kind: "youtube#video",
          videoId: "RE5yzqSXDCI",
        },
        snippet: {
          publishedAt: "2018-12-14T17:52:13Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "10 MIN TONED ARMS WORKOUT (At Home No Equipment)",
          description:
            "A 10 minute arms workout to tone that upper body! There is no equipment needed, and you can do this workout anywhere! ⭐️SHOP MY COOKBOOKS!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/RE5yzqSXDCI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/RE5yzqSXDCI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/RE5yzqSXDCI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2018-12-14T17:52:13Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "uPwH6Ru92opEqnccErG8C1gh1lk",
        id: {
          kind: "youtube#video",
          videoId: "fsPKfahw-B0",
        },
        snippet: {
          publishedAt: "2014-05-02T21:26:51Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "CT Fletcher&#39;s Armed Warfare Arm Workout",
          description:
            "CT Fletcher knows a thing or fifty about building monstrous biceps and triceps. Think you can hang? Get ready to command your muscles to grow! ▻Get the Full ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/fsPKfahw-B0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/fsPKfahw-B0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/fsPKfahw-B0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2014-05-02T21:26:51Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "H0_j3bjb7JQ0bxeYENIY5RuLL_o",
        id: {
          kind: "youtube#video",
          videoId: "0GRsBJZ2Fb4",
        },
        snippet: {
          publishedAt: "2015-09-29T20:23:14Z",
          channelId: "UCag7XoiJLutjBTsM0tAzUzg",
          title:
            "ARM EXERCISES FOR WOMEN - Get Rid of Bingo Wings &amp; Tone Your Arms - Home Workout - Lucy Wyndham-Read",
          description:
            "ARM EXERCISES FOR WOMEN - Get Rid of Bingo Wings & Tone Your Arms - Home Workout - Lucy Wyndham-Read GET YOUR DREAM ARMS BOOK ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/0GRsBJZ2Fb4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/0GRsBJZ2Fb4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/0GRsBJZ2Fb4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Lucy Wyndham-Read",
          liveBroadcastContent: "none",
          publishTime: "2015-09-29T20:23:14Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "_LtSqrjaQCh7bSEUZWNpfcPoYPs",
        id: {
          kind: "youtube#video",
          videoId: "5qCjyzbKmAE",
        },
        snippet: {
          publishedAt: "2019-09-23T11:39:04Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title: "Lean Arms Workout Challenge |  Lose Arm Fat (No Equipment)",
          description:
            "This 3 weeks lean arms challenge is great for building some upper body strength and at the same time help to tone your arms. Combine this exercise with other ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/5qCjyzbKmAE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/5qCjyzbKmAE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/5qCjyzbKmAE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2019-09-23T11:39:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "QZQ85O0raJuiTX-DqIr1jczaCHo",
        id: {
          kind: "youtube#video",
          videoId: "42o0qjnF4fY",
        },
        snippet: {
          publishedAt: "2018-10-03T16:00:01Z",
          channelId: "UCkZuCxXdE6OUGDEbETcjhPw",
          title: "Mass Building Arm Workout",
          description:
            "https://axeandsledge.com/ https://allamericanroughneck.com/ Warm-Up! DB Curls & Tricep Press Downs, Fill up those muscles! 1. Alt. DB Curls | Super Set with ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/42o0qjnF4fY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/42o0qjnF4fY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/42o0qjnF4fY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Seth Feroce",
          liveBroadcastContent: "none",
          publishTime: "2018-10-03T16:00:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "4_BOig7-TJkMscxzppyjwyH-UGw",
        id: {
          kind: "youtube#video",
          videoId: "4lvwAEKGsMQ",
        },
        snippet: {
          publishedAt: "2016-06-30T19:09:43Z",
          channelId: "UCKUpAAg8q4CozUxBjxyvDDQ",
          title: "5 minute arm workout- get long, lean, toned arms",
          description:
            "If you are looking for a quick arm workout that gives you long, lean, toned arm muscles, this is it! All you need is 2 pound weights and a great 4-5 minute song.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/4lvwAEKGsMQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/4lvwAEKGsMQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/4lvwAEKGsMQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Annie Taylor Efremsky",
          liveBroadcastContent: "none",
          publishTime: "2016-06-30T19:09:43Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "bmt9JiEkEb1xYY5cMmApvg1qywM",
        id: {
          kind: "youtube#video",
          videoId: "mFN-t4tq9BM",
        },
        snippet: {
          publishedAt: "2020-10-21T14:00:23Z",
          channelId: "UCmSEdfW3LpEKyLiCDWBDdVQ",
          title:
            "&quot;MEGA&quot; Arm Pump Workout (Add Inches to Your Biceps &amp; Triceps)",
          description:
            "So who loves a good pump in the gym? I know I sure do. In this amazing new workout series we are going to be getting mega pumps. What better body part to ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/mFN-t4tq9BM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/mFN-t4tq9BM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/mFN-t4tq9BM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "mountaindog1",
          liveBroadcastContent: "none",
          publishTime: "2020-10-21T14:00:23Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "y9YFwiX2slmqjoCGXHEZy4xNbwk",
        id: {
          kind: "youtube#video",
          videoId: "kYMDcMNvHh8",
        },
        snippet: {
          publishedAt: "2018-02-20T19:59:56Z",
          channelId: "UCag7XoiJLutjBTsM0tAzUzg",
          title:
            "7 DAY CHALLENGE - 7 MINUTE WORKOUT - TO LOSE ARM FLAB  - ARM EXERCISE FOR WOMEN - START TODAY",
          description:
            "GET YOUR DREAM ARMS BOOK https://www.lwrfitness.com/product/3minutearmbook/ NEW HEALTHY RECIPE BOOK ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/kYMDcMNvHh8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/kYMDcMNvHh8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/kYMDcMNvHh8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Lucy Wyndham-Read",
          liveBroadcastContent: "none",
          publishTime: "2018-02-20T19:59:56Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "lA20K1mimx1r-Hlz_fx_6SARWZw",
        id: {
          kind: "youtube#video",
          videoId: "ws0UI8Syyus",
        },
        snippet: {
          publishedAt: "2020-01-27T14:33:15Z",
          channelId: "UCj209lI3ZOmOCkK8e3qFFNQ",
          title: "BIG ARM WORKOUT - (Grow Faster With These Exercises!!)",
          description:
            "Ready For Some Bigger Arms? Last night I went through one of my arm days where I hit biceps and triceps together. I walk you through some of my favorite ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ws0UI8Syyus/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ws0UI8Syyus/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ws0UI8Syyus/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Ryan Humiston",
          liveBroadcastContent: "none",
          publishTime: "2020-01-27T14:33:15Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "3sKhwPreZzcloR0Z9x9WGmU5BhI",
        id: {
          kind: "youtube#video",
          videoId: "0zhvUV1bAVQ",
        },
        snippet: {
          publishedAt: "2020-02-20T18:40:07Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "Full UPPER BODY Workout (Tone &amp; Sculpt) - 15 min At Home",
          description:
            "Try this 15 min dumbbell upper body circuit at home! Tone, sculpt, and build the arms, chest, back, and shoulders! SHOP MY COOKBOOKS!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/0zhvUV1bAVQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/0zhvUV1bAVQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/0zhvUV1bAVQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2020-02-20T18:40:07Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "mhN7moyq8kuD7wCZKNnlTJ0CmVI",
        id: {
          kind: "youtube#video",
          videoId: "XKBbn5GBw0Y",
        },
        snippet: {
          publishedAt: "2016-04-17T18:00:01Z",
          channelId: "UCIJwWYOfsCfz6PjxbONYXSg",
          title:
            "Quick Burn Arms! Incredible Arm Toning Workout - no equipment, no pushups!",
          description:
            "I'm going to shape your arms sooooooo GOOD with this arm routine that uses NO WEIGHTS, NO DUMBBELLS, and NO PUSHUPS! Uhh yeah. It's possible.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/XKBbn5GBw0Y/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/XKBbn5GBw0Y/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/XKBbn5GBw0Y/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "blogilates",
          liveBroadcastContent: "none",
          publishTime: "2016-04-17T18:00:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "zQcYO0OpScCfjcNTUjiZZVp4fXE",
        id: {
          kind: "youtube#video",
          videoId: "r1FNRH-mG2E",
        },
        snippet: {
          publishedAt: "2015-03-29T00:20:41Z",
          channelId: "UCWN2FPlvg9r-LnUyepH9IaQ",
          title: "Best Arm Tone &amp; Definition Class ♥ 5 Minute Miracle",
          description:
            'A five minute miracle class designed to tone and define sexy arms arms like a supermodel. Have you ever wondered, "How do I tone my arms without weights?',
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/r1FNRH-mG2E/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/r1FNRH-mG2E/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/r1FNRH-mG2E/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Boho Beautiful",
          liveBroadcastContent: "none",
          publishTime: "2015-03-29T00:20:41Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "7qXSv2BVxPKJR7Wd1NYNfLHSL3g",
        id: {
          kind: "youtube#video",
          videoId: "RYbV5zTD5OM",
        },
        snippet: {
          publishedAt: "2020-04-28T13:59:21Z",
          channelId: "UCpis3RcTw6t47XO0R_KY4WQ",
          title: "Arm Workout at Home | Dumbbells Only",
          description:
            "Arm Workout at Home | Dumbbells This is a quick and targeted follow along arm workout with dumbbells! If you wanting to tighten your arms and improve shape ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/RYbV5zTD5OM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/RYbV5zTD5OM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/RYbV5zTD5OM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caroline Girvan",
          liveBroadcastContent: "none",
          publishTime: "2020-04-28T13:59:21Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ljgfvTAHuEL5ZpQgkccfNG33Eeg",
        id: {
          kind: "youtube#video",
          videoId: "RVTfIfox9EY",
        },
        snippet: {
          publishedAt: "2019-10-14T17:40:29Z",
          channelId: "UCpQ34afVgk8cRQBjSJ1xuJQ",
          title: "TONE YOUR ARMS Workout - QUICK &amp; INTENSE (No Equipment)",
          description:
            "A quick, but intense 10 min workout to TONE YOUR ARMS with no equipment or weights needed! You can do this workout at home, or anywhere else! THE ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/RVTfIfox9EY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/RVTfIfox9EY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/RVTfIfox9EY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MadFit",
          liveBroadcastContent: "none",
          publishTime: "2019-10-14T17:40:29Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "EbDHBKV0Y3_l1YJqqmpiAPtjiLM",
        id: {
          kind: "youtube#video",
          videoId: "8E7cjvOit64",
        },
        snippet: {
          publishedAt: "2020-04-17T10:44:18Z",
          channelId: "UCiWPU83VmOXjQ6xY0gIrhIQ",
          title:
            "10 Minute Tone Your Arm Workout For Women Over 50 | Beginner Friendly",
          description:
            "10 minute toned Toned arms workout for women over 50, no equipment and suitable for beginners and seniors. Tone your arms and get rid of Flabby arms and ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8E7cjvOit64/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8E7cjvOit64/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8E7cjvOit64/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "fabulous50s",
          liveBroadcastContent: "none",
          publishTime: "2020-04-17T10:44:18Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "4vMF42EgNkic4MxdSqqEzVUPtOM",
        id: {
          kind: "youtube#video",
          videoId: "NCzNpWHIvTE",
        },
        snippet: {
          publishedAt: "2020-06-25T16:30:00Z",
          channelId: "UCNCwlEyer3G6gMTmKxYR04g",
          title:
            "5 MINUTES TONED ARMS WORKOUT | no equipment | Caro Daur #DAURPOWER",
          description:
            "Are you ready for a quick 5 minutes arm & shoulder workout? 1. 60 sec - Hold 2. 30 sec - Circles forward 3. 30 sec - Circles reverse 4. 60 sec - Plates in and out ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/NCzNpWHIvTE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/NCzNpWHIvTE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/NCzNpWHIvTE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Caro Daur",
          liveBroadcastContent: "none",
          publishTime: "2020-06-25T16:30:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "kfGqOjO4AZWFg4FjKLeq3Iuf0Fo",
        id: {
          kind: "youtube#video",
          videoId: "klF40ObD6mg",
        },
        snippet: {
          publishedAt: "2019-09-12T15:27:24Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "Superset Arm Workout for Mass | Brian DeCosta",
          description:
            "Think you know how to use supersets to get bigger arms? Brian DeCosata shares his killer arm workout to help you get the best biceps and triceps pump of your ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/klF40ObD6mg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/klF40ObD6mg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/klF40ObD6mg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2019-09-12T15:27:24Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "uFR_xJkjUg_xiPo--TpgSqCBUtA",
        id: {
          kind: "youtube#video",
          videoId: "AHZaEayZY0M",
        },
        snippet: {
          publishedAt: "2019-07-30T21:45:08Z",
          channelId: "UCZUUZFex6AaIU4QTopFudYA",
          title:
            "Do This Every Morning To Lose Flabby Arms ( Slimmer Arms In 7 Days! ) No Equipment Arm Workout",
          description:
            "IF YOUR READING THIS, JOIN THE FAM HIT SUBSCRIBE! ❤️ 8 MINUTES to lean and toned arms! Who's down for this beginner-friendly fat loss challenge?",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/AHZaEayZY0M/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/AHZaEayZY0M/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/AHZaEayZY0M/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "growwithjo",
          liveBroadcastContent: "none",
          publishTime: "2019-07-30T21:45:08Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "qN21vwD6DjYyKw3WIFhgpgEd__Q",
        id: {
          kind: "youtube#video",
          videoId: "eBnSe3CIPn4",
        },
        snippet: {
          publishedAt: "2020-07-21T04:00:05Z",
          channelId: "UChVRfsT_ASBZk10o0An7Ucg",
          title:
            "10 MIN UPPER BODY WORKOUT - for toned arms, chest &amp; back muscles / No Equipment I Pamela Reif",
          description:
            "it's time for a new (no equipment) UPPER BODY workout! I know: home workouts for arms, chest & back are always hard to find - but I think I created another ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/eBnSe3CIPn4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/eBnSe3CIPn4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/eBnSe3CIPn4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Pamela Reif",
          liveBroadcastContent: "none",
          publishTime: "2020-07-21T04:00:05Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "i017H8UfFf73FUT_7Bpu3CVQOmU",
        id: {
          kind: "youtube#video",
          videoId: "clcILxPkCrU",
        },
        snippet: {
          publishedAt: "2020-06-04T22:13:54Z",
          channelId: "UC3LTva0w-540VwN99rz-KKA",
          title:
            "SLIM ARMS FAST | i tried chloe ting and gabriella whited arm workouts combined *guaranteed result*",
          description:
            "WATCH IN HD Mabuhay! My name is Kim! I am a Filipina Youtuber :) It's been a long time since I last uploaded. Thank you to all who subscribed to my channel!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/clcILxPkCrU/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/clcILxPkCrU/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/clcILxPkCrU/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Kim Balanay",
          liveBroadcastContent: "none",
          publishTime: "2020-06-04T22:13:54Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "i4EVD_TmfacpNXqOBG0WapvbwoA",
        id: {
          kind: "youtube#video",
          videoId: "LHhG7fLdeuk",
        },
        snippet: {
          publishedAt: "2020-12-01T21:34:23Z",
          channelId: "UCKUpAAg8q4CozUxBjxyvDDQ",
          title: "10 MINUTE ARM BURN- 2lb weights arm workout",
          description:
            "Sculpt those arms in 10 minutes! All you need is a set of 2 or 3 pound weights. Workout Pants: Lulu Lemon Align Workout Top: Athleta Epidemic Sound: Glowing ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/LHhG7fLdeuk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/LHhG7fLdeuk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/LHhG7fLdeuk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Annie Taylor Efremsky",
          liveBroadcastContent: "none",
          publishTime: "2020-12-01T21:34:23Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "i5Y1Mdn_5T3Y61pUbeCnER-6Jto",
        id: {
          kind: "youtube#video",
          videoId: "-KjlbTjq2sc",
        },
        snippet: {
          publishedAt: "2020-09-25T00:26:09Z",
          channelId: "UC0I0EiJVAA6NyWcMfDbzC6g",
          title: "FULL ARM WORKOUT SESSION!",
          description:
            "Join me as I take my clients Hocine, Alan and Tracy through this strategic arm session. hope you can learn some through it. leave a comment and a like if you ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/-KjlbTjq2sc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/-KjlbTjq2sc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/-KjlbTjq2sc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Charles Glass",
          liveBroadcastContent: "none",
          publishTime: "2020-09-25T00:26:09Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "vP06voI3hPmphPjfIP4BlJBwpRQ",
        id: {
          kind: "youtube#video",
          videoId: "W3BGPrhgs6Q",
        },
        snippet: {
          publishedAt: "2020-11-25T14:45:01Z",
          channelId: "UCH-fbyTq-YcZPijpf82I2Rw",
          title:
            "10MIN INTENSE Arm Workout | you will either love me or hate me (wrist friendly)",
          description:
            "GET TONE AND BEAUTIFUL ARMS WITH ME! Hello everyone! Today, we have a VERY fun and challenging arm workout that will make you burn, cry, laugh, ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/W3BGPrhgs6Q/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/W3BGPrhgs6Q/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/W3BGPrhgs6Q/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Elizabeth Chu",
          liveBroadcastContent: "none",
          publishTime: "2020-11-25T14:45:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "W0Kg3MRWrMIcchW8hR-tu1HgZfs",
        id: {
          kind: "youtube#video",
          videoId: "5S0dM-WSzj0",
        },
        snippet: {
          publishedAt: "2020-04-04T05:13:14Z",
          channelId: "UClY87IoUANFZtswyC9GeecQ",
          title:
            "Lose Arm Fat in 1 WEEK - Get Slim Arms | Arms Workout Exercise for Flabby Arms &amp; Tone Sagging Arms",
          description:
            "Get rid of flabby arms and tone sagging arms. How To Lose Arm Fat | Simple Exercises at home for Beginners. How to tighten and tone sagging arms by doing ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/5S0dM-WSzj0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/5S0dM-WSzj0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/5S0dM-WSzj0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Beauty recipes",
          liveBroadcastContent: "none",
          publishTime: "2020-04-04T05:13:14Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "cjofd14zUh8Igg1bkX8xdqk6LYA",
        id: {
          kind: "youtube#video",
          videoId: "wgc0rY1kInY",
        },
        snippet: {
          publishedAt: "2020-09-21T14:00:11Z",
          channelId: "UC3-xcOU2D7f9XA5JQm2Lgtw",
          title: "25 MINUTE MASS BUILDING ARM WORKOUT | REGAN GRIMES",
          description:
            "MY APPAREL AND TRAIN PROGRAM WWW.REGANGRIMES.COM INSANE Arm Routine with IFBB PRO Regan Grimes and Zane Watson! If you are looking to ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/wgc0rY1kInY/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/wgc0rY1kInY/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/wgc0rY1kInY/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Regan Grimes",
          liveBroadcastContent: "none",
          publishTime: "2020-09-21T14:00:11Z",
        },
      },
    ],
  },
];
