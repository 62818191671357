import React, { useState } from "react";

import NavBar from "./Components/NavBar";
import YoutubeCard from "./Components/YoutubeCard";
import YoutubePlayer from "./Components/YoutubePlayer";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion, AnimatePresence } from "framer-motion";

function App() {
  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState();
  console.log(video);
  return (
    <div className="App">
      <header className="App-header">
        <NavBar click={getRandomVideos} onSet={setVideos} />
      </header>
      <h1 className="title">Random Workout Selector</h1>
      <div className="media-container">{ShowVideoPlayer(video)}</div>
      {ListVideos(videos, setVideo)}
    </div>
  );
}

const getRandomVideos = (setVideos, ListOfWorkouts) => {
  const data = ListOfWorkouts[0].items;
  const vids = [];
  while (vids.length !== 3) {
    const vid = data[Math.floor(Math.random() * data.length)];
    if (!vids.includes(vid)) {
      vids.push(vid);
    }
  }

  setVideos(vids);

};

function ListVideos(randomVideos, setVideo) {
  return (
    <ul className={"flex-container"}>
      {randomVideos.map(function (randomVideo) {
        if (randomVideo !== undefined && randomVideo != null) {
          return (
            <motion.div
              key={randomVideo.id.videoId}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <YoutubeCard
                video={randomVideo}
                key={randomVideo.id.videoId}
                click={setVideo}
              />
            </motion.div>
          );
        }
        return <div></div>;
      })}
    </ul>
  );
}

function ShowVideoPlayer(video) {
  if (video !== undefined) {
    return (
      <motion.div
        key={video.id.videoId}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1.5 }}
      >
        <YoutubePlayer video={video} />
      </motion.div>
    );
  }
  return <h4>Select a category to see three random workout videos.</h4>;
}

export default App;
