import React from "react";
import ReactPlayer from "react-player";

import "./YoutubePlayer.css";

function YoutubePlayer(props) {
  return (
    <div className={"player-container"}>
      <div className={"player-wrapper"}>
        <ReactPlayer
          className={"react-player"}
          url={`https://www.youtube.com/watch?v=${props.video.id.videoId}`}
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    </div>
  );
}

export default YoutubePlayer;
