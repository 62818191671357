export const ChestWorkouts = [
  {
    kind: "youtube#searchListResponse",
    etag: "6VRlYlpGvx_gJe1NbCp_5uxcEOE",
    nextPageToken: "CDIQAA",
    regionCode: "GB",
    pageInfo: {
      totalResults: 1000000,
      resultsPerPage: 50,
    },
    items: [
      {
        kind: "youtube#searchResult",
        etag: "FBx-7hJQXR5s5ItEixH9JA4TMMc",
        id: {
          kind: "youtube#video",
          videoId: "89e518dl4I8",
        },
        snippet: {
          publishedAt: "2018-10-21T16:37:10Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "The PERFECT Chest Workout (Sets and Reps Included)",
          description:
            "Build Muscle in 90 Days - http://athleanx.com/x/my-workouts Subscribe to this channel here - http://bit.ly/2b0coMW The perfect chest workout should consist of ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/89e518dl4I8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/89e518dl4I8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/89e518dl4I8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2018-10-21T16:37:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "N05vhVYqxrfbEVu4y1gEmvFsW9I",
        id: {
          kind: "youtube#video",
          videoId: "mQMA88jJrFc",
        },
        snippet: {
          publishedAt: "2017-01-24T14:10:46Z",
          channelId: "UCzGLDaTu81nJDtWK10MniGg",
          title: "5 Chest Exercises You Should Be Doing",
          description:
            "Training Programmes - https://mikethurston.co.uk/ » 25% OFF BULK POWDERS® Supplements (USE CODE THURSTON) - http://bit.ly/2F2UHwP » Instagram: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/mQMA88jJrFc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/mQMA88jJrFc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/mQMA88jJrFc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mike Thurston",
          liveBroadcastContent: "none",
          publishTime: "2017-01-24T14:10:46Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "EFC-TNjnN3NgJJy2FySL7C6lFOM",
        id: {
          kind: "youtube#video",
          videoId: "lWXhih3xbVc",
        },
        snippet: {
          publishedAt: "2020-10-14T02:30:01Z",
          channelId: "UC6vkKAsph6kZuAsC5Q8MVNQ",
          title: "10 Best Chest Exercises YOU Should Be Doing",
          description:
            "10 Best Chest Exercises YOU Should Be Doing Ex1: 00:00 Barbell Bench Press Ex2: 00:42 Dumbbell Bench Press Ex3: 01:24 Hammer-grip DB Bench Press ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/lWXhih3xbVc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/lWXhih3xbVc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/lWXhih3xbVc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "GYM BODY MOTIVATION",
          liveBroadcastContent: "none",
          publishTime: "2020-10-14T02:30:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "eH1AarqNWBtLHnkMX8yiKcRUnT0",
        id: {
          kind: "youtube#video",
          videoId: "BkS1-El_WlE",
        },
        snippet: {
          publishedAt: "2019-04-01T00:00:00Z",
          channelId: "UCqjwF8rxRsotnojGl4gM0Zw",
          title: "The BEST HOME CHEST WORKOUT (NO EQUIPMENT NEEDED)",
          description:
            "Join Chris Heria as he shows you the best Home chest workout to achieve real results that you can do anywhere. You don't need any Equipment to do this ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/BkS1-El_WlE/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/BkS1-El_WlE/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/BkS1-El_WlE/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "THENX",
          liveBroadcastContent: "none",
          publishTime: "2019-04-01T00:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "74JurcZqUWFEQUa5PLuVGjFLAiE",
        id: {
          kind: "youtube#video",
          videoId: "rxEMKXW2Wqs",
        },
        snippet: {
          publishedAt: "2019-07-04T19:36:53Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "Home Chest Workout | 10 Minutes (FOLLOW ALONG!)",
          description:
            "If you want to build a bigger chest and prefer to use a home chest workout to do it, then you have found the right video. In just 10 minutes, I'm going to show you ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/rxEMKXW2Wqs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/rxEMKXW2Wqs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/rxEMKXW2Wqs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2019-07-04T19:36:53Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "0vdYb8fUgoed1qATIqiLTmtpzPw",
        id: {
          kind: "youtube#video",
          videoId: "XwSJy-S6oXI",
        },
        snippet: {
          publishedAt: "2017-11-04T18:00:04Z",
          channelId: "UCoguzybAI8Fgdj4Aze5tE8w",
          title: "WORLD&#39;S BEST CHEST WORKOUT",
          description:
            "A J Ellison is the World's Greatest Muscle Model - and his huge, thick chest is his most striking body part. CHECK OUT OUR FULL HOME WORKOUT PLAYLIST ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/XwSJy-S6oXI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/XwSJy-S6oXI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/XwSJy-S6oXI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Fit Media Channel",
          liveBroadcastContent: "none",
          publishTime: "2017-11-04T18:00:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "HPBLNzul5Knozj2Ty3nLKVv7kGE",
        id: {
          kind: "youtube#video",
          videoId: "jWc8gHlAkoM",
        },
        snippet: {
          publishedAt: "2010-06-30T20:09:41Z",
          channelId: "UCNAjjkbNbaVeH4o617fTC4g",
          title: "Chest Workout Home Version",
          description:
            "Watch our Best of videos playlist http://bit.ly/2QiCD6z Subscribe to our channel http://bit.ly/2Nx4u01 FREE Chest workout App iOS ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/jWc8gHlAkoM/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/jWc8gHlAkoM/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/jWc8gHlAkoM/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Passion4Profession",
          liveBroadcastContent: "none",
          publishTime: "2010-06-30T20:09:41Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "MMVZdxX3fcftvhb1xykvB2wt65s",
        id: {
          kind: "youtube#video",
          videoId: "fAU2DueNmYA",
        },
        snippet: {
          publishedAt: "2020-07-29T15:30:46Z",
          channelId: "UCj209lI3ZOmOCkK8e3qFFNQ",
          title: "6 Chest Exercises (YOU&#39;LL REGRET NOT DOING!!)",
          description:
            "New some new chest exercises for your workout? Let's get after it! ...And here we go again, another kick your own a** style of workout and this time we did chest.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/fAU2DueNmYA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/fAU2DueNmYA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/fAU2DueNmYA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Ryan Humiston",
          liveBroadcastContent: "none",
          publishTime: "2020-07-29T15:30:46Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "HuxA7JSf55TB-yq0BFFt6OkBOiU",
        id: {
          kind: "youtube#video",
          videoId: "a9vL6BsgkPg",
        },
        snippet: {
          publishedAt: "2018-11-13T11:15:00Z",
          channelId: "UCCgLoMYIyP0U56dEhEL1wXQ",
          title: "10 Mins INTENSE Chest Workout | BEGINNERS TO ADVANCED",
          description:
            "Another dumbell workout for you guys, this time a chest workout! If you don't have dumbells, feel free to DIY dumbells for now with water bottles and all you need ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/a9vL6BsgkPg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/a9vL6BsgkPg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/a9vL6BsgkPg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Chloe Ting",
          liveBroadcastContent: "none",
          publishTime: "2018-11-13T11:15:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "HywcM2umFaQn7pvjKblnQa-D8k4",
        id: {
          kind: "youtube#video",
          videoId: "UxdTsE1esIQ",
        },
        snippet: {
          publishedAt: "2020-12-02T17:15:01Z",
          channelId: "UC6vkKAsph6kZuAsC5Q8MVNQ",
          title: "8 Best Chest Exercises YOU Should Be Doing",
          description:
            "8 Best Chest Exercises YOU Should Be Doing 00:00 Svend Press 00:35 Dumbbell Low Fly 01:06 Incline Dumbbell Bench Press 01:40 Incline Barbell Bench ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/UxdTsE1esIQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/UxdTsE1esIQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/UxdTsE1esIQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "GYM BODY MOTIVATION",
          liveBroadcastContent: "none",
          publishTime: "2020-12-02T17:15:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "dL035aDGtPZrBZNbo4kGDQRim68",
        id: {
          kind: "youtube#video",
          videoId: "_q_-adYzkh0",
        },
        snippet: {
          publishedAt: "2019-06-14T18:15:00Z",
          channelId: "UCK9JEqf7LBBx3tkrPx2xvbQ",
          title: "5 MINUTE CHEST WORKOUT(NO EQUIPMENT)",
          description:
            "This is a follow along chest workout that will take you 5 minutes to LIGHT YOUR PECS ON FIRE! We all know you have at least 5 minutes in a day that you are ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/_q_-adYzkh0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/_q_-adYzkh0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/_q_-adYzkh0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "BullyJuice",
          liveBroadcastContent: "none",
          publishTime: "2019-06-14T18:15:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "y_a6WbmALEtjVAhXRKfyz-vNaoA",
        id: {
          kind: "youtube#video",
          videoId: "MxnzcssW-tk",
        },
        snippet: {
          publishedAt: "2017-10-29T16:00:53Z",
          channelId: "UCERm5yFZ1SptUEU4wZ2vJvw",
          title: "The Best Science-Based Chest Workout for Mass &amp; Symmetry",
          description:
            "When it comes to the “best chest workout”, it really comes down to choosing exercises that allow symmetrical growth in the upper chest, middle chest, and lower ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/MxnzcssW-tk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/MxnzcssW-tk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/MxnzcssW-tk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeremy Ethier",
          liveBroadcastContent: "none",
          publishTime: "2017-10-29T16:00:53Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Wm--RDrXTRnezttaofUFajVDHH8",
        id: {
          kind: "youtube#video",
          videoId: "qwx1VV9vV1A",
        },
        snippet: {
          publishedAt: "2020-08-19T15:15:20Z",
          channelId: "UC_nfjMmJlJXrhl2xbP9wgRA",
          title: "Most Effective CHEST Workout at HOME (NO EQUIPMENT NEEDED)",
          description:
            "Most Effective CHEST Workout at HOME (NO EQUIPMENT NEEDED) #workout #chest #homeworkout My instagram: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/qwx1VV9vV1A/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/qwx1VV9vV1A/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/qwx1VV9vV1A/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "NEXT Workout",
          liveBroadcastContent: "none",
          publishTime: "2020-08-19T15:15:20Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "WlhopZZSKa8gAJIOB6CZTlakZuk",
        id: {
          kind: "youtube#video",
          videoId: "ZL92kkRafKs",
        },
        snippet: {
          publishedAt: "2020-02-17T16:16:56Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "Favorite Mass Building Chest Workout | IFBB Pro Regan Grimes",
          description:
            "Add thickness and mass to your chest with this beast-tested, IFBB pro bodybuilder-approved pec blast from Primeval Labs-sponsored athlete Regan Grimes.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ZL92kkRafKs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ZL92kkRafKs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ZL92kkRafKs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2020-02-17T16:16:56Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "bWsYhsuQhIYbky2hXKU-hxJOSoI",
        id: {
          kind: "youtube#video",
          videoId: "pJlE3CHP-Ac",
        },
        snippet: {
          publishedAt: "2019-09-01T13:00:20Z",
          channelId: "UCOFCwvhDoUvYcfpD7RJKQwA",
          title: "12 Best CHEST Exercises For Bigger Pecs",
          description:
            "Order Pre Workout by Sculpt Nation and save 20% using code 20-workout at checkout - http://vshred.fit/12bestchestexercises What's going on guys! In today's ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/pJlE3CHP-Ac/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/pJlE3CHP-Ac/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/pJlE3CHP-Ac/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "V Shred",
          liveBroadcastContent: "none",
          publishTime: "2019-09-01T13:00:20Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "drBXfWqxa9Io1MXvMJI4V_DmTgQ",
        id: {
          kind: "youtube#video",
          videoId: "sSIccj82Dyg",
        },
        snippet: {
          publishedAt: "2020-04-16T18:00:26Z",
          channelId: "UCaBqRxHEMomgFU-AkSfodCw",
          title: "7Min Effective Home Chest Workout (No Equipment)",
          description:
            "Follow along with Chris Heria for this 7Min Effective Home Chest Workout. Learn how to engage your chest differently and start building crazy strength and size ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/sSIccj82Dyg/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/sSIccj82Dyg/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/sSIccj82Dyg/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "CHRIS HERIA",
          liveBroadcastContent: "none",
          publishTime: "2020-04-16T18:00:26Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "zjJoXif16-Xn_E0aaF5MKIRlLbE",
        id: {
          kind: "youtube#video",
          videoId: "zAstcqPv35M",
        },
        snippet: {
          publishedAt: "2019-08-08T16:00:14Z",
          channelId: "UCkZuCxXdE6OUGDEbETcjhPw",
          title: "Chest Workout Tips Explained | Seth Feroce",
          description:
            "https://axeandsledge.com https://allamericanroughneck.com Pre-Workout Shake: 1.5 Scoops Hydraulic - Shark Bite 1 Scoop Demo Day Warm Up - Pull-ups - 50 ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/zAstcqPv35M/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/zAstcqPv35M/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/zAstcqPv35M/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Seth Feroce",
          liveBroadcastContent: "none",
          publishTime: "2019-08-08T16:00:14Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "KDllzr_QuI6KThyfRbGZOuNpAUk",
        id: {
          kind: "youtube#video",
          videoId: "nWPIilN-Sso",
        },
        snippet: {
          publishedAt: "2019-01-24T16:54:15Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title:
            "Abel Albonetti&#39;s Mass Building Chest Workout | Full Commentary",
          description:
            "In this chest workout, Abel Albonetti takes you through five chest exercises to add some mass to your upper body while giving you the ultimate pump. ▻ Abel ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/nWPIilN-Sso/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/nWPIilN-Sso/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/nWPIilN-Sso/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2019-01-24T16:54:15Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "YWNX5hXQfQZzx5vwgTdZZs69XhY",
        id: {
          kind: "youtube#video",
          videoId: "8LLe7AZ4TFk",
        },
        snippet: {
          publishedAt: "2020-05-31T01:22:07Z",
          channelId: "UCbm8FxO0gBbZ19iydL9g3ZQ",
          title: "CHEST WORKOUT Adding Size After Quarantine &amp; JEEP MODS!",
          description:
            "Instagram // @merkelfitness ✘ big s/o to Ross @ Rehab Detailing in Beachwood, OH... excited to see these upgrades on in about a month ✘ TRAINING ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/8LLe7AZ4TFk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/8LLe7AZ4TFk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/8LLe7AZ4TFk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "JustMerk",
          liveBroadcastContent: "none",
          publishTime: "2020-05-31T01:22:07Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "qW1IE4Dq3M1wVN9wxYZrV4xdjfw",
        id: {
          kind: "youtube#video",
          videoId: "Qf3hp9O84rA",
        },
        snippet: {
          publishedAt: "2019-12-05T20:35:57Z",
          channelId: "UCHp-_faz2gINcwGEf9A6ZZg",
          title: "BUILD A BETTER CHEST | Full Chest Workout | Mike Rashid King",
          description:
            "Watch the entire video to get the discount code for Ambrosia Products... order here: http://bit.ly/31Q11PR I'm now taking online clients: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Qf3hp9O84rA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Qf3hp9O84rA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Qf3hp9O84rA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mike Rashid",
          liveBroadcastContent: "none",
          publishTime: "2019-12-05T20:35:57Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "UO-FIRuSyVgaCIIO4IlYChKgUIQ",
        id: {
          kind: "youtube#video",
          videoId: "cb_rjST7Vw0",
        },
        snippet: {
          publishedAt: "2019-05-22T12:13:23Z",
          channelId: "UCvGEK5_U-kLgO6-AMDPeTUQ",
          title:
            "10 min Natural Boob Lift! Firming + Shaping Chest Workout ◆ Emi ◆",
          description:
            "SUBSCRIBE FOR WEEKLY VIDEOS 訂閱我的頻道你不會後悔☺ ▻ http://bit.ly/SubscribeToEmi ♡ADD ME ON 成為我的朋友☺ Instagram: @EmiWong_ ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/cb_rjST7Vw0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/cb_rjST7Vw0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/cb_rjST7Vw0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "emi wong",
          liveBroadcastContent: "none",
          publishTime: "2019-05-22T12:13:23Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Lpnqc6Vdz4X-Dx-_sAHIp0J6vz0",
        id: {
          kind: "youtube#video",
          videoId: "4j1mXp6J_Is",
        },
        snippet: {
          publishedAt: "2019-04-25T18:49:16Z",
          channelId: "UCWl4r8lWQwk1BRQhNugBYzQ",
          title: "HEAVY Chest Workout - Classic Bodybuilding",
          description:
            "GYM CLOTHING AND OLD-SCHOOL SUPPLEMENTS https://vintagegenetics.com/ WORKOUT Dumbbells Press 7 sets - 10-12 reps *last set heavy set (4-6 ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/4j1mXp6J_Is/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/4j1mXp6J_Is/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/4j1mXp6J_Is/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "VintageGenetics",
          liveBroadcastContent: "none",
          publishTime: "2019-04-25T18:49:16Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "7ijAuH-lThAEOjRQRrGmqXcFEv8",
        id: {
          kind: "youtube#video",
          videoId: "E7ZuFDpIBL8",
        },
        snippet: {
          publishedAt: "2020-03-15T16:00:04Z",
          channelId: "UCFAsEytj1vVG7uPvWXLA5rg",
          title: "The ONLY 3 Lower Chest Exercises You Need for Rounded Pecs",
          description:
            "For many guys, the lower pecs are the most difficult area of the chest to fully develop. In most cases, this has less to do with the exercises they're performing and ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/E7ZuFDpIBL8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/E7ZuFDpIBL8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/E7ZuFDpIBL8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "musclemonsters",
          liveBroadcastContent: "none",
          publishTime: "2020-03-15T16:00:04Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "FC5yv_LBCSfLiD4keLX3l3f0X_w",
        id: {
          kind: "youtube#video",
          videoId: "JBKej6n_A9I",
        },
        snippet: {
          publishedAt: "2015-11-30T01:03:30Z",
          channelId: "UCYlG9AHZStenkJgXLj6w61A",
          title: "Chest workout with Calum von Moger",
          description:
            "WEBSITE: http://calumvonmoger.com OFFICIAL APPAREL: http://teamvonmoger.com INSTAGRAM: https://www.instagram.com/calumvonmoger/ FACEBOOK: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/JBKej6n_A9I/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/JBKej6n_A9I/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/JBKej6n_A9I/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Calum von Moger",
          liveBroadcastContent: "none",
          publishTime: "2015-11-30T01:03:30Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "EelJ9abG3-U6LVs-BvIg404yMbw",
        id: {
          kind: "youtube#video",
          videoId: "E1neQOtI4l8",
        },
        snippet: {
          publishedAt: "2020-05-05T14:49:03Z",
          channelId: "UC3imX_9eaMl6-NuokFyHH0w",
          title:
            "Lift and firm your breasts in 3 Weeks, Intense workout to give your bust line a natural lift",
          description:
            "Members share their journeys https://www.instagram.com/inshapefam VOL 2: Round, firm and lifted breasts in 21 days: 1. https://youtu.be/5Dp4KQOapqk 2.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/E1neQOtI4l8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/E1neQOtI4l8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/E1neQOtI4l8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Hana Milly",
          liveBroadcastContent: "none",
          publishTime: "2020-05-05T14:49:03Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "fgKpfgsCg9fMsqwVsOxinuNm0y0",
        id: {
          kind: "youtube#video",
          videoId: "QdT-Bgmp2u4",
        },
        snippet: {
          publishedAt: "2020-10-15T18:00:07Z",
          channelId: "UCaBqRxHEMomgFU-AkSfodCw",
          title: "Best Chest Home Workout (Dumbbells Only)",
          description:
            "Join Chris Heria for Best Chest Home Workout. Follow along with only a pair of dumbbells in combination with the right calisthenics exercises to get a ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/QdT-Bgmp2u4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/QdT-Bgmp2u4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/QdT-Bgmp2u4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "CHRIS HERIA",
          liveBroadcastContent: "none",
          publishTime: "2020-10-15T18:00:07Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "yb1ITICFxmx6ePWrBzbmqsdVIz4",
        id: {
          kind: "youtube#video",
          videoId: "G2XrGztOTi0",
        },
        snippet: {
          publishedAt: "2020-01-27T22:12:19Z",
          channelId: "UC_nfjMmJlJXrhl2xbP9wgRA",
          title: "6 MIN CHEST WORKOUT AT HOME (FOR GROWTH)",
          description:
            "6 MIN CHEST WORKOUT AT HOME (FOR GROWTH) Let's build this chest together guys! Full free workout program schedule at http://igorvoitenko.com/ If you ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/G2XrGztOTi0/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/G2XrGztOTi0/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/G2XrGztOTi0/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "NEXT Workout",
          liveBroadcastContent: "none",
          publishTime: "2020-01-27T22:12:19Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "22qHieKvfFrqxjmZ5fw1RBZcVZ8",
        id: {
          kind: "youtube#video",
          videoId: "ADYeVtOm4KQ",
        },
        snippet: {
          publishedAt: "2020-05-24T10:52:52Z",
          channelId: "UCo0tkZZA2CKVckQNBgzlc9w",
          title: "THE ROCK CHEST WORKOUT MOTIVATION",
          description:
            "https://www.youtube.com/channel/UCOGXZIT0-ng0qpKsNrpFkKA . THE ROCK CHEST WORKOUT MOTIVATION #therock #dwaynejohnson #motivation.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/ADYeVtOm4KQ/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/ADYeVtOm4KQ/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/ADYeVtOm4KQ/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "STAMINO FITNESS",
          liveBroadcastContent: "none",
          publishTime: "2020-05-24T10:52:52Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "fod2j2rLvU9-kPVHTquwYquYjXc",
        id: {
          kind: "youtube#video",
          videoId: "T2xe39p1hKc",
        },
        snippet: {
          publishedAt: "2019-07-18T16:03:55Z",
          channelId: "UCTEpWW26-LKVvIP9mZkvlRw",
          title:
            "Chest workout and BEST assistance exercises for a STRONG log!",
          description:
            "Great Training Catalogue and Live Streams: www.Officialstrongman.com My Sponsors: https://myo-band.com/ My Instagram: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/T2xe39p1hKc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/T2xe39p1hKc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/T2xe39p1hKc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Eddie 'The Beast' Hall",
          liveBroadcastContent: "none",
          publishTime: "2019-07-18T16:03:55Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "BpUkXpfpMpgu2jOGivrWvvPsz7Q",
        id: {
          kind: "youtube#video",
          videoId: "JXYqd6ugxZw",
        },
        snippet: {
          publishedAt: "2018-10-23T15:51:44Z",
          channelId: "UC97k3hlbE-1rVN8y56zyEEA",
          title: "Abel Albonetti&#39;s Meanest Chest Workout",
          description:
            "MuscleTech-sponsored athlete Abel Albonetti runs you through his most brutal chest workout yet to help you build size and mass in your upper chest. ▻ Get the ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/JXYqd6ugxZw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/JXYqd6ugxZw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/JXYqd6ugxZw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Bodybuilding.com",
          liveBroadcastContent: "none",
          publishTime: "2018-10-23T15:51:44Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "CMsuQSsixKx4IB32tLOG5OGwpj4",
        id: {
          kind: "youtube#video",
          videoId: "wMmy2MlH55U",
        },
        snippet: {
          publishedAt: "2018-03-25T17:06:26Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "The LOWER Chest Solution (GET DEFINED PECS!)",
          description:
            "Get a ripped, athletic body - http://athleanx.com/x/my-workouts Subscribe to this channel here - http://bit.ly/2b0coMW The lower chest line is something that many ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/wMmy2MlH55U/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/wMmy2MlH55U/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/wMmy2MlH55U/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2018-03-25T17:06:26Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "m-cslY0M54d_UmdNhXAgl005OCM",
        id: {
          kind: "youtube#video",
          videoId: "HtV1FIPyTEw",
        },
        snippet: {
          publishedAt: "2016-12-28T19:04:56Z",
          channelId: "UC68TLK0mAEzUyHx5x5k-S1Q",
          title:
            "The Most Scientific Way to Train CHEST for Growth (9 Studies)",
          description:
            "My Chest Hypertrophy Program → http://www.strcng.com/chesthypertrophyprogram/ ▹ Timestamp to Scientific Section → 1:35 ▹ MY BENCH PRESS VIDEO ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/HtV1FIPyTEw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/HtV1FIPyTEw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/HtV1FIPyTEw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Jeff Nippard",
          liveBroadcastContent: "none",
          publishTime: "2016-12-28T19:04:56Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "J5NomgHJa0MLtN2pmTC3TUcZi4Y",
        id: {
          kind: "youtube#video",
          videoId: "5DFAcAvT2mI",
        },
        snippet: {
          publishedAt: "2017-07-03T03:00:00Z",
          channelId: "UCU3juAek2IQClkV7eUw-a2w",
          title: "MONDAY: Complete CHEST WORKOUT! (Hindi / Punjabi)",
          description:
            "For high quality protein powders and supplements I highly recommend MY PROTEIN Click on the link below, use code iKNOWSUNNY to get 25% to 40% off!!",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/5DFAcAvT2mI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/5DFAcAvT2mI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/5DFAcAvT2mI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "MY BOLLYWOOD BODY",
          liveBroadcastContent: "none",
          publishTime: "2017-07-03T03:00:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "kvaG-kQ4GSB5klPLVpRzpJJRe78",
        id: {
          kind: "youtube#video",
          videoId: "xtUmMyHWphc",
        },
        snippet: {
          publishedAt: "2020-10-20T22:20:23Z",
          channelId: "UCj209lI3ZOmOCkK8e3qFFNQ",
          title: "4 Chest Exercises YOU SHOULD BE DOING!",
          description:
            "Ready to build a bigger chest? That's what this video is for... Wanted to go over a few exercises I haven't shown before and also a few classics but with a different ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/xtUmMyHWphc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/xtUmMyHWphc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/xtUmMyHWphc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Ryan Humiston",
          liveBroadcastContent: "none",
          publishTime: "2020-10-20T22:20:23Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "pgFmB3oO3aDsfoTPitC7nZaRWi0",
        id: {
          kind: "youtube#video",
          videoId: "7bo3RaOEL8g",
        },
        snippet: {
          publishedAt: "2019-04-21T17:46:15Z",
          channelId: "UCe0TLA0EsQbE-MjuHXevj2A",
          title: "The BEST Dumbbell Exercises - CHEST EDITION!",
          description:
            "In today's video we look at the best dumbbell exercises for chest. We're going to focus on several areas of training: from strength, to power as well as ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/7bo3RaOEL8g/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/7bo3RaOEL8g/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/7bo3RaOEL8g/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "ATHLEAN-X™",
          liveBroadcastContent: "none",
          publishTime: "2019-04-21T17:46:15Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "jl7GWBM73NwwFFCQs98Sl9GHTQs",
        id: {
          kind: "youtube#video",
          videoId: "zlwNlFyRjFk",
        },
        snippet: {
          publishedAt: "2020-09-28T13:58:02Z",
          channelId: "UCmSEdfW3LpEKyLiCDWBDdVQ",
          title: "Top &quot;7&quot; Chest Exercises ( For Massive Growth )",
          description:
            "Have you ever wanted to know what my favorite chest exercises are? Today I will be going over just that. I have picked my top seven chest exercises for you.",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/zlwNlFyRjFk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/zlwNlFyRjFk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/zlwNlFyRjFk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "mountaindog1",
          liveBroadcastContent: "none",
          publishTime: "2020-09-28T13:58:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ljf7bfVK47av87lNFGmmU2eLxTI",
        id: {
          kind: "youtube#video",
          videoId: "JxvRGXPeTFs",
        },
        snippet: {
          publishedAt: "2020-04-20T17:19:39Z",
          channelId: "UCMyVy_Q6xXwKFX4UleHk-vg",
          title: "DUMBBELL Chest Exercises Workouts - Massive",
          description:
            "dumbbell chest press, dumbbell chest fly, dumbbell chest workout, dumbbell chest exercises, dumbbell chest workout no bench, dumbbell chest workout at ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/JxvRGXPeTFs/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/JxvRGXPeTFs/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/JxvRGXPeTFs/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "مهووس عضلات /كمال الأجسام",
          liveBroadcastContent: "none",
          publishTime: "2020-04-20T17:19:39Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "6xd1uEbuYOoxcHlZY6NFtj1aJwA",
        id: {
          kind: "youtube#video",
          videoId: "n69-eVLtevc",
        },
        snippet: {
          publishedAt: "2020-07-22T12:00:15Z",
          channelId: "UCg8LTugCdsOLuwtd9n0d8kA",
          title:
            "CHEST WORKOUT HOME ROUTINE | BODYWEIGHT EXERCISES | Rowan Row",
          description:
            "CHEST WORKOUT HOME ROUTINE | BODYWEIGHT EXERCISES | Rowan Row Hey guys, I am super excited to share with you this bodyweight quick and ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/n69-eVLtevc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/n69-eVLtevc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/n69-eVLtevc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Rowan Row",
          liveBroadcastContent: "none",
          publishTime: "2020-07-22T12:00:15Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "EZN-sOvg-kjRpJINAIBINPVLl_w",
        id: {
          kind: "youtube#video",
          videoId: "9K2NVgR14BI",
        },
        snippet: {
          publishedAt: "2019-07-11T14:00:02Z",
          channelId: "UCIuQttovXppQLlWASzre1Lw",
          title: "Chest Workout | Complete Gym Chest Day Routine",
          description:
            "Hi everyone! Three videos in one week.. WHO.AM.I. Haha, but seriously, I told you once I found some time and my busy schedule simmered itself on down I ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/9K2NVgR14BI/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/9K2NVgR14BI/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/9K2NVgR14BI/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Hunter Chilton",
          liveBroadcastContent: "none",
          publishTime: "2019-07-11T14:00:02Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "NcLVKCaX9duRIDhdjhzfu4HoXCc",
        id: {
          kind: "youtube#video",
          videoId: "zujqLN3jdHA",
        },
        snippet: {
          publishedAt: "2020-02-10T14:15:31Z",
          channelId: "UCZO-5bP7WoJQtYQX9rFiLEA",
          title:
            "CHEST WORKOUT for MASS &amp; SHAPE - UNDERCONSTRUCTION SERIES 1",
          description:
            'Here is a new video of a new video series of my "offseason"/bulking phase. Just #Underconstruction to get back all the lost gains and build a new and improved ...',
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/zujqLN3jdHA/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/zujqLN3jdHA/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/zujqLN3jdHA/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Sergi Constance",
          liveBroadcastContent: "none",
          publishTime: "2020-02-10T14:15:31Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "pCIsR1Jra6HxQgXd6QYGMDy_ld4",
        id: {
          kind: "youtube#video",
          videoId: "9-jMV_41Lsc",
        },
        snippet: {
          publishedAt: "2018-08-12T11:00:01Z",
          channelId: "UCIJwWYOfsCfz6PjxbONYXSg",
          title:
            "5 Chest Isolation Exercises To Give You A Lift- Without Surgery!",
          description:
            "I just wanna make one thing clear to you guys! These exercises are a great way to naturally strengthen and lift your chest but they WILL NOT make you go from a ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/9-jMV_41Lsc/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/9-jMV_41Lsc/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/9-jMV_41Lsc/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "blogilates",
          liveBroadcastContent: "none",
          publishTime: "2018-08-12T11:00:01Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "9-LBTPNl7AGKS2JDq8hgdA8_O3U",
        id: {
          kind: "youtube#video",
          videoId: "6IQFfMhb4Do",
        },
        snippet: {
          publishedAt: "2020-09-14T10:30:00Z",
          channelId: "UCTuOoT5hhkHYwStFpmchwXQ",
          title: "MY CHEST WORKOUT EXPLAINED | How to Build the Perfect Chest",
          description:
            "Be The First to get early access: https://forms.gle/12SzEvz1cAXqnda66 Let Me Coach You and get your diet and training plan: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/6IQFfMhb4Do/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/6IQFfMhb4Do/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/6IQFfMhb4Do/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Zac Perna",
          liveBroadcastContent: "none",
          publishTime: "2020-09-14T10:30:00Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "CAJc-ab4QrWwBfTAcrPglWO5Ggk",
        id: {
          kind: "youtube#video",
          videoId: "FJ-93QIK1Ek",
        },
        snippet: {
          publishedAt: "2019-08-23T11:32:31Z",
          channelId: "UC_Ih0f1H_eyHeLLT0Tzuh8g",
          title:
            "10 EXERCISES TO BUILD A BIG CHEST | ADD THESE TO YOUR ROUTINE",
          description:
            "TRAINING PROGRAMS: https://www.simeonpanda.com JUST LIFT. CLOTHING: https://www.justlift.com SP AESTHETICS: https://www.sp-aesthetics.com VQ Fit: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/FJ-93QIK1Ek/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/FJ-93QIK1Ek/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/FJ-93QIK1Ek/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Simeon Panda",
          liveBroadcastContent: "none",
          publishTime: "2019-08-23T11:32:31Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "Fe4Tqg-WT0GUlPDjc2L--4ig_vI",
        id: {
          kind: "youtube#video",
          videoId: "oV5HYbLD1gk",
        },
        snippet: {
          publishedAt: "2020-12-29T21:11:51Z",
          channelId: "UCWl4r8lWQwk1BRQhNugBYzQ",
          title: "Chest Workout for MASS | Post Olympia Training",
          description:
            "GLADIATOR SUPPLEMENTS (by me) https://BecomeGladiator.com OLD-SCHOOL GYM CLOTHING & APPAREL https://VintageGenetics.com/ Become a ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/oV5HYbLD1gk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/oV5HYbLD1gk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/oV5HYbLD1gk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "VintageGenetics",
          liveBroadcastContent: "none",
          publishTime: "2020-12-29T21:11:51Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "IvZoKMy5ZPCdtYlJiNFTQfV4y8k",
        id: {
          kind: "youtube#video",
          videoId: "SDRG2u8jRas",
        },
        snippet: {
          publishedAt: "2020-12-08T16:00:10Z",
          channelId: "UCgOO-SRCWE8lhYqpo2kKvFw",
          title: "Chest Workout Ft Simeon Panda",
          description:
            "My Instagram: www.instagram.com/andreideiu_/ ➤ Simeon Panda Instagram: www.instagram.com/simeonpanda/ ➤ Simeon Panda YT: ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/SDRG2u8jRas/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/SDRG2u8jRas/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/SDRG2u8jRas/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Andrei Deiu",
          liveBroadcastContent: "none",
          publishTime: "2020-12-08T16:00:10Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "iDFZkc2kqJpQp5kRkFhJk4emzEM",
        id: {
          kind: "youtube#video",
          videoId: "QCKu-VHIJN4",
        },
        snippet: {
          publishedAt: "2019-03-25T15:50:45Z",
          channelId: "UCbUUeXlg5mSygcSy-qugdWQ",
          title: "Big Ramy - Epic Chest Workout",
          description:
            "Watch the first video of Big Ramy as Dragon Pharma Athlete! In this video, Big Ramy takes us through an EPIC chest workout! So, if you want to a massive chest, ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/QCKu-VHIJN4/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/QCKu-VHIJN4/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/QCKu-VHIJN4/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Dragon Pharma",
          liveBroadcastContent: "none",
          publishTime: "2019-03-25T15:50:45Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "XEZoy5_btHo90s4zkxYMX__t6m8",
        id: {
          kind: "youtube#video",
          videoId: "Cse56EbcBSk",
        },
        snippet: {
          publishedAt: "2019-08-16T19:52:09Z",
          channelId: "UC7t6QJ4u8qF8pI-vibX-BUQ",
          title: "18 CHEST EXERCISES | GYM WORKOUT",
          description:
            "Chest Exercises Browse through a variety of lower and upper chest exercises designed by today's leaders in the health fitness industry. We have strength ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/Cse56EbcBSk/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/Cse56EbcBSk/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/Cse56EbcBSk/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "WORKOUT",
          liveBroadcastContent: "none",
          publishTime: "2019-08-16T19:52:09Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "KYUWGcvKngu9Ok7HN84IpzIQXSo",
        id: {
          kind: "youtube#video",
          videoId: "_IaMMu1ckv8",
        },
        snippet: {
          publishedAt: "2013-01-01T18:35:35Z",
          channelId: "UCx-M_FYowSsGa2eXqfdMX2A",
          title: "Ronnie Coleman   Chest workout",
          description: "",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/_IaMMu1ckv8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/_IaMMu1ckv8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/_IaMMu1ckv8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "xmust xwatch",
          liveBroadcastContent: "none",
          publishTime: "2013-01-01T18:35:35Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "OSP4rHXui51tVlJwrMlQPeDjMks",
        id: {
          kind: "youtube#video",
          videoId: "xSzajPTZwh8",
        },
        snippet: {
          publishedAt: "2020-03-02T18:30:11Z",
          channelId: "UC53s-VII9tDkMRzg2zqFzmw",
          title: "INSANE CHEST WORKOUT WITH JASON DERULO! || Tristyn Lee",
          description:
            "Follow Tristyn on Instagram https://www.instagram.com/tristynleeofficial Follow Tyler on Instagram: https://www.instagram.com/officialtyl... Follow Braedon on ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/xSzajPTZwh8/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/xSzajPTZwh8/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/xSzajPTZwh8/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Tristyn Lee",
          liveBroadcastContent: "none",
          publishTime: "2020-03-02T18:30:11Z",
        },
      },
      {
        kind: "youtube#searchResult",
        etag: "ZwDNfqt9Z4SpE5kc3O9hua7wuOM",
        id: {
          kind: "youtube#video",
          videoId: "3giles5W_Kw",
        },
        snippet: {
          publishedAt: "2020-12-06T19:12:22Z",
          channelId: "UCzGLDaTu81nJDtWK10MniGg",
          title: "Olympia Prep Chest &amp; Triceps Workout With Ryan Terry",
          description:
            "Full Chest & Triceps workout with @RyanJTerry who's 3 weeks out from competing in Mr Olympia. » Training Programmes - https://mikethurston.co.uk/ » THRST ...",
          thumbnails: {
            default: {
              url: "https://i.ytimg.com/vi/3giles5W_Kw/default.jpg",
              width: 120,
              height: 90,
            },
            medium: {
              url: "https://i.ytimg.com/vi/3giles5W_Kw/mqdefault.jpg",
              width: 320,
              height: 180,
            },
            high: {
              url: "https://i.ytimg.com/vi/3giles5W_Kw/hqdefault.jpg",
              width: 480,
              height: 360,
            },
          },
          channelTitle: "Mike Thurston",
          liveBroadcastContent: "none",
          publishTime: "2020-12-06T19:12:22Z",
        },
      },
    ],
  },
];
